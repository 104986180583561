<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { router, WhenVisible } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import ThemeCard from './Components/ThemeCard.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';

const props = defineProps({
    themes: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
});

const seacher = () =>
    router.get('/dashboard/musical-themes', searchForm, {
        preserveState: true,
    });

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(route('musical-themes.index'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page-title="Temas">
        <Breadcrumb current-page-name="Temas" />

        <PageHeader
            title="Temas"
            :button-link="route('musical-themes.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label class="form-label" for="floatingInput">Nome do tema</label>
                    <input
                        id="floatingInput"
                        type="email"
                        class="form-control"
                        placeholder="Celebração"
                        @input="compositionSeacher('name', $event)"
                    />
                </div>
            </div>

            <hr />

            <WhenVisible data="themes">
                <template #fallback>
                    <Loader />
                </template>
                <div class="row mt-3">
                    <ThemeCard v-for="(item, index) in themes?.data" :key="index" :data="item" />

                    <Pagination
                        v-if="themes?.meta"
                        :data="themes.meta"
                        reference-date="musical-themes"
                    />
                </div>
            </WhenVisible>
        </div>
    </Dashboard>
</template>
