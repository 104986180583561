import { ref } from 'vue';
import ColorThief from 'colorthief';

export function useDominantColor(initialColor = '#000') {
    const dominantColor = ref(initialColor);
    const imageLoaded = ref(false);

    const extractDominantColor = (imageSrc) => {
        const image = new Image();
        image.crossOrigin = 'Anonymous';
        image.src = imageSrc;

        image.onload = () => {
            try {
                const colorThief = new ColorThief();
                const [r, g, b] = colorThief.getColor(image);
                dominantColor.value = `rgb(${r}, ${g}, ${b})`;
                imageLoaded.value = true;
            } catch (error) {
                console.error('Erro ao extrair a cor dominante:', error);
            }
        };

        image.onerror = (error) => {
            console.error('Erro ao carregar a imagem:', error);
        };
    };

    return {
        dominantColor,
        imageLoaded,
        extractDominantColor,
    };
}
