<script setup>
import { reactive, onMounted } from 'vue';
import { Link, router, WhenVisible } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import DepartmentCard from './Components/DepartmentCard.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';

const props = defineProps({
    departments: Object,
});

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const searchForm = reactive({
    name: null,
});

const seacher = () =>
    router.get(route('departments.index'), searchForm, {
        preserveState: true,
    });

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(route('departments.index'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page-title="Departamentos">
        <Breadcrumb current-page-name="Departamentos" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Departamentos</h4>
                <Link href="/dashboard/departments/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Novo
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar departamentos</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/departments/pdf/export`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/departments/excel/export`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>
            <hr class="mx-3" />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do departamento</label>
                        <input
                            id="floatingInput"
                            type="email"
                            class="form-control"
                            placeholder="Verboshop"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <hr />
                <WhenVisible data="events">
                    <template #fallback>
                        <Loader />
                    </template>
                    <DepartmentCard :data="departments?.data" />
                    <Pagination
                        v-if="departments?.data"
                        :data="departments.meta"
                        reference-date="departments"
                    />
                </WhenVisible>
            </div>
        </div>
    </Dashboard>
</template>
