<script setup>
import { onMounted, ref, computed } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import VideoWithRecommended from '@/Pages/Dashboard/Components/Shared/VideoWithRecommended.vue';

import { useDominantColor } from '@/Pages/Dashboard/Composables/useDominantColor.js';

const props = defineProps({
    music: Object,
    musicArtist: Array,
    recommendedSongs: Array,
    creatorRegistration: Object,
    playlists: Object,
});

const { dominantColor, extractDominantColor } = useDominantColor();

const convertYouTubeLink = (link) => {
    if (link && link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];
    }
    return link;
};

const copyLyrics = () => {
    const letter =
        activeTab.value === 'oficial' ? props.music.official_letter : props.music.ministry_letter;
    if (letter) {
        navigator.clipboard
            .writeText(letter)
            .then(() => {
                alert('Letra copiada para a área de transferência!');
            })
            .catch((error) => {
                console.error('Erro ao copiar a letra:', error);
            });
    }
};

const activeTab = ref('oficial');

const tabTitle = computed(() => {
    return activeTab.value === 'oficial' ? 'Letra Oficial' : 'Letra Ministerial';
});

onMounted(() => {
    const imageSrc = `/storage/${props.music.cover_photo}`;
    extractDominantColor(imageSrc);
});

function onUpdateYoutubeLink(newLink) {
    props.music.youtube_link = newLink;
}
</script>

<template>
    <Dashboard :page-title="music.name">
        <Breadcrumb
            current-page-name="Música"
            :link-list="[
                {
                    previous_page_url: 'musics',
                    previous_page_name: 'Músicas',
                },
            ]"
        />

        <div
            class="music-header text-white p-4 mb-3 rounded-3 shadow-lg"
            :style="{ background: `linear-gradient(135deg, ${dominantColor}, #000)` }"
        >
            <div class="row d-flex align-items-center">
                <div class="col-lg-4">
                    <div class="position-relative d-flex justify-content-center">
                        <img
                            class="rounded-3 soft-shadow"
                            width="200px"
                            :src="`/storage/${music.cover_photo}`"
                            alt=""
                            ref="musicImage"
                        />
                        <div class="position-absolute top-0 end-0 m-5 mt-2">
                            <Link :href="route('musics.edit', music.id)" class="btn btn-light">
                                <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                            </Link>
                        </div>
                    </div>
                </div>

                <div class="col-lg-8 mt-3 mt-sm-0">
                    <div>
                        <span class="font-semibold text-white">Música</span>
                        <h2 class="font-bold text-white mb-0">{{ music.name }}</h2>
                        <div class="d-flex align-items-center flex-wrap">
                            <div class="d-flex align-items-center mt-2">
                                <Link :href="route('artists.show', music.artist_id)">
                                    <LazyImage
                                        :src="`/storage/${music.artist.profile_photo}`"
                                        width="30px"
                                    />
                                </Link>
                                <span class="text-white font-semibold ms-2 fs-12">
                                    {{ music.artist.name }}
                                </span>
                            </div>
                        </div>

                        <div v-if="music.reproductions" class="row mt-3 font-semibold ms-1">
                            Tocada {{ music.reproductions }}x na igreja
                        </div>

                        <div class="d-flex mt-3" v-if="music.musical_themes.length">
                            <Link
                                v-for="(item, index) in music.musical_themes"
                                :key="index"
                                :href="route('musical-themes.show', item.id)"
                                class="btn btn-light btn-sm font-semibold me-2 rounded-2 fs-10"
                            >
                                {{ item.name }}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-simple card p-4">
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-between">
                <h4 class="font-bold mb-3">
                    <font-awesome-icon
                        class="text-secondary me-2"
                        @click="copyLyrics"
                        style="cursor: pointer"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        data-bs-title="Copiar"
                        :icon="['fas', 'copy']"
                    />
                    {{ tabTitle }}
                </h4>
                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link active font-semibold fs-12"
                            id="pills-home-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-home"
                            type="button"
                            role="tab"
                            aria-controls="pills-home"
                            aria-selected="true"
                            @click="activeTab = 'oficial'"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'circle-check']" />
                            Oficial
                        </button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link font-semibold fs-12"
                            id="pills-profile-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-profile"
                            type="button"
                            role="tab"
                            :disabled="music.ministry_letter == null"
                            aria-controls="pills-profile"
                            aria-selected="false"
                            @click="activeTab = 'ministerial'"
                        >
                            <font-awesome-icon class="me-2" :icon="['fas', 'house-circle-check']" />
                            Ministerial
                        </button>
                    </li>
                </ul>
            </div>

            <hr class="mt-0" />

            <div class="row">
                <div class="col-lg-6 col-xxl-8 col-12 p-0">
                    <div class="tab-content" id="pills-tabContent">
                        <div
                            class="tab-pane fade show active"
                            id="pills-home"
                            role="tabpanel"
                            aria-labelledby="pills-home-tab"
                            tabindex="0"
                        >
                            <div class="lyrics-container">
                                {{ music.official_letter }}
                            </div>
                        </div>
                        <div
                            class="tab-pane fade"
                            id="pills-profile"
                            role="tabpanel"
                            aria-labelledby="pills-profile-tab"
                            tabindex="0"
                        >
                            <div class="lyrics-container">
                                {{ music.ministry_letter }}
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-xxl-4 mt-4 mt-md-0">
                    <VideoWithRecommended
                        :music="music"
                        :recommendedSongs="recommendedSongs"
                        @updateYoutubeLink="onUpdateYoutubeLink"
                    />
                </div>
            </div>
        </div>

        <div class="card-simple card p-4 mt-4" v-if="musicArtist.length">
            <span class="text-secondary font-semibold fs-12">Músicas disponíveis de</span>
            <h4 class="font-bold mb-3">{{ music.artist.name }}</h4>
            <div class="search-form-card border-0 shadow-none">
                <Table
                    table-id="table-musics"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'name', label: 'Nome' },
                        {
                            key: 'youtube',
                            label: 'Youtube',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'duration',
                            label: 'Duração',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="musicArtist"
                    :rowLink="(item) => route('musics.show', item.id)"
                    :pagination="{ reference: 'musicArtist' }"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #cell-name="{ item }">
                        <div class="d-flex align-items-center">
                            <img
                                class="rounded-pill me-3"
                                height="35"
                                width="35"
                                :src="`/storage/${item.cover_photo}`"
                                alt=""
                            />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                    <template #cell-youtube="{ item }">
                        <a
                            target="_blank"
                            class="btn btn-link text-danger p-0 fs-3"
                            :class="{ disabled: !item.youtube_link }"
                            :href="item.youtube_link"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Assistir no Youtube"
                        >
                            <font-awesome-icon :icon="['fab', 'youtube']" />
                        </a>
                    </template>
                    <template #cell-duration="{ item }">
                        {{ item.duration }}
                    </template>
                </Table>
            </div>
        </div>

        <div class="card-simple card p-4 mt-4" v-if="recommendedSongs.length">
            <h4 class="font-bold mb-0">Recomendações</h4>
            <span class="text-secondary font-semibold fs-12 mb-3">Com base nesta música</span>
            <div class="search-form-card border-0 shadow-none">
                <Table
                    table-id="table-musics"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'name', label: 'Nome' },
                        {
                            key: 'youtube',
                            label: 'Youtube',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'duration',
                            label: 'Duração',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="recommendedSongs"
                    :rowLink="(item) => route('musics.show', item.id)"
                    :pagination="{ reference: 'recommendedSongs' }"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #cell-name="{ item }">
                        <div class="d-flex align-items-center">
                            <img
                                class="rounded-pill me-3"
                                height="35"
                                width="35"
                                :src="`/storage/${item.cover_photo}`"
                                alt=""
                            />
                            <span>{{ item.name }}</span>
                        </div>
                    </template>
                    <template #cell-youtube="{ item }">
                        <a
                            target="_blank"
                            class="btn btn-link text-danger p-0 fs-3"
                            :class="{ disabled: !item.youtube_link }"
                            :href="item.youtube_link"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Assistir no Youtube"
                        >
                            <font-awesome-icon :icon="['fab', 'youtube']" />
                        </a>
                    </template>
                    <template #cell-duration="{ item }">
                        {{ item.duration }}
                    </template>
                </Table>
            </div>
        </div>

        <div class="card-simple card p-4 mt-4" v-if="music.albums && music.albums.length">
            <h4 class="font-bold mb-0">
                <font-awesome-icon class="text-secondary" :icon="['fas', 'record-vinyl']" /> Álbums
            </h4>
            <span class="text-secondary font-semibold fs-12">relacionados a essa música</span>
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-3 mt-2">
                <div class="col" v-for="album in music.albums" :key="album.id">
                    <Link class="text-decoration-none" :href="route('albums.show', album.id)">
                        <div class="card border-0">
                            <img
                                :src="`/storage/${album.cover_image}`"
                                class="card-img-top rounded-3"
                                :alt="album.title"
                            />
                            <div class="card-body p-0 mt-2">
                                <h5 class="card-title mb-0 fs-14 font-bold">
                                    {{ album.title }}
                                </h5>
                                <p class="card-text text-secondary fs-12 mb-0 font-semibold">
                                    {{ new Date(album.release_date_gross).getFullYear() }} • Álbum
                                </p>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>

        <div class="card-simple card p-4 mt-4" v-if="playlists && playlists.length">
            <h4 class="font-bold mb-0">
                <font-awesome-icon class="text-secondary" :icon="['fas', 'podcast']" /> Playlists
            </h4>
            <span class="text-secondary font-semibold fs-12">que contêm essa música</span>
            <div class="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-3 mt-2">
                <div class="col" v-for="playlist in playlists" :key="playlist.id">
                    <Link class="text-decoration-none" :href="route('playlists.show', playlist.id)">
                        <div class="card border-0">
                            <img
                                src="/img/default/playlist.png"
                                class="card-img-top rounded-3"
                                :alt="playlist.name"
                            />
                            <div class="card-body p-0 mt-2">
                                <h5 class="card-title mb-0 fs-14 font-bold">
                                    {{ playlist.name }}
                                </h5>
                            </div>
                        </div>
                        <div class="d-flex align-items-center mt-2 p-2 border-top">
                            <img
                                :src="playlist.user.profile_image_url"
                                alt="Criador"
                                class="rounded-circle me-2"
                                width="30"
                            />
                            <span class="fs-10 font-semibold text-secondary">
                                {{ playlist.user.name }}
                            </span>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <CreatorRecord v-if="creatorRegistration.id" :data="creatorRegistration" />
    </Dashboard>
</template>

<style scoped>
.lyrics-container {
    background-color: #fff;
    padding: 1rem;
    max-height: 400px;
    overflow-y: auto;
    border-radius: 0.5rem;
    white-space: pre-wrap;
}
</style>
