<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const form = useForm({
    name: null,
    color: null,
});

const store = () => form.post(route('color-palettes-praise.store'));
</script>

<template>
    <Dashboard page-title="Nova cor">
        <Breadcrumb
            current-page-name="Nova cor"
            :link-list="[
                {
                    previous_page_url: 'color-palettes-praise',
                    previous_page_name: 'Cores',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Nova cor</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome da cor </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Azul"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="color" class="form-label"> Código da cor </label>

                    <input
                        id="color"
                        v-model="form.color"
                        type="color"
                        autocomplete="off"
                        class="form-control"
                        required
                    />
                    <small v-if="form.errors.color" class="text-danger mt-3">
                        {{ form.errors.color }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
