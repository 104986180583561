<script setup>
import { onMounted, ref, reactive } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import { vMaska } from 'maska/vue';
import { VMoney } from 'v-money';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import usePreviewImage from '@/Pages/Dashboard/Composables/usePreviewImage.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

import useMoney from '@/Pages/Dashboard/Composables/useMoney.vue';
import usePriceFormatter from '@/Pages/Dashboard/Composables/usePriceFormatter.vue';

const props = defineProps({
    event: Object,
    departments: Object,
    eventTypes: Object,
    eventStatus: Object,
    allMinisters: Object,
    allLeaders: Object,
    eventMinisters: Object,
    eventLeaders: Object,
    ministeringTheme: String,
    ministeringDetails: String,
    schedules: Object,
});

const { previewImage, imageUrl } = usePreviewImage();
const { money } = useMoney();
const { convertDecimalFormat } = usePriceFormatter();

const form = useForm({
    _method: 'PUT',
    ...props.event,
    banner: '',
    start_time: props.event.start_time_gross,
    details_ministry: props.ministeringDetails,
    theme: props.ministeringTheme,
    registration_fee: props.event.registration_fee,
    schedule_id: props.event.schedule_id,
    department_id: props.event.department_id ?? '',
    ministers: [],
    leaders: [],
});

const update = () =>
    form
        .transform((data) => ({
            ...data,
            registration_fee: convertDecimalFormat(data.registration_fee),
        }))
        .post(`/dashboard/events/${props.event.id}`);

const toggleSelection = (list, id) => {
    const index = form[list].indexOf(id);
    index === -1 ? form[list].push(id) : form[list].splice(index, 1);
};

onMounted(() => {
    imageUrl.value = `/storage/${props.event.banner}`;
    form.ministers = props.eventMinisters.map((minister) => minister.id);
    form.leaders = props.eventLeaders.map((leader) => leader.id);
});

const eventEditModalCloseButton = ref(null);

const destroy = () => {
    eventEditModalCloseButton.value.click();
    router.delete(`/dashboard/events/${props.event.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar evento">
        <Breadcrumb
            :current-page-name="event.name"
            :link-list="[{ previous_page_url: 'events', previous_page_name: 'Eventos' }]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link :href="`/dashboard/events/${event.id}`" class="btn btn-success btn-add me-2">
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#eventEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-4">
                <label for="name" class="form-label">Nome do evento</label>
                <input
                    id="name"
                    v-model="form.name"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Culto de consagração"
                    required
                />
                <small v-if="form.errors.name" class="text-danger mt-3">{{
                    form.errors.name
                }}</small>
            </div>

            <div class="col-md-4">
                <label for="start_time" class="form-label">Horário de início</label>
                <input
                    id="start_time"
                    v-model="form.start_time"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.start_time" class="text-danger mt-3">{{
                    form.errors.start_time
                }}</small>
            </div>

            <div class="col-md-4">
                <label for="schedule_id" class="form-label">Programação relacionada</label>
                <select
                    id="schedule_id"
                    v-model="form.schedule_id"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in schedules" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-4">
                <label for="department_id" class="form-label">Departamento</label>
                <select
                    id="department_id"
                    v-model="form.department_id"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option value="">Selecionar</option>
                    <option v-for="(item, index) in departments" :key="index" :value="item.id">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-4">
                <label for="type" class="form-label">Tipo do evento</label>
                <select
                    id="type"
                    :disabled="form.type == 'PAID_EVENT'"
                    v-model="form.type"
                    class="form-select"
                >
                    <option v-for="(item, index) in eventTypes" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-4">
                <label for="status" class="form-label">Status</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in eventStatus" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="youtube_url" class="form-label">Link Transmissão do Youtube</label>
                <small class="optional ms-2">(opcional)</small>
                <input
                    id="youtube_url"
                    v-model="form.youtube_url"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="https://www.youtube.com/watch?verbodavida"
                />
                <small v-if="form.errors.youtube_url" class="text-danger mt-3">{{
                    form.errors.youtube_url
                }}</small>
            </div>

            <div class="col-md-12">
                <label for="details" class="form-label">Descrição</label>
                <small class="optional ms-2">(opcional)</small>
                <textarea
                    id="details"
                    v-model="form.details"
                    class="form-control"
                    placeholder="Forneça um breve resumo dessa programação"
                    rows="5"
                />
                <small v-if="form.errors.details" class="text-danger mt-3">{{
                    form.errors.details
                }}</small>
            </div>

            <div class="col-md-12">
                <label for="summary" class="form-label">Resumo</label>
                <small class="optional ms-2">(opcional)</small>
                <textarea
                    id="summary"
                    v-model="form.summary"
                    class="form-control"
                    placeholder="Forneça um breve resumo dessa programação"
                    rows="5"
                />
                <small v-if="form.errors.summary" class="text-danger mt-3">{{
                    form.errors.summary
                }}</small>
            </div>

            <div class="col-md-12" v-if="form.type == 'PAID_EVENT'">
                <div class="row">
                    <label for="inputPassword" class="col-sm-9 col-form-label"
                        >Valor da inscrição</label
                    >
                    <div class="col-sm-3">
                        <input
                            id="registration_fee"
                            v-model="form.registration_fee"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            v-model.lazy="form.registration_fee"
                            v-money="money"
                        />
                        <small v-if="form.errors.registration_fee" class="text-danger mt-3">
                            {{ form.errors.registration_fee }}
                        </small>
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o dirigente do evento</legend>

            <div class="row">
                <div
                    v-for="(item, index) in allLeaders"
                    :key="index"
                    class="col-md-2 col-6 mb-3 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.leaders.includes(item.id),
                            }"
                            @click="toggleSelection('leaders', item.id)"
                        />
                        <span
                            v-show="form.leaders.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                    </label>
                </div>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione o ministro do evento</legend>

            <div class="row">
                <div
                    v-for="(item, index) in allMinisters"
                    :key="index"
                    class="col-md-2 col-6 text-center mb-3"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.ministers.includes(item.id),
                            }"
                            @click="toggleSelection('ministers', item.id)"
                        />
                        <span
                            v-show="form.ministers.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                    </label>
                </div>
            </div>

            <template v-if="form.ministers.length">
                <hr class="mb-0" />
                <legend>Dados da ministração</legend>
                <div class="col-md-12">
                    <label for="theme" class="form-label">Tema da ministração</label>
                    <input
                        id="theme"
                        v-model="form.theme"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Obediência"
                    />
                    <small v-if="form.errors.theme" class="text-danger mt-3">{{
                        form.errors.theme
                    }}</small>
                </div>

                <div class="col-md-12">
                    <label for="details_ministry" class="form-label">Detalhes da ministração</label>
                    <small class="optional ms-2">(opcional)</small>
                    <textarea
                        id="details_ministry"
                        v-model="form.details_ministry"
                        class="form-control"
                        placeholder="Forneça um breve resumo dessa programação"
                        rows="5"
                    />
                    <small v-if="form.errors.details_ministry" class="text-danger mt-3">{{
                        form.errors.details_ministry
                    }}</small>
                </div>
            </template>

            <hr class="mb-0" />

            <div class="col-md-12">
                <label for="formFile" class="form-label"
                    >Banner de Divulgação - Dimensões recomendadas: 1080 x 1080</label
                >
                <input
                    id="formFile"
                    class="form-control"
                    type="file"
                    @input="form.banner = $event.target.files[0]"
                    @change="previewImage"
                />
                <small class="mt-3 informative-text"
                    >O tamanho máximo que a imagem deve ter é de 2mb</small
                >
            </div>

            <div class="col-md-11 mx-auto">
                <img
                    v-if="imageUrl && imageUrl != '/storage/null'"
                    class="d-block mx-auto preview-image-banner border border-5 border-light shadow"
                    :src="imageUrl"
                />
            </div>
        </Form>
        <ConfirmationModal
            modal_id="eventEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="eventEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
