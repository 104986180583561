<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    discipleship: Object,
    disciplines: Object,
    instructors: Object,
});

const form = useForm({
    discipline_id: props.disciplines[0]?.id || null,
    instructor_id: null,
});

const store = () => form.post(`/dashboard/discipleships/${props.discipleship.id}/disciplines`);

const toggleInstructorSelection = (instructor_id) => {
    form.instructor_id = form.instructor_id === instructor_id ? null : instructor_id;
};
</script>

<template>
    <Dashboard page-title="Adicionar disciplina">
        <Breadcrumb
            current-page-name="Adicionar disciplina"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}/disciplines`,
                    previous_page_name: `${discipleship.name}`,
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Adicionar disciplina</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing || !form.discipline_id"
            >
                <div class="col-md-12">
                    <label for="floatingSelect" class="bg-transparent form-label"
                        >Selecione a disciplina desejada</label
                    >
                    <select id="type" v-model="form.discipline_id" class="form-select">
                        <option v-for="(item, index) in disciplines" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <hr />

                <legend class="mb-4">Selecione o instrutor dessa disciplina na turma</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in instructors"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`${item.member.identification_photo}`"
                                :class="{
                                    selected: form.instructor_id == item.id,
                                }"
                                @click="toggleInstructorSelection(item.id)"
                            />
                            <span
                                v-show="form.instructor_id == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
