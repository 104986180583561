<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Layout from '../Layout.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    classe: Object,
    children: Object,
    types: Object,
    sexs: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        type: 'FIXED',
        sex: 0,
    },
    `child-department-classe.children.index`,
    { class_id: props.classe.id }
);

watch(
    () => props.children,
    (newVal) => updateLoadingState(newVal),
    { immediate: true, deep: true }
);
</script>

<template>
    <Layout :classe="classe">
        <PageHeader
            title="Crianças"
            :button-link="route('child-department-classe.children.create', classe.id)"
            button-text="Nova"
        />

        <hr class="m-0 mb-3" />

        <div class="p-3 search-form-card">
            <div class="row">
                <div class="col-md-6">
                    <label for="sex" class="form-label">Sexo</label>
                    <select
                        id="sex"
                        v-model="searchParams.sex"
                        class="form-select"
                        aria-label="Selecione o sexo"
                        @change="executeSearch"
                    >
                        <option value="0">Todos os sexos</option>
                        <option v-for="item in sexs" :key="item.value" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6 mt-2 mt-sm-0">
                    <label for="type" class="form-label">Tipo de criança</label>
                    <select
                        id="type"
                        v-model="searchParams.type"
                        class="form-select"
                        aria-label="Selecione o tipo"
                        @change="executeSearch"
                    >
                        <option value="0">Todos os tipos</option>
                        <option v-for="item in types" :key="item.value" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="mt-3">
                <Table
                    table-id="table-children"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center fw-bold',
                        },
                        {
                            key: 'name',
                            label: 'Nome da criança',
                            thClass: 'text-start',
                            tdClass: 'text-start',
                        },
                        {
                            key: 'age',
                            label: 'Idade',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'type',
                            label: 'Tipo',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'family',
                            label: 'Família',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'ai',
                            label: 'A.I',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'ne',
                            label: 'N.E',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="children?.data"
                    :pagination="{ data: props.children?.meta, reference: 'children' }"
                    :rowLink="
                        (item) =>
                            `/dashboard/child-department-classes/${classe.id}/children/${item.id}`
                    "
                    :is-loading="isLoading"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-name="{ item }">
                        <div class="d-flex align-items-center">
                            <LazyImage width="30px" :src="item.child.photo_identification" />
                            <span class="ms-3">{{ item.child.clean_name }}</span>
                        </div>
                    </template>

                    <template #cell-age="{ item }">
                        {{ item.child.age }}
                    </template>

                    <template #cell-type="{ item }">
                        <Badge :name="item.child.type" />
                    </template>

                    <template #cell-family="{ item }">
                        <div v-if="item.child.family?.parents && item.child.family.parents.length">
                            <div class="d-flex justify-content-center align-items-center">
                                <div v-for="(parent, idx) in item.child.family.parents" :key="idx">
                                    <LazyImage width="30px" :src="parent.profile_photo_url" />
                                </div>
                            </div>
                        </div>
                        <template v-else>
                            <span
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-title="Não vinculado a nenhuma família"
                            >
                                <font-awesome-icon :icon="['fas', 'minus']" />
                            </span>
                        </template>
                    </template>

                    <template #cell-ai="{ item }">
                        <span
                            v-if="item.child.intolerant_foods"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :data-bs-title="'Intolerâncias: ' + item.child.intolerant_foods"
                        >
                            <font-awesome-icon
                                class="text-danger"
                                :icon="['fas', 'wheat-awn-circle-exclamation']"
                            />
                        </span>
                        <span
                            v-else
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Não intolerante a nenhum alimento"
                        >
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </template>

                    <template #cell-ne="{ item }">
                        <span
                            v-if="item.child.special_needs"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :data-bs-title="'Necessidades Especiais: ' + item.child.special_needs"
                        >
                            <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                        </span>
                        <span
                            v-else
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Não possui nenhuma necessidade especial"
                        >
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </template>
                </Table>
            </div>
        </div>
    </Layout>
</template>
