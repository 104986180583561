<script setup>
import { watch, defineProps } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    colors: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
    },
    'color-palettes-praise.index'
);

watch(
    () => props.colors,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Cores">
        <Breadcrumb current-page-name="Cores" />

        <PageHeader
            title="Cores"
            :button-link="route('color-palettes-praise.create')"
            button-text="Nova"
        />

        <div class="py-3 px-4 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label class="form-label" for="colorName">Nome da cor</label>
                    <input
                        id="colorName"
                        type="text"
                        v-model="searchParams.name"
                        class="form-control"
                        placeholder="Azul"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-colors"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'name', label: 'Nome' },
                    {
                        key: 'color',
                        label: 'Cor',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="colors?.data"
                :pagination="{
                    data: colors?.meta,
                    reference: 'colors',
                }"
                :rowLink="(item) => route('color-palettes-praise.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-color="{ item }">
                    <span class="p-2 text-white" :style="`background-color: ${item.color};`">
                        Exemplo
                    </span>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
