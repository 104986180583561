<script setup>
import { reactive, ref, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    waterBaptism: Object,
    members: Array,
    sexs: Object,
});

const form = useForm({
    member_id: null,
    name: '',
    member_name: '',
    phone: '',
    email: '',
    water_baptism_id: props.waterBaptism?.id ?? null,
    sex: props.sexs[0].value,
});

const seacher = reactive({
    member_name: null,
});

let timeoutId = null;

const search = () => {
    router.visit('/dashboard/water-baptisms/members/create', {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            if (!props.members.length) {
                form.member_id = null;
                form.phone = '';
                form.email = '';
                member.value = false;
            }
        },
    });
};

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 700);
};

const toggleSelection = (field, id, selectedMember) => {
    if (form[field] === id) {
        form[field] = null;
        form.name = '';
        form.phone = '';
        form.email = '';
        member.value = false;
    } else {
        form[field] = id;

        if (selectedMember) {
            form.name = selectedMember.clean_name || selectedMember.name;
            form.phone = selectedMember.phone_number || '';
            form.email = selectedMember.email || '';
            form.sex = selectedMember.sex || '';
        }
    }
};

const member = ref(false);

const store = () => form.post(`/dashboard/water-baptisms/members`);

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(
            route('water-baptisms.members.create'),
            {},
            { replace: true, preserveState: true }
        );
    }
});
</script>

<template>
    <Dashboard page-title="Nova Inscrição - Batismo nas Águas">
        <Breadcrumb
            current-page-name="Nova Inscrição"
            :link-list="[
                {
                    previous_page_url: 'water-baptisms',
                    previous_page_name: 'Turmas de Batismo',
                },
            ]"
        />

        <h4 class="my-3">Nova Inscrição</h4>

        <Form
            button-name="Salvar Inscrição"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <template v-if="waterBaptism">
                <div class="row g-2">
                    <p
                        class="p-3 bg-info bg-opacity-10 rounded-end border-primary border-start border-5 font-semibold fs-12"
                    >
                        Turma de Batismo: {{ waterBaptism.identifier }} - ({{
                            waterBaptism.completion_date
                        }})
                    </p>

                    <!-- Campo de busca por membro -->
                    <div class="col-md-12">
                        <label for="name" class="form-label">Buscar inscrito existente</label>
                        <input
                            id="name"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="Juliana Santiago"
                            v-model="form.member_name"
                            @input="compositionSeacher('member_name', $event)"
                        />
                        <small v-if="form.errors.member_name" class="text-danger mt-3">
                            {{ form.errors.member_name }}
                        </small>
                    </div>

                    <!-- Se encontramos membros, listamos -->
                    <template v-if="members?.length">
                        <hr />
                        <legend class="m-0">Membro(s) encontrado(s)</legend>
                        <ul class="list-group member-found">
                            <li
                                v-for="(item, index) in members"
                                :key="index"
                                class="list-group-item p-2 d-flex align-items-center mb-3"
                                :class="{ checked: form.member_id === item.id }"
                            >
                                <input
                                    :id="'checkBoxMember' + item.id"
                                    class="form-check-input me-1 mb-0"
                                    type="checkbox"
                                    name="listGroupRadio"
                                    :value="item.id"
                                    :checked="form.member_id === item.id"
                                    @change="toggleSelection('member_id', item.id, item)"
                                />
                                <label
                                    class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                    :for="'checkBoxMember' + item.id"
                                    style="cursor: pointer"
                                >
                                    <div class="d-flex justify-content-between align-items-center">
                                        <span>{{ item.name }}</span>
                                        <Link
                                            :href="`/dashboard/members/${item.id}`"
                                            target="_blank"
                                            class="btn btn-primary btn-add z-2 me-3"
                                        >
                                            Acessar perfil
                                        </Link>
                                    </div>
                                </label>
                            </li>
                        </ul>
                    </template>

                    <!-- Se não selecionar nenhum membro, preenchemos manualmente os dados do novo membro -->
                    <hr />
                    <legend class="m-0">Ou insira os dados de um novo</legend>
                    <div class="col-md-3">
                        <label for="newMemberPhone" class="form-label">Telefone</label>
                        <input
                            id="newMemberPhone"
                            v-model="form.phone"
                            v-maska
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="(00) 00000-0000"
                            data-maska="(##) #####-####"
                        />
                    </div>

                    <div class="col-md-5">
                        <label for="newMemberEmail" class="form-label">Email</label>
                        <input
                            id="newMemberEmail"
                            v-model="form.email"
                            type="email"
                            autocomplete="off"
                            class="form-control"
                            placeholder="exemplo@email"
                        />
                    </div>
                    <div class="col-md-4">
                        <label for="type" class="form-label">Sexo</label>
                        <select
                            id="type"
                            v-model="form.sex"
                            class="form-select form-select"
                            aria-label="Small select example"
                        >
                            <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </template>
            <template v-else>
                <p
                    class="p-3 bg-danger bg-opacity-10 rounded-end border-danger border-start border-5 font-semibold fs-12"
                >
                    Nenhuma turma de batismo aberta no momento
                </p>
            </template>
        </Form>
    </Dashboard>
</template>
