<script setup>
import { watch } from 'vue';
import { WhenVisible } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import ArtistCard from './Components/ArtistCard.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    artists: Object,
});

const { searchParams, updateLoadingState, executeSearch } = useSearch(
    {
        name: '',
    },
    'artists.index'
);

watch(
    () => props.artists,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Artistas">
        <Breadcrumb current-page-name="Artistas" />

        <PageHeader title="Artistas" :button-link="route('artists.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label for="artistName" class="form-label">Nome do artista</label>
                    <input
                        id="artistName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Fernandinho"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <hr />

            <WhenVisible data="artists">
                <template #fallback>
                    <Loader />
                </template>
                <div class="row mt-3">
                    <ArtistCard v-for="(item, index) in artists?.data" :key="index" :data="item" />

                    <Pagination
                        v-if="artists?.meta"
                        :data="artists?.meta"
                        reference-date="artists"
                    />
                </div>
            </WhenVisible>
        </div>
    </Dashboard>
</template>
