<script setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

const props = defineProps({
    lesson: Object,
    children: Object,
    teachers: Object,
});

const totalTeachers = computed(() => props.teachers.length);
const totalChildren = computed(() => props.children.length);
</script>

<template>
    <Dashboard page-title="Aula">
        <Breadcrumb
            current-page-name="Aula"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${lesson.child_department_class_id}/lessons`,
                    previous_page_name: 'Aulas',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Evento relacionado</h4>
            </div>
            <div class="col-md-6 text-end mt-2 mt-sm-0">
                <Link
                    :href="
                        route('child-department-classe.lessons.edit', {
                            class_id: lesson.child_department_class_id,
                            id: lesson.id,
                        })
                    "
                    class="btn btn-add btn-primary"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <div v-if="lesson.active" class="alert mx-0 font-semibold fs-12 alert-success" role="alert">
            Essa aula está ativa
        </div>

        <div class="card p-4">
            <h6 class="font-bold">Professores dessa aula ({{ totalTeachers }})</h6>

            <div class="row" v-if="teachers.length">
                <div
                    v-for="(teacher, index) in teachers"
                    :key="index"
                    class="col-md-2 col-6 mt-3 counselors"
                >
                    <Link
                        class="d-flex flex-column align-items-center"
                        :href="route('member-child-department.show', teacher.id)"
                    >
                        <LazyImage
                            width="40px"
                            class="rounded-circle"
                            :src="teacher.member.identification_photo"
                        />
                    </Link>
                    <span class="name d-block text-center mt-2">
                        {{ teacher.member.clean_name }}
                    </span>
                </div>
            </div>

            <p v-else>Nenhum professor adicionado ainda</p>
        </div>

        <div class="card p-4 mt-4">
            <div class="d-flex position-relative">
                <img
                    :src="`/storage/${lesson.event_photo}`"
                    class="flex-shrink-0 me-3 rounded-3 shadow-sm bg-secondary"
                    style="height: 125px; width: 108px"
                />
                <div>
                    <h5 class="mt-0 font-bold">
                        {{ lesson.event_name }} - {{ lesson.event_date }}
                    </h5>
                    <p>Este é o evento que está acontecendo nesse momento</p>

                    <Link
                        :href="`/dashboard/events/${lesson.event_id}`"
                        class="btn btn-success btn-add"
                    >
                        Acessar evento
                    </Link>
                </div>
            </div>
        </div>

        <div class="card p-4 mt-4">
            <div class="d-flex justify-content-between align-items-center">
                <h5 class="font-bold m-0">Material de Apoio</h5>
                <a
                    v-if="lesson.support_material"
                    :href="`/storage/${lesson.support_material}`"
                    download
                    class="btn btn-danger font-bold fs-12"
                >
                    <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                </a>
            </div>

            <hr />

            <div v-if="lesson.support_material">
                <iframe
                    :src="`/storage/${lesson.support_material}`"
                    width="100%"
                    height="400px"
                    class="border rounded shadow-sm"
                ></iframe>
            </div>

            <p v-else class="text-muted">Nenhum material de apoio disponível para esta aula.</p>
        </div>

        <template v-if="children.length">
            <h5 class="my-3 font-bold">{{ totalChildren }} Criança(s) presente(s) nessa aula</h5>

            <div class="p-3 search-form-card">
                <Table
                    table-id="table-children"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'child', label: 'Criança' },
                        {
                            key: 'family',
                            label: 'Família',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'visitor',
                            label: 'Visitante',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'ai', label: 'A.I', thClass: 'text-center', tdClass: 'text-center' },
                        { key: 'ne', label: 'N.E', thClass: 'text-center', tdClass: 'text-center' },
                    ]"
                    :data="children"
                    :pagination="{ reference: 'children' }"
                    :rowLink="(item) => route('child-lessons.show', item.id)"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-child="{ item }">
                        <div class="d-flex align-items-center justify-content-start">
                            <LazyImage width="30px" :src="item.child.photo_identification" />
                            <span class="ms-3">{{ item.child.clean_name }}</span>
                        </div>
                    </template>

                    <template #cell-family="{ item }">
                        <div v-if="item.child.family?.parents.length">
                            <div class="d-flex justify-content-center align-items-center">
                                <div v-for="(parent, idx) in item.child.family.parents" :key="idx">
                                    <LazyImage width="30px" :src="parent.profile_photo_url" />
                                </div>
                            </div>
                        </div>
                        <span v-else>
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </template>

                    <template #cell-visitor="{ item }">
                        <Badge :name="item.visitor ? 'YES' : 'NOT'" />
                    </template>

                    <template #cell-ai="{ item }">
                        <span v-if="item.child.intolerant_foods">
                            <font-awesome-icon
                                class="text-danger"
                                :icon="['fas', 'wheat-awn-circle-exclamation']"
                            />
                        </span>
                        <span v-else>
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </template>

                    <template #cell-ne="{ item }">
                        <span v-if="item.child.special_needs">
                            <font-awesome-icon class="text-warning" :icon="['fas', 'star']" />
                        </span>
                        <span v-else>
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </template>
                </Table>
            </div>
        </template>
    </Dashboard>
</template>
