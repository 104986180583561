<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    members: Object,
    discipleship: Object,
    sexs: Object,
    maritalStatus: Object,
    waterBaptismTypes: Object,
});

const form = useForm({
    member_id: null,
    member_name: '',
    phone: '',
    email: '',
    sex: props.sexs[0].value,
    discipleship_id: props.discipleship?.id ?? null,
    marital_status: props.maritalStatus[0].value || null,
    neighborhood: null,
    number: null,
    address: null,
    time_in_church: null,
    time_as_christian: null,
    water_baptism: null,
    water_baptism_type: props.waterBaptismTypes[0].value,
});

const seacher = reactive({
    member_name: null,
});

let timeoutId = null;

const search = () => {
    router.visit('/dashboard/discipleships/members/create', {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            if (!props.members || !props.members.length) {
                form.member_id = null;
                form.phone = '';
                form.email = '';
            }
        },
    });
};

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id, selectedMember) => {
    if (form[field] === id) {
        form[field] = null;
        form.member_name = '';
        form.phone = '';
        form.email = '';
        form.sex = props.sexs[0].value;
    } else {
        form[field] = id;
        if (selectedMember) {
            form.member_name = selectedMember.clean_name || selectedMember.name;
            form.phone = selectedMember.phone_number || '';
            form.email = selectedMember.email || '';
            form.sex = selectedMember.sex || props.sexs[0].value;
        }
    }
};

const store = () => form.post(`/dashboard/discipleships/${props.discipleship.id}/members`);
</script>

<template>
    <Dashboard page-title="Nova Inscrição - Discipulado">
        <Breadcrumb
            current-page-name="Nova Inscrição"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship?.id ?? ''}`,
                    previous_page_name: 'Turma',
                },
            ]"
        />

        <h4 class="my-3">Nova Inscrição</h4>

        <Form
            button-name="Salvar Inscrição"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <template v-if="discipleship">
                <div class="row g-2">
                    <p
                        class="p-3 bg-info bg-opacity-10 rounded-end border-primary border-start border-5 font-semibold fs-12"
                    >
                        Discipulado: {{ discipleship.name }} - ({{
                            discipleship.total_registrations
                        }}
                        inscrições)
                    </p>

                    <!-- Campo de busca por membro existente -->
                    <div class="col-md-12">
                        <label for="name" class="form-label">Buscar inscrito existente</label>
                        <input
                            id="name"
                            type="text"
                            autocomplete="off"
                            class="form-control"
                            placeholder="João Pedro de Souza"
                            v-model="form.member_name"
                            @input="compositionSeacher('member_name', $event)"
                            required
                        />
                        <small v-if="form.errors.member_name" class="text-danger mt-3">
                            {{ form.errors.member_name }}
                        </small>
                    </div>
                </div>

                <template v-if="members && members.length">
                    <hr />
                    <legend class="m-0">Membro(s) encontrado(s)</legend>
                    <ul class="list-group member-found">
                        <li
                            v-for="(item, index) in members"
                            :key="index"
                            class="list-group-item p-2 d-flex align-items-center mb-3"
                            :class="{ checked: form.member_id === item.id }"
                        >
                            <input
                                :id="'checkBoxMember' + item.id"
                                class="form-check-input me-1 mb-0"
                                type="checkbox"
                                name="listGroupRadio"
                                :value="item.id"
                                :checked="form.member_id === item.id"
                                @change="toggleSelection('member_id', item.id, item)"
                            />
                            <label
                                class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                                :for="'checkBoxMember' + item.id"
                                style="cursor: pointer"
                            >
                                <div class="d-flex justify-content-between align-items-center">
                                    <span>{{ item.name }}</span>
                                    <Link
                                        :href="`/dashboard/members/${item.id}`"
                                        target="_blank"
                                        class="btn btn-primary btn-add z-2 me-3"
                                    >
                                        Acessar perfil
                                    </Link>
                                </div>
                            </label>
                        </li>
                    </ul>
                    <hr class="my-0" />
                </template>

                <hr />
                <legend class="m-0">Ou insira os dados de um novo</legend>
                <div class="col-md-4">
                    <label for="newMemberPhone" class="form-label">Telefone</label>
                    <input
                        id="newMemberPhone"
                        v-model="form.phone"
                        v-maska
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="(00) 00000-0000"
                        data-maska="(##) #####-####"
                    />
                </div>

                <div class="col-md-5">
                    <label for="newMemberEmail" class="form-label">Email</label>
                    <input
                        id="newMemberEmail"
                        v-model="form.email"
                        type="email"
                        autocomplete="off"
                        class="form-control"
                        placeholder="exemplo@email.com"
                    />
                </div>

                <div class="col-md-3">
                    <label for="type" class="form-label">Sexo</label>
                    <select
                        id="type"
                        v-model="form.sex"
                        class="form-select"
                        aria-label="Selecione o sexo"
                    >
                        <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Estado civil</label>
                    <select id="type" v-model="form.marital_status" class="form-select">
                        <option
                            v-for="(item, index) in maritalStatus"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="time_in_church" class="form-label"> Tempo na igreja</label>

                    <input
                        id="time_in_church"
                        v-model="form.time_in_church"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.time_in_church" class="text-danger mt-3">
                        {{ form.errors.time_in_church }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="time_as_christian" class="form-label"> Tempo como cristão</label>

                    <input
                        id="time_as_christian"
                        v-model="form.time_as_christian"
                        type="date"
                        autocomplete="off"
                        class="form-control"
                    />
                    <small v-if="form.errors.time_as_christian" class="text-danger mt-3">
                        {{ form.errors.time_as_christian }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="address" class="form-label">Endereço</label>
                    <input
                        id="address"
                        v-model="form.address"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Rua São Jorge"
                        :disabled="addressExists"
                        required
                    />
                    <small v-if="form.errors.address" class="text-danger mt-3">
                        {{ form.errors.address }}
                    </small>
                </div>

                <div class="col-md-3">
                    <label for="number" class="form-label">Número</label>
                    <input
                        id="number"
                        v-model="form.number"
                        type="number"
                        autocomplete="off"
                        class="form-control"
                        placeholder="120"
                        :disabled="addressExists"
                    />
                    <small v-if="form.errors.number" class="text-danger mt-3">
                        {{ form.errors.number }}
                    </small>
                </div>

                <div class="col-md-3">
                    <label for="neighborhood" class="form-label">Bairro</label>
                    <input
                        id="neighborhood"
                        v-model="form.neighborhood"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Centro"
                        :disabled="addressExists"
                    />
                    <small v-if="form.errors.neighborhood" class="text-danger mt-3">
                        {{ form.errors.neighborhood }}
                    </small>
                </div>

                <div class="col-md-12">
                    <div class="form-check form-switch d-flex align-items-center">
                        <input
                            class="form-check-input my-0"
                            v-model="form.water_baptism"
                            type="checkbox"
                            role="switch"
                            id="water_baptism"
                        />
                        <label class="form-check-label ms-3" for="water_baptism"
                            >Já recebeu o batismo nas Águas?</label
                        >
                    </div>
                </div>

                <div class="col-md-12" v-if="form.water_baptism">
                    <label for="type" class="form-label">Tipo de batismo nas águas</label>
                    <select id="type" v-model="form.water_baptism_type" class="form-select">
                        <option
                            v-for="(item, index) in waterBaptismTypes"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </template>

            <template v-else>
                <p
                    class="p-3 bg-danger bg-opacity-10 rounded-end border-danger border-start border-5 font-semibold fs-12"
                >
                    Nenhuma turma de discipulado aberta no momento
                </p>
            </template>
        </Form>
    </Dashboard>
</template>
