<script setup>
import { ref, defineProps, computed } from 'vue';
import 'leaflet/dist/leaflet.css';

import { LMap, LTileLayer, LTooltip, LMarker, LIcon } from '@vue-leaflet/vue-leaflet';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import BarChart from '@/Pages/Dashboard/Graphics/BarChart.vue';

const props = defineProps({
    families: Object,
    peopleCountByNeighborhood: Object,
});

const zoom = ref(15);

const familyArray = computed(() => Object.values(props.families));
</script>

<template>
    <Dashboard pageTitle="Mapa de membros">
        <Breadcrumb current-page-name="Mapa de membros" />
        <h4 class="my-3">Mapa de membros</h4>
        <div>
            <l-map :center="[-9.4019589, -38.2323677]" :zoom="zoom" style="height: 700px">
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>
                <l-marker
                    v-for="(family, index) in familyArray"
                    :key="index"
                    :lat-lng="[family.location.latitude, family.location.longitude]"
                >
                    <l-tooltip>
                        <span class="fw-bold">{{ family.name }}</span>
                        <ul class="list-group mt-2">
                            <li
                                v-for="(member, index) in family.members"
                                class="list-group-item"
                                :key="index"
                            >
                                <img
                                    class="rounded-circle me-2"
                                    width="25"
                                    :src="member.photo ? member.photo : '/img/avatars/male.png'"
                                    alt=""
                                />
                                {{ member.name }}
                            </li>
                        </ul>
                    </l-tooltip>
                </l-marker>

                <l-marker :lat-lng="[-9.4019589, -38.2323677]">
                    <l-icon :icon-anchor="[16, 17]">
                        <div class="headline">Igreja</div>
                        <img
                            width="60"
                            height="60"
                            src="https://verbodavidavilamatilde.com.br/wp-content/uploads/2019/10/logo-Igreja-Verbo-da-Vida-fundo-Preto-01.png"
                        />
                    </l-icon>
                </l-marker>
            </l-map>
            <hr />
            <div class="row">
                <div class="col-sm-12">
                    <div class="card d-flex justify-content-center p-3 border">
                        <div class="card-header bg-white fw-bold">
                            Divisão de membros pelo maiores bairros
                        </div>
                        <BarChart
                            :labels="peopleCountByNeighborhood.labels"
                            :series="peopleCountByNeighborhood.series"
                        />
                    </div>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
