<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    plannings: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        theme: null,
    },
    'musical-plannings.index'
);

watch(
    () => props.plannings,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Planejamento">
        <Breadcrumb current-page-name="Planejamentos" />

        <PageHeader
            title="Planejamentos"
            :button-link="route('musical-plannings.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <label for="theme" class="form-label">Nome do evento</label>
                    <input
                        id="theme"
                        v-model="searchParams.theme"
                        type="text"
                        class="form-control"
                        placeholder="Cura Divina"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-musical-plannings"
                :headers="[
                    { key: 'index', label: 'ID', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'theme', label: 'Tema' },
                    {
                        key: 'event',
                        label: 'Evento',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'musics',
                        label: 'Músicas',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'palette',
                        label: 'Paleta',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'event_date',
                        label: 'Data',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="plannings?.data"
                :pagination="{
                    data: plannings?.meta,
                    reference: 'plannings',
                }"
                :rowLink="(item) => route('musical-plannings.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-event="{ item }">
                    <Link
                        class="d-flex justify-content-center"
                        :href="route('events.show', item.event_id)"
                    >
                        <LazyImage
                            width="35px"
                            height="35px"
                            :src="`/storage/${item.event.banner}`"
                        />
                    </Link>
                </template>

                <template #cell-musics="{ item }">
                    {{ item.musics.length }}
                </template>

                <template #cell-palette="{ item }">
                    <div class="d-flex justify-content-center" v-if="item.colors.length">
                        <div
                            v-for="(color, index) in item.colors"
                            :key="index"
                            :style="{
                                width: '30px',
                                height: '30px',
                                backgroundColor: color.color,
                                borderRadius: '50%',
                                marginLeft: '-8px',
                            }"
                            class="border border-2 border-info"
                            :title="color.name"
                        ></div>
                    </div>
                    <span v-else title="Nenhuma cadastrada">
                        <font-awesome-icon :icon="['fas', 'minus']" />
                    </span>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
