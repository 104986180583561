<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    visits: Object,
});

const { searchParams, executeSearch, isLoading, updateLoadingState, buildDownloadUrl } = useSearch(
    { start_date: '', end_date: '' },
    'hospital-verb-visit.index'
);

watch(
    () => props.visits,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Verbo nos hospitais">
        <Breadcrumb current-page-name="Verbo nos hospitais" />

        <PageHeader
            title="Verbo nos hospitais"
            :button-link="route('hospital-verb-visit.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar visitas</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('hospital-verb-visits', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('hospital-verb-visits', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row align-items-center">
                <div class="col-6">
                    <label for="startDate" class="form-label">Data de início</label>
                    <input
                        id="startDate"
                        v-model="searchParams.start_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>
                <div class="col-6">
                    <label for="endDate" class="form-label">Data de fim</label>
                    <input
                        id="endDate"
                        v-model="searchParams.end_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>
            </div>

            <Table
                table-id="table-hospital-verb-visits"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'identification',
                        label: 'Identificação da visita',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_people',
                        label: 'Vidas alcançadas',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="visits?.data"
                :pagination="{
                    data: visits?.meta,
                    reference: 'visits',
                }"
                :rowLink="(item) => `/dashboard/hospital-verb-visits/${item.id}`"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell-identification="{ item }">
                    {{ item.identification }}
                </template>
                <template #cell-total_people="{ item }">
                    {{ item.total_people }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
