<script setup>
import { ref } from 'vue';
import { useForm, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Pages/Dashboard/Composables/useImageResizer.js';

const props = defineProps({
    album: Object,
    allMusics: Object,
    albumMusics: Object,
});

const form = useForm({
    artist_id: props.album.artist_id,
    title: props.album.title,
    release_date: props.album.release_date_gross,
    cover_image: `/storage/${props.album.cover_image}`,
    selected_musics: props.albumMusics.map((item) => item.id),
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.cover_image);

const update = () => {
    form.cover_image = photo.value;
    form.changed_photo = changedPhoto.value;
    form.put(route('albums.update', props.album.id));
};
</script>

<template>
    <Dashboard page-title="Editar álbum">
        <Breadcrumb
            current-page-name="Editar álbum"
            :link-list="[
                {
                    previous_page_url: 'albums',
                    previous_page_name: 'Álbuns',
                },
            ]"
        />

        <div id="edit-album">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="route('albums.show', album.id)"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center mb-4">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Capa do álbum"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#coverUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.cover_image">
                    {{ form.errors.cover_image }}
                </div>

                <div class="col-md-6 mb-3">
                    <label for="title" class="form-label">Título do álbum</label>
                    <input
                        id="title"
                        v-model="form.title"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Digite o título do álbum"
                        required
                    />
                    <small v-if="form.errors.title" class="text-danger mt-3">
                        {{ form.errors.title }}
                    </small>
                </div>

                <div class="col-md-6 mb-3">
                    <label for="release_date" class="form-label">Data de Lançamento</label>
                    <input
                        id="release_date"
                        v-model="form.release_date"
                        type="date"
                        class="form-control"
                        required
                    />
                    <small v-if="form.errors.release_date" class="text-danger mt-3">
                        {{ form.errors.release_date }}
                    </small>
                </div>

                <hr />

                <legend class="mb-3">Selecione as músicas para este álbum</legend>
                <div class="row">
                    <div
                        v-for="music in allMusics"
                        :key="music.id"
                        class="col-md-3 col-6 mb-3 text-center"
                    >
                        <label class="music-checkbox position-relative">
                            <img
                                :src="`/storage/${music.cover_photo}`"
                                alt="Capa da Música"
                                class="img-thumbnail mb-2"
                                style="width: 100px; height: 100px; object-fit: cover"
                            />
                            <div>
                                <input
                                    type="checkbox"
                                    :value="music.id"
                                    v-model="form.selected_musics"
                                    class="d-none"
                                />

                                <span
                                    v-if="form.selected_musics.includes(music.id)"
                                    class="badge bg-primary p-2"
                                >
                                    Selecionado
                                </span>
                                <span v-else class="badge bg-secondary p-2"> Selecionar </span>
                            </div>
                            <p class="mt-2">{{ music.name }}</p>
                        </label>
                    </div>
                </div>

                <div
                    class="alert alert-danger mx-2"
                    role="alert"
                    v-if="form.errors.selected_musics"
                >
                    {{ form.errors.selected_musics }}
                </div>
                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>

            <PhotoEditModal
                v-if="form.cover_image"
                title="Editar capa"
                id="coverUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
