<script setup>
import { defineProps } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

// Composable só se você quiser formatar preço, como no seu exemplo
import usePriceFormatter from '@/Pages/Dashboard/Composables/usePriceFormatter.vue';
const { convertToCurrencyFormat } = usePriceFormatter();

// Props que vêm do controller
const props = defineProps({
    ticketExists: {
        type: Boolean,
        default: false,
    },
    member: {
        type: Object,
        default: null,
    },
    creatorRegistration: {
        type: Object,
        default: null,
    },
    event: {
        type: Object,
        default: null,
    },
});
</script>

<template>
    <Dashboard page-title="Verificar Ticket">
        <!-- Breadcrumb -->
        <Breadcrumb current-page-name="Inscrição" />

        <!-- Título da página -->
        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Verificar Ticket</h4>
            </div>
        </div>

        <!-- Se o ticket existe, exibir card com informações do membro -->
        <template v-if="ticketExists && member">
            <div class="card-simple p-4">
                <div class="row g-0">
                    <div class="col-md-3 d-flex align-items-center justify-content-center">
                        <img
                            width="100"
                            :src="member.identification_photo"
                            class="img-fluid rounded-circle"
                            alt="Foto do Membro"
                        />
                    </div>
                    <div class="col-md-9 d-flex align-items-center justify-content-center">
                        <div class="card-body">
                            <h5 v-if="member.member_id" class="card-title mt-3 mt-sm-0">
                                <Link :href="`/dashboard/members/${member.member_id}`">{{
                                    member.name
                                }}</Link>
                            </h5>
                            <h5 class="card-title mt-3 mt-sm-0" v-else>
                                {{ member.name }}
                            </h5>
                            <p class="card-text mt-3">
                                <Badge :name="convertToCurrencyFormat(member.amount_paid)" />
                                - Inscrição feita em {{ member.created_at }}
                                utilizando o método
                                <Badge :name="member.payment_method" />
                            </p>
                        </div>
                    </div>
                </div>
                <!-- Casadinha, responsável, etc., se você quiser mostrar -->
                <template v-if="member.married && member.married_id">
                    <hr />
                    <p class="mt-3 p-3 bg-primary bg-opacity-10 border border-primary my-3">
                        Essa inscrição foi feita como uma casadinha. Ela está conectada com a
                        seguinte
                        <Link
                            :href="`/dashboard/events/${member.event_id}/members/${member.married_id}`"
                            >inscrição</Link
                        >.
                    </p>
                </template>

                <template v-if="member.responsible">
                    <hr />
                    <p class="mt-3 p-3 bg-success bg-opacity-10 border border-success my-3">
                        Esse inscrito do evento é menor de idade, dessa forma ele tem como
                        responsável
                        <span v-if="member.responsible_id">
                            <Link :href="`/dashboard/members/${member.responsible_id}`">
                                {{ member.responsible_name }} </Link
                            >.
                        </span>
                        <span v-else> {{ member.responsible_name }}. </span>
                    </p>
                </template>

                <hr />

                <div class="alert alert-success" role="alert">
                    O ticket informado é válido para <strong>{{ member.event_name }}</strong
                    >.
                </div>
            </div>
        </template>

        <!-- Se não existe, mostrar alerta de erro -->
        <template v-else>
            <div class="alert alert-danger" role="alert">
                <h4 class="alert-heading">Ticket inválido!</h4>
                <p>Não encontramos nenhum registro para o código informado.</p>
            </div>
        </template>
    </Dashboard>
</template>
