<script setup>
import { ref } from 'vue';
import { Link, useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    group: Object,
    member: Object,
    status: Object,
    functions: Object,
});

const form = useForm({
    function: props.member.function,
    status: props.member.status,
    host: props.member.host ? true : false,
});

const update = () =>
    form.put(`/dashboard/group-connections/${props.group.id}/members/${props.member.id}`);

const memberEditModalCloseButton = ref(null);

const destroy = () => {
    memberEditModalCloseButton.value.click();
    router.delete(`/dashboard/group-connections/${props.group.id}/members/${props.member.id}`);
};
</script>

<template>
    <Dashboard :page-title="member.member.clean_name">
        <Breadcrumb
            :current-page-name="member.member.clean_name"
            :link-list="[
                {
                    previous_page_url: `group-connections/${group.id}/members`,
                    previous_page_name: 'Membros',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/group-connections/${group.id}/members/${member.id}`"
                    class="btn btn-add btn-success me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#memberEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-6">
                <label for="function" class="form-label">Função do membro</label>
                <select
                    id="function"
                    v-model="form.function"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in functions" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-6">
                <label for="status" class="form-label">Status do membro</label>
                <select
                    id="status"
                    v-model="form.status"
                    class="form-select form-select"
                    aria-label="Small select example"
                >
                    <option v-for="(item, index) in status" :key="index" :value="item.value">
                        {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.host"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault"> Anfitrião? </label>
                </div>
            </div>

            <ConfirmationModal
                modal_id="memberEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="memberEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </Form>
    </Dashboard>
</template>
