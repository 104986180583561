import { ref } from 'vue';

export function useImageResizer(initialPhoto = '/img/avatars/male.png') {
    const photo = ref(initialPhoto);
    const changedPhoto = ref(false);

    const handleImageResized = (blob) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            photo.value = e.target.result;
            changedPhoto.value = true;
        };
        reader.readAsDataURL(blob);
    };

    return {
        photo,
        changedPhoto,
        handleImageResized,
    };
}
