<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    request: Object,
    isPastor: Boolean,
    isLeader: Boolean,
    canRemove: Boolean,
});

const form = useForm({
    comments_pastor: props.request.comments_pastor,
    comments_leader: props.request.comments_leader,
});

const update = () => form.put(`/dashboard/departments/entry-requests/${props.request.id}`);

const departmentEditModalCloseButton = ref(null);

const destroy = () => {
    departmentEditModalCloseButton.value.click();
    router.delete(`/dashboard/departments/entry-requests/${props.request.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar observação">
        <Breadcrumb
            current-page-name="Editar observação"
            :link-list="[
                {
                    previous_page_url: `departments/entry-requests/${request.id}`,
                    previous_page_name: 'Solicitação',
                },
            ]"
        />
        <div id="create-minister">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/departments/entry-requests/${request.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        v-if="canRemove"
                        data-bs-toggle="modal"
                        data-bs-target="#requestEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing || (!isLeader && !isPastor)"
            >
                <p
                    v-if="!isLeader && !isPastor"
                    class="bg-warning bg-opacity-10 border-start border-warning border-5 p-3 mt-3"
                >
                    Infelizmente, você não tem permissão para alterar as observações da solicitação.
                    Somente o pastor e o líder do departamento possuem essa autorização.
                </p>

                <div class="col-md-12" v-if="isLeader">
                    <label for="comments_leader" class="form-label"> Observações líder </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="comments_leader"
                        v-model="form.comments_leader"
                        type="text"
                        class="form-control"
                        name="comments_leader"
                        placeholder="Caso necessário, informe algo importante nesse ponto"
                        rows="5"
                    />

                    <small v-if="form.errors.comments_leader" class="text-danger mt-3">
                        {{ form.errors.comments_leader }}
                    </small>
                </div>
                <div class="col-md-12" v-if="isPastor">
                    <label for="comments_pastor" class="form-label"> Observações pastor </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="comments_pastor"
                        v-model="form.comments_pastor"
                        type="text"
                        class="form-control"
                        name="comments_pastor"
                        placeholder="Caso necessário, informe algo importante nesse ponto"
                        rows="5"
                    />

                    <small v-if="form.errors.comments_pastor" class="text-danger mt-3">
                        {{ form.errors.comments_pastor }}
                    </small>
                </div>
            </Form>
            <ConfirmationModal
                modal_id="requestEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="departmentEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </div>
    </Dashboard>
</template>
