<script setup>
import { Link } from '@inertiajs/vue3';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    rooms: Object,
});

const rowLink = (room) => `/dashboard/rooms/${room.id}`;
</script>

<template>
    <Dashboard page-title="Salas">
        <Breadcrumb current-page-name="Salas" />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Salas</h4>
                <Link :href="`/dashboard/rooms/create`" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <Table
                table-id="table-rooms"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'identifier', label: 'Nome de identificação' },
                ]"
                :data="rooms?.data"
                :rowLink="rowLink"
                :pagination="{ reference: 'rooms' }"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-identifier="{ item }">
                    {{ item.identifier }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
