<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import FamilyOrganizationCad from '../../Families/Components/FamilyOrganizationCard.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

const props = defineProps({
    child: Object,
    classes: Object,
    lessons: Object,
});

const goToLessonPage = (classeID, lessonID) =>
    router.get('child-department-classes.show', { class_id: classeID, id: lessonID });
</script>

<template>
    <Dashboard :page-title="child.name">
        <Breadcrumb
            :current-page-name="child.clean_name"
            :link-list="[
                {
                    previous_page_url: 'children',
                    previous_page_name: 'Crianças',
                },
            ]"
        />

        <div class="d-flex justify-content-end container-buttons">
            <Link :href="route('children.edit', child.id)" class="btn btn-add btn-primary">
                <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                Editar dados
            </Link>
        </div>

        <div class="mb-3 mt-3 mt-sm-0">
            <div id="child-details" class="row mb-3 px-3">
                <div class="d-flex justify-content-center">
                    <LazyImage
                        width="200px"
                        :showBorder="true"
                        :src="`${child.photo_identification}`"
                    />
                </div>
                <h4 class="text-center mt-4">
                    {{ child.name }}
                </h4>
            </div>
        </div>

        <div class="p-3 row mt-2">
            <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link active"
                        id="home-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#personal-data"
                        type="button"
                        role="tab"
                        aria-controls="personal-data"
                        aria-selected="true"
                    >
                        Dados pessoais
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="family-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#family-tab-pane"
                        type="button"
                        role="tab"
                        :disabled="!child.family"
                        aria-controls="family-tab-pane"
                        aria-selected="false"
                    >
                        Família relacionada
                    </button>
                </li>

                <li class="nav-item" role="presentation">
                    <button
                        class="nav-link"
                        id="classe-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#classe-tab-pane"
                        type="button"
                        role="tab"
                        aria-controls="classe-tab-pane"
                        aria-selected="false"
                    >
                        Turmas e aulas
                    </button>
                </li>
            </ul>
            <div class="tab-content mt-3" id="myTabContent">
                <div
                    class="tab-pane fade show active form-card p-4 mx-0"
                    id="personal-data"
                    role="tabpanel"
                    aria-labelledby="home-tab"
                    tabindex="0"
                >
                    <form action="">
                        <fieldset class="mb-3">
                            <div class="row g-3">
                                <div class="col-sm-6">
                                    <label for="name" class="form-label"> Nome da criança </label>

                                    <input
                                        disabled
                                        id="name"
                                        :value="child.name"
                                        type="text"
                                        autocomplete="off"
                                        class="form-control"
                                    />
                                </div>

                                <div class="col-sm-6">
                                    <label for="name" class="form-label">
                                        Data de nascimento ({{ child.age }})</label
                                    >

                                    <input
                                        disabled
                                        id="name"
                                        :value="child.birth_date"
                                        type="date"
                                        autocomplete="off"
                                        class="form-control"
                                    />
                                </div>

                                <div class="col-sm-6">
                                    <label for="sex" class="form-label d-block">
                                        Tipo de criança
                                    </label>
                                    <Badge :name="child.type" />
                                </div>

                                <div class="col-sm-6">
                                    <label for="sex" class="form-label d-block"> Sexo </label>
                                    <Badge :name="child.sex" />
                                </div>

                                <div class="col-md-12" v-if="child.observations">
                                    <label for="observations" class="form-label">
                                        Observações
                                    </label>
                                    <small class="optional ms-2">(opcional)</small>

                                    <textarea
                                        id="observations"
                                        v-model="child.observations"
                                        type="text"
                                        class="form-control"
                                        name="observations"
                                        placeholder="Forneça alguma observação"
                                        rows="5"
                                    />
                                </div>

                                <div class="col-md-12" v-if="child.intolerant_foods">
                                    <label for="intolerant_foods" class="form-label">
                                        Alimentos de intolerância
                                    </label>

                                    <input
                                        id="intolerant_foods"
                                        v-model="child.intolerant_foods"
                                        type="text"
                                        class="form-control"
                                        name="intolerant_foods"
                                        placeholder="Forneça alguma observação"
                                        rows="5"
                                    />
                                </div>

                                <div class="col-md-12" v-if="child.special_needs">
                                    <label for="special_needs" class="form-label">
                                        Necessidades especiais
                                    </label>

                                    <input
                                        id="special_needs"
                                        v-model="child.special_needs"
                                        type="text"
                                        class="form-control"
                                        name="special_needs"
                                        placeholder="Forneça alguma observação"
                                        rows="5"
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </form>
                </div>
                <div
                    v-if="child.family"
                    class="tab-pane fade"
                    id="family-tab-pane"
                    role="tabpanel"
                    aria-labelledby="family-tab"
                    tabindex="0"
                >
                    <FamilyOrganizationCad :family="child.family" />
                </div>

                <div
                    class="tab-pane fade"
                    id="classe-tab-pane"
                    role="tabpanel"
                    aria-labelledby="classe-tab"
                    tabindex="0"
                >
                    <h5 class="font-bold mb-3">{{ classes.length }} Turma(s) relacionada(s)</h5>

                    <div
                        class="card classe-card mb-4"
                        :class="{
                            'border-primary': item.current,
                            'opacity-50': !item.current,
                        }"
                        v-for="(item, index) in classes"
                        :key="index"
                    >
                        <div class="card-header p-0 position-relative">
                            <img
                                :src="`/storage/${item.classe.banner}`"
                                class="card-img-top"
                                alt=""
                                style="width: 100%; height: 100px; object-fit: cover"
                                :style="!item.current ? 'opacity: 0.5;' : ''"
                            />

                            <div class="position-absolute top-0 end-0 m-3" v-if="item.current">
                                <button
                                    class="btn btn-success text-white shadow font-bold fs-12 border"
                                >
                                    Turma atual
                                    <font-awesome-icon
                                        class="ms-2"
                                        :icon="['fas', 'circle-check']"
                                    />
                                </button>
                            </div>
                        </div>

                        <div class="card-body">
                            <h5
                                class="font-bold d-flex justify-content-between align-items-center"
                                :class="{ 'text-muted': !item.current }"
                            >
                                <span>Turma {{ item.classe.name }}</span>

                                <Link
                                    :href="`/dashboard/child-department-classes/${item.classe.id}`"
                                    class="btn"
                                    :class="
                                        item.current
                                            ? 'btn-primary fs-12'
                                            : 'btn-secondary disabled'
                                    "
                                    v-if="item.current"
                                >
                                    Acessar turma
                                </Link>
                            </h5>
                        </div>
                    </div>

                    <template v-if="lessons.length">
                        <hr />

                        <h5 class="font-bold my-3">Últimas aulas assistidas</h5>

                        <div class="table-responsive search-form-card p-0">
                            <table class="table m-0 align-middle table-hover" id="table-families">
                                <thead class="table-light">
                                    <tr>
                                        <th class="text-center" scope="col">#</th>
                                        <th scope="col">Evento relacionado</th>
                                        <th class="text-center" scope="col">Data</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr
                                        v-for="(lesson, index) in lessons"
                                        :key="index"
                                        @click="
                                            goToLessonPage(
                                                lesson.classe.id,
                                                lesson.child_department_lesson_id
                                            )
                                        "
                                    >
                                        <td class="text-center">
                                            {{ index + 1 }}
                                        </td>
                                        <td>
                                            <img
                                                height="35"
                                                width="35"
                                                class="rounded-circle border border-1 border-ligth"
                                                :src="`/storage/${lesson.event_banner}`"
                                                alt=""
                                            />
                                            <span class="ms-3">{{ lesson.event_name }}</span>
                                        </td>
                                        <td class="text-center">
                                            {{ lesson.event_date }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
