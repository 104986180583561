<script setup>
import { computed, ref } from 'vue';
import { Link } from '@inertiajs/vue3';

import 'leaflet/dist/leaflet.css';

import { LMap, LTileLayer, LTooltip, LMarker, LIcon } from '@vue-leaflet/vue-leaflet';

// Components
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

import FamilyOrganizationCad from './Components/FamilyOrganizationCard.vue';

const props = defineProps({
    family: Object,
    creatorRegistration: Object,
});

const totalBelievers = computed(() => {
    return props.family.members.filter((member) => member.believer).length;
});

const totalWithMemberId = computed(() => {
    return props.family.members.filter((member) => member.church_member).length;
});

const zoom = ref(15);
</script>

<template>
    <Dashboard :page-title="family.name">
        <div id="show-water-baptism">
            <Breadcrumb
                :current-page-name="family.name"
                :link-list="[
                    {
                        previous_page_url: 'families',
                        previous_page_name: 'Famílias',
                    },
                ]"
            />

            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-sm-6">
                    <h4 class="font-bold m-0">{{ family.name }}</h4>
                </div>
                <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/families/edit/${family.id}`"
                        class="btn btn-primary btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
            </div>

            <div class="p-3 bg-info bg-opacity-10 border border-info" v-if="family.members.length">
                <font-awesome-icon class="me-2" :icon="['fas', 'people-roof']" /> Família composta
                por <span class="fw-bold">{{ family.members.length }}</span> membros, dos quais
                <span class="fw-bold">{{ totalWithMemberId }}</span> já fazem parte da nossa igreja
            </div>

            <div
                class="p-3 bg-warning bg-opacity-10 border border-warning my-3"
                v-if="totalBelievers < family.members.length"
            >
                <font-awesome-icon class="me-2" :icon="['fas', 'person-praying']" />
                <span class="fw-bold">{{ totalBelievers }}/{{ family.members.length }}</span>
                membros já aceitaram jesus nela
            </div>

            <FamilyOrganizationCad :family="family" />
        </div>

        <hr />

        <h4 class="font-bold font-bold mb-0">Endereço(s) no mapa</h4>
        <span class="text-secondary font-semibold fs-12">Facilitando a visualização</span>

        <div class="mt-3">
            <l-map
                :center="[-9.4019589, -38.2323677]"
                class="rounded-3"
                :zoom="zoom"
                style="height: 500px"
            >
                <l-tile-layer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                ></l-tile-layer>

                <l-marker
                    v-for="(item, index) in family.addresses"
                    :key="index"
                    :lat-lng="[item.location.latitude, item.location.longitude]"
                >
                    <l-tooltip>
                        <span class="fw-bold">Casa da família</span>
                    </l-tooltip>
                </l-marker>

                <l-marker :lat-lng="[-9.4019589, -38.2323677]">
                    <l-icon :icon-anchor="[16, 17]">
                        <div class="headline">Igreja</div>
                        <img
                            width="60"
                            height="60"
                            src="https://verbodavidavilamatilde.com.br/wp-content/uploads/2019/10/logo-Igreja-Verbo-da-Vida-fundo-Preto-01.png"
                        />
                    </l-icon>
                </l-marker>
            </l-map>
        </div>

        <CreatorRecord
            v-if="creatorRegistration.name"
            :data="creatorRegistration"
            title="Família cadastrada por: "
        />
    </Dashboard>
</template>
