<script setup>
import { defineProps } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    disciplines: Object,
});

const { searchParams, executeSearch, isLoading } = useSearch(
    { name: '' },
    'disciplines-discipleship.index'
);
</script>

<template>
    <Dashboard page-title="Disciplinas">
        <Breadcrumb current-page-name="Disciplinas" />

        <PageHeader
            title="Disciplinas"
            :button-link="`/dashboard/disciplines-discipleship/create`"
            button-text="Nova"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label class="form-label" for="floatingInput">Nome da disciplina</label>
                    <input
                        id="floatingInput"
                        type="email"
                        v-model="searchParams.name"
                        class="form-control"
                        placeholder="Fundamentos da Fé"
                        @input="executeSearch"
                    />
                </div>
            </div>

            <Table
                table-id="table-disciplines-discipleship"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'name', label: 'Nome da disciplina' },
                    {
                        key: 'created_at',
                        label: 'Data de criação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="disciplines?.data"
                :pagination="{ reference: 'disciplines' }"
                :rowLink="(item) => `/dashboard/disciplines-discipleship/${item.id}`"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage :src="`/storage/${item.photo_identification}`" width="35px" />
                        <span class="ms-3">{{ item.name }}</span>
                    </div>
                </template>

                <template #cell-created_at="{ item }">
                    {{ item.created_at }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
