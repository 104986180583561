<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    events: Object,
    counselors: Object,
    members: Object,
});

const mounted = ref(false);

const form = useForm({
    event_id: props.events[0]?.id || null,
    counselors: [],
    member_id: null,
    current_church: '',
    observations: null,
});

const seacher = reactive({
    member_name: null,
});

const search = () => {
    router.visit(route('members.baptism-holy-spirits.create'), {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
            member.value = false;
            baptismHolySpiritsAlreadyExists.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const store = () => form.post(route('members.baptism-holy-spirits'));

const member = ref(false);
const baptismHolySpiritsAlreadyExists = ref(false);

const toggleCounselorSelection = (counselorId) => {
    const index = form.counselors.indexOf(counselorId);
    return index === -1 ? form.counselors.push(counselorId) : form.counselors.splice(index, 1);
};

const toggleSelection = (field, id) => {
    member.value = props.members.find((v) => v.id == id);

    baptismHolySpiritsAlreadyExists.value = false;

    if (member.value.baptism_holy_spirit) {
        baptismHolySpiritsAlreadyExists.value = true;
    }

    if (form[field] === id) {
        form[field] = null;
        member.value = false;
    } else {
        form[field] = id;
    }
};

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(
            route('members.baptism-holy-spirits.create'),
            {},
            { replace: true, preserveState: true }
        );
    }
});
</script>

<template>
    <Dashboard page-title="Batismo no Espírito Santo">
        <Breadcrumb
            current-page-name="Batismo no Espírito Santo"
            :link-list="[
                {
                    previous_page_url: 'baptism-holy-spirits',
                    previous_page_name: 'Batismos no Espírito Santo',
                },
            ]"
        />

        <h4 class="my-3">Batismo no Espírito Santo</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.member_id || baptismHolySpiritsAlreadyExists"
        >
            <div
                v-if="baptismHolySpiritsAlreadyExists"
                class="p-3 bg-info bg-opacity-10 border border-info rounded"
            >
                {{ member.name }} já tem um registro de batismo no Espírito Santo cadastrado!
            </div>

            <div class="row g-2">
                <div class="col-md-6">
                    <label for="name" class="form-label">Buscar membro</label>
                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('member_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
                <div class="col-md-6">
                    <label for="type" class="form-label">Evento relacionado</label>
                    <select id="type" v-model="form.event_id" class="form-select">
                        <option v-for="(item, index) in events" :key="index" :value="item.id">
                            {{ item.schedule.name }} - {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <template v-if="mounted && members?.length">
                <hr />

                <legend class="m-0">Membro relacionado</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="route('members.show', item.id)"
                                    target="_blank"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
                <hr class="my-0" />
            </template>

            <div class="col-md-12">
                <label for="current_church" class="form-label">Nome da igreja atual</label>
                <input
                    id="current_church"
                    v-model="form.current_church"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua São Jorge"
                />
                <small v-if="form.errors.current_church" class="text-danger mt-3">
                    {{ form.errors.current_church }}
                </small>
            </div>

            <div class="col-md-12">
                <label for="observations" class="form-label"> Observações </label>

                <textarea
                    id="observations"
                    v-model="form.observations"
                    type="text"
                    class="form-control"
                    name="observations"
                    placeholder="Caso necessário, forneca alguma observação para esse registro"
                    rows="5"
                />

                <small v-if="form.errors.observations" class="text-danger mt-3">
                    {{ form.errors.observations }}
                </small>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os conselheiros</legend>

            <div class="row">
                <div
                    v-for="(item, index) in counselors"
                    :key="index"
                    class="col-md-2 col-6 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.counselors.includes(item.id),
                            }"
                            @click="toggleCounselorSelection(item.id)"
                        />
                        <span
                            v-show="form.counselors.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>
