<script setup>
import { onMounted } from 'vue';
import { Head, Link } from '@inertiajs/vue3';

import AOS from 'aos';
import 'aos/dist/aos.css';

const props = defineProps({
    churches: Object,
});

onMounted(() => {
    AOS.init({
        once: true,
    });
});
</script>

<template>
    <Head title="Selecionar igreja" />
    <div class="bg-ligth d-flex flex-column min-vh-100">
        <div class="flex-grow-1">
            <nav class="navbar bg-white border-0">
                <div class="container d-flex justify-content-center">
                    <a class="navbar-brand" href="#">
                        <img
                            src="/img/verbo_vida_logo_preto.png"
                            style="width: 80px"
                            class="logo"
                            alt="Verboshop Logo"
                        />
                    </a>
                </div>
            </nav>

            <div class="d-flex justify-content-center" id="choose-church">
                <div class="container mt-sm-5 mt-3">
                    <div class="text-center mt-sm-3">
                        <h3 class="font-bold">Qual igreja deseja acessar?</h3>
                    </div>
                    <div
                        class="row d-flex justify-content-center mt-5"
                        data-aos="zoom-in"
                        data-aos-duration="1500"
                    >
                        <div
                            class="col-12 col-sm-6 col-lg-4 col-xl-3 mb-3 text-center"
                            v-for="(item, index) in churches"
                            :key="index"
                        >
                            <Link
                                class="border-0 bg-transparent"
                                method="post"
                                :href="`/dashboard/selected-church/${item.id}`"
                            >
                                <img
                                    class="identification_photo shadow-sm border border-5"
                                    :class="{
                                        'border-warning': item.type == 'MAIN',
                                        'border-secondary': item.type == 'CONGREGATION',
                                    }"
                                    :src="`/storage/${item.logo}`"
                                    alt="Verboshop Logo"
                                />
                            </Link>

                            <h6 class="font-semibold mt-4">{{ item.name }}</h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="border d-block w-100 p-3 bg-body-tertiary">
            <div class="text-center font-bold text-body-tertiary">
                <font-awesome-icon class="me-2" :icon="['fas', 'copyright']" /> SGI - Verbo da Vida
            </div>
        </div>
    </div>
</template>
