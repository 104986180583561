<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import EventCardMember from '@/Pages/Dashboard/Pages/Events/Components/EventCardMember.vue';
import SimpleDataCard from '@/Pages/Dashboard/Pages/Members/Components/SimpleDataCard.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

const props = defineProps({
    newBirth: Object,
    event: Object,
    creatorRegistration: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();
</script>

<template>
    <Dashboard :page-title="newBirth.member.name">
        <div id="show-member">
            <Breadcrumb
                :current-page-name="newBirth.member.clean_name"
                :link-list="[
                    {
                        previous_page_url: 'new-births',
                        previous_page_name: 'Novos nascimentos',
                    },
                ]"
            />

            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">{{ newBirth.member.name }}</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="route('members.show', newBirth.member.id)"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Ver membro
                    </Link>
                    <Link
                        :href="route('members.new-births.edit', newBirth.id)"
                        class="btn btn-primary btn-add me-2"
                        v-if="event"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
            </div>

            <div class="col-12 mt-3 mt-sm-0">
                <img
                    class="identification_photo shadow-sm d-block mx-auto"
                    :src="newBirth.member.identification_photo"
                    alt=""
                />
            </div>

            <hr />

            <SimpleDataCard :data="newBirth">
                <template #badge>
                    <span class="me-2">
                        <Badge :name="newBirth.type" />
                    </span>
                </template>
            </SimpleDataCard>

            <EventCardMember :event="event" v-if="event" />

            <template v-if="newBirth.connection_group_meeting">
                <div class="px-5 py-4 mb-3 card-simple position-relative mt-5">
                    <div class="position-absolute top-0 start-50 translate-middle">
                        <img
                            class="rounded-circle"
                            width="50"
                            :src="`/storage/${newBirth.connection_group_meeting.group_connection_photo}`"
                            alt=""
                        />
                    </div>
                    <div class="font-semibold text-center fs-14 mt-3">
                        Essa conversão foi realizada durante um encontro do
                        <Link
                            :href="`/dashboard/group-connections/${newBirth.connection_group_meeting.group_connection_id}/meetings/${newBirth.connection_group_meeting.id}`"
                        >
                            {{ newBirth.connection_group_meeting.group_connection_name }}</Link
                        >
                        em {{ simplifyDate(newBirth.connection_group_meeting.meeting_date) }}
                    </div>
                </div>
            </template>

            <CreatorRecord
                v-if="creatorRegistration.name"
                :data="creatorRegistration"
                title="Conversão cadastrada por: "
            />
        </div>
    </Dashboard>
</template>
