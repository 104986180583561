<script setup>
import { router, Link } from '@inertiajs/vue3';
import Layout from '../Layout.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

const props = defineProps({
    discipleship: Object,
    members: Object,
});

const goMemberPage = (discipleshipMemberID) =>
    router.get(`/dashboard/discipleships/${props.discipleship.id}/members/${discipleshipMemberID}`);

const statusFrequency = (status) => {
    const style = {
        PENDING: 'Não informado',
        COMPLETED: 'Presencial',
        COMPLETED_REMOTELY: 'Remotamente',
        NOT_COMPLETED: 'Ausente',
    };
    return style[status];
};

const formatStatusIcon = (status) => {
    const style = {
        PENDING: 'text-body-tertiary',
        COMPLETED: 'text-success',
        NOT_COMPLETED: 'text-danger',
        COMPLETED_REMOTELY: 'text-primary',
    };
    return style[status] || 'text-success';
};
</script>

<template>
    <Layout :discipleship="discipleship">
        <div class="px-4 py-3 search-form-card">
            <PageHeader
                title="Inscritos"
                :button-link="route('discipleship-members.create')"
                button-text="Novo"
            />

            <hr />

            <div class="search-form-card p-0 shadow-none border-0">
                <Table
                    table-id="table-discipleship-members"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'member',
                            label: 'Nome do inscrito',
                            thClass: 'text-start',
                            tdClass: 'text-start',
                        },
                        {
                            key: 'phone',
                            label: 'Telefone de contato',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'frequencies',
                            label: 'Frequências',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="members?.data"
                    :rowLink="
                        (item) => `/dashboard/discipleships/${discipleship.id}/members/${item.id}`
                    "
                    :pagination="{
                        reference: 'members',
                    }"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-member="{ item }">
                        <div class="d-flex justify-content-start align-items-center">
                            <LazyImage
                                width="30px"
                                :src="item.member.identification_photo"
                                alt="Foto de {{ item.member.clean_name }}"
                            />
                            <span class="ms-3">{{ item.member.clean_name }}</span>
                        </div>
                    </template>

                    <template #cell-phone="{ item }">
                        <template
                            v-if="item.member.phone_number && item.member.phone_number.phone_number"
                        >
                            <a
                                :href="`https://wa.me/55${item.member.phone_number.phone_number}`"
                                target="_blank"
                                class="btn btn-light btn-add"
                                :class="{
                                    disabled: !item.member.phone_number.same_whatsapp_number,
                                }"
                            >
                                <font-awesome-icon :icon="['fab', 'whatsapp']" />
                                <span class="ms-3">{{
                                    item.member.phone_number.phone_number
                                }}</span>
                            </a>
                        </template>
                        <template v-else>
                            <span>Não informado</span>
                        </template>
                    </template>

                    <template #cell-frequencies="{ item }">
                        <div v-if="item.frequencies && item.frequencies.length">
                            <font-awesome-icon
                                v-for="(freq, idx) in item.frequencies"
                                :key="idx"
                                :class="`fs-4 me-2 ${formatStatusIcon(freq.status)}`"
                                :icon="['fas', 'circle-check']"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :data-bs-title="statusFrequency(freq.status)"
                            />
                        </div>
                        <div v-else>Pendente</div>
                    </template>
                </Table>
            </div>
        </div>
    </Layout>
</template>
