<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const form = useForm({
    name: null,
});

const store = () => form.post('/dashboard/discipleships');
</script>

<template>
    <Dashboard page-title="Nova turma">
        <Breadcrumb
            current-page-name="Nova turma"
            :link-list="[
                {
                    previous_page_url: 'discipleships',
                    previous_page_name: 'Turmas',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Nova turma</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="name" class="form-label"> Nova do turma </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Turma Maio 2025"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
