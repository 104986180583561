<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Pages/Dashboard/Composables/useImageResizer.js';

const props = defineProps({
    artists: Object,
    themes: Object,
});

const form = useForm({
    name: null,
    cover_photo: '/img/avatars/male.png',
    description: null,
    youtube_link: null,
    link_lyrics: null,
    release_year: null,
    official_letter: null,
    ministry_letter: null,
    changed_photo: false,
    artist: null,
    duration: null,
    themes: [],
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.cover_photo);

const inputRef = ref(null);

const store = () => {
    form.cover_photo = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('musics.store'));
};

const toggleArtistSelection = (artistId) => {
    form.artist = form.artist === artistId ? null : artistId;
};

const toggleThemeSelection = (themeId) => {
    const index = form.themes.indexOf(themeId);
    if (index === -1) {
        form.themes.push(themeId);
    } else {
        form.themes.splice(index, 1);
    }
};
</script>

<template>
    <Dashboard page-title="Nova música">
        <Breadcrumb
            current-page-name="Nova música"
            :link-list="[
                {
                    previous_page_url: 'musics',
                    previous_page_name: 'Músicas',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Nova música</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing || !form.artist"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_photo">
                    {{ form.errors.changed_photo }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome da música </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Bondade de Deus"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="release_year" class="form-label"> Lançamento </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        v-maska
                        id="release_year"
                        v-model="form.release_year"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        data-maska="####"
                        placeholder="2024"
                    />
                    <small v-if="form.errors.release_year" class="text-danger mt-3">
                        {{ form.errors.release_year }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="youtube_link" class="form-label"> Link do Youtube </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="youtube_link"
                        v-model="form.youtube_link"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="https://www.youtube.com/@verbodavidapauloafonso"
                    />
                    <small v-if="form.errors.youtube_link" class="text-danger mt-3">
                        {{ form.errors.youtube_link }}
                    </small>
                </div>

                <div class="col-md-7">
                    <label for="link_lyrics" class="form-label"> Link da Letra </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="link_lyrics"
                        v-model="form.link_lyrics"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="https://www.letras.mus.br/verbodavida"
                    />
                    <small v-if="form.errors.link_lyrics" class="text-danger mt-3">
                        {{ form.errors.link_lyrics }}
                    </small>
                </div>

                <div class="col-md-5">
                    <label for="duration" class="form-label"> Duração da música </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="duration"
                        v-maska
                        v-model="form.duration"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        data-maska="##:##"
                        placeholder="00:00"
                    />
                    <small v-if="form.errors.duration" class="text-danger mt-3">
                        {{ form.errors.duration }}
                    </small>
                </div>

                <hr class="mb-0" />

                <div class="col-md-12">
                    <label for="official_letter" class="form-label"> Letra Oficial </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="official_letter"
                        v-model="form.official_letter"
                        type="text"
                        class="form-control"
                        name="official_letter"
                        placeholder="Forneça a letra oficial dessa música"
                        rows="20"
                    />

                    <small v-if="form.errors.official_letter" class="text-danger mt-3">
                        {{ form.errors.official_letter }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="ministry_letter" class="form-label"> Versão do Ministério </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="ministry_letter"
                        v-model="form.ministry_letter"
                        type="text"
                        class="form-control"
                        name="ministry_letter"
                        placeholder="Forneça a versão do ministério dessa música"
                        rows="20"
                    />

                    <small v-if="form.errors.ministry_letter" class="text-danger mt-3">
                        {{ form.errors.ministry_letter }}
                    </small>
                </div>

                <legend class="mb-4">Selecione os temas relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in themes"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.photo_identification}`"
                                :class="{
                                    selected: form.themes.includes(item.id),
                                }"
                                @click="toggleThemeSelection(item.id)"
                            />
                            <span
                                v-show="form.themes.includes(item.id)"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>

                <hr class="mb-0" />

                <legend class="mb-4">Selecione os artistas relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in artists"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.profile_photo}`"
                                :class="{
                                    selected: form.artist == item.id,
                                }"
                                @click="toggleArtistSelection(item.id)"
                            />
                            <span
                                v-show="form.artist == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>

            <PhotoEditModal
                v-if="form.cover_photo"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
