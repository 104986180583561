<script setup>
import { reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    musics: Object,
});

const form = useForm({
    name: '',
    description: '',
    is_public: false,
    musics: [],
});

const copiedMusics = reactive([]);

const selectedMusicsIds = () => form.musics.map((music) => music.id);

const store = () => {
    form.transform((data) => ({
        ...data,
        musics: selectedMusicsIds(),
    })).post(route('playlists.store'));
};

const seacher = reactive({ music_name: '' });
let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(search, 1000);
};

const search = () => {
    router.visit(route('playlists.create'), {
        method: 'get',
        data: { seacher },
        only: ['musics'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: (page) => {
            const currentSelectedMusics = form.musics.map((m) => m.id);
            if (page.props.musics) {
                const updatedMusics = page.props.musics.map((music) => ({
                    ...music,
                    selected: currentSelectedMusics.includes(music.id),
                }));
                copiedMusics.splice(0, copiedMusics.length, ...updatedMusics);
            }
        },
    });
};

const toggleMusicSelection = (music) => {
    const index = form.musics.findIndex((m) => m.id === music.id);
    if (index === -1) {
        form.musics.push(music);
    } else {
        form.musics.splice(index, 1);
    }
    music.selected = !music.selected;
};

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(route('playlists.create'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page-title="Nova Playlist">
        <Breadcrumb
            current-page-name="Nova Playlist"
            :link-list="[{ previous_page_url: 'playlists', previous_page_name: 'Playlists' }]"
        />

        <h4 class="my-3">Nova Playlist</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.name"
        >
            <div class="col-6">
                <label for="name" class="form-label">Nome da Playlist</label>
                <input
                    id="name"
                    type="text"
                    class="form-control"
                    v-model="form.name"
                    placeholder="Digite o nome da playlist"
                />
                <small v-if="form.errors.name" class="text-danger mt-1">{{
                    form.errors.name
                }}</small>
            </div>

            <div class="col-6 form-check d-flex align-items-end justify-content-center">
                <input
                    id="is_public"
                    type="checkbox"
                    class="form-check-input"
                    v-model="form.is_public"
                />
                <label for="is_public" class="form-check-label ms-2">Tornar pública</label>
            </div>

            <div class="col-12">
                <label for="description" class="form-label">Descrição</label>
                <textarea
                    id="description"
                    v-model="form.description"
                    class="form-control"
                    rows="3"
                    placeholder="Descreva a playlist"
                ></textarea>
                <small v-if="form.errors.description" class="text-danger mt-1">{{
                    form.errors.description
                }}</small>
            </div>

            <hr class="mb-0" />

            <div class="mb-3">
                <label for="music_search" class="form-label">Adicionar música</label>
                <input
                    id="music_search"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Buscar música"
                    @input="compositionSeacher('music_name', $event)"
                />
                <small v-if="form.errors.music_search" class="text-danger mt-1">{{
                    form.errors.music_search
                }}</small>
            </div>

            <ul class="list-group member-found col-d-12 p-2 mb-3">
                <li
                    v-for="(music, index) in musics"
                    :key="index"
                    class="list-group-item p-2 d-flex align-items-center mb-3"
                    :class="{ checked: music.selected }"
                >
                    <input
                        :id="'checkBoxVisitor' + music.id"
                        class="form-check-input me-1 mb-0"
                        type="checkbox"
                        :value="music.id"
                        :checked="music.selected"
                        @click="toggleMusicSelection(music)"
                    />
                    <label
                        class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                        :for="'checkBoxVisitor' + music.id"
                        style="cursor: pointer"
                    >
                        <div class="d-flex justify-content-between align-items-center">
                            <span>{{ music.name }}</span>
                            <a
                                :href="`/dashboard/musics/${music.id}`"
                                class="btn btn-primary btn-add z-2 me-3"
                                target="_blank"
                            >
                                Ver música
                            </a>
                        </div>
                    </label>
                </li>
            </ul>

            <template v-if="form.musics.length">
                <legend class="mb-5">Músicas adicionadas</legend>
                <div class="row">
                    <div
                        v-for="(music, index) in form.musics"
                        :key="index"
                        class="col-md-3 text-center mb-3"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${music.cover_photo}`"
                                alt="Capa da música"
                            />
                            <span
                                class="position-absolute top-0 start-100 translate-middle badge rounded-circle bg-danger p-2"
                                @click="toggleMusicSelection(music)"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-xmark']" />
                            </span>
                            <span class="mt-2 d-block">{{ music.name }}</span>
                        </label>
                    </div>
                </div>
            </template>
        </Form>
    </Dashboard>
</template>
