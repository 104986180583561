<script setup>
import { ref, watch } from 'vue';

const props = defineProps({
    src: String,
    alt: String,
    width: { type: String, default: '100px' },
    showBorder: { type: Boolean, default: false },
    inlineStyle: { type: Object, default: () => ({}) },
});

const isLoaded = ref(false);
const hasError = ref(false);

watch(
    () => props.src,
    (newSrc) => {
        isLoaded.value = false;
        hasError.value = false;
    }
);
</script>

<template>
    <div
        :class="[
            'rounded-circle d-flex align-items-center justify-content-center',
            { 'bg-secondary': !isLoaded || hasError },
        ]"
        :style="{ width: width, height: width }"
    >
        <img
            v-if="src && !hasError"
            :src="src"
            :alt="alt"
            @load="isLoaded = true"
            @error="hasError = true"
            class="rounded-circle profile-image"
            :class="{ 'border border-3 border-info': showBorder }"
            :style="{
                width: width,
                height: width,
                display: isLoaded ? 'block' : 'none',
                ...inlineStyle,
            }"
        />
    </div>
</template>
