<script setup>
import { defineProps, watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import useStatus from '@/Pages/Dashboard/Composables/useStatus.vue';
import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const { formatStatusIcon, statusResponsePastor, statusResponseLeader } = useStatus();

const { searchParams, isLoading, updateLoadingState, executeSearch } = useSearch(
    {
        department_id: 0,
        status: 0,
    },
    'departments.entry-requests.index'
);

const props = defineProps({
    requests: Object,
    departments: Object,
});

watch(
    () => props.requests,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Solicitações Departamentos">
        <Breadcrumb current-page-name="Solicitações Departamentos" />

        <PageHeader
            title="Solicitações"
            :button-link="route('departments.entry-requests.create')"
            button-text="Nova"
        />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-7">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar departamento</label
                        >
                        <select
                            id="department_id"
                            v-model="searchParams.department_id"
                            class="form-select"
                            required
                            @change="executeSearch()"
                        >
                            <option value="0">Todos os departamentos</option>
                            <option
                                v-for="(item, index) in departments"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-5">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Status da solicitação</label
                        >
                        <select
                            v-model="searchParams.status"
                            class="form-select"
                            required
                            @change="executeSearch()"
                        >
                            <option value="0">Todos os status</option>
                            <option value="pending_by_pastor">Aguardando aprovação pastor</option>
                            <option value="pending_by_leader">Aguardando aprovação líder</option>
                            <option value="failed">Reprovadas</option>
                        </select>
                    </div>
                </div>
            </div>

            <hr class="mb-0" />

            <Table
                table-id="table-requests"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'department', label: 'Departamento' },
                    { key: 'member', label: 'Membro solicitante' },
                    {
                        key: 'status',
                        label: 'Status da solicitação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="requests?.data"
                :pagination="{
                    data: requests?.meta,
                    reference: 'requests',
                }"
                :rowLink="(item) => route('departments.entry-requests.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-department="{ item }">
                    {{ item.department.name }}
                </template>

                <template #cell-member="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="item.member_photo" />
                        <span class="ms-3">{{ item.member_name }}</span>
                    </div>
                </template>

                <template #cell-status="{ item }">
                    <font-awesome-icon
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-title="statusResponsePastor(item.status_response_pastor)"
                        :class="`fs-4 ${formatStatusIcon(item.status_response_pastor)}`"
                        :icon="['fas', 'circle-check']"
                    />
                    <font-awesome-icon
                        v-if="item.status_response_pastor != 'NOT_APPROVED'"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        :data-bs-title="statusResponseLeader(item.status_response_leader)"
                        :class="`fs-4 ${formatStatusIcon(item.status_response_leader)} ms-3`"
                        :icon="['fas', 'circle-check']"
                    />
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
