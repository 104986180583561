<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    playlists: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
    },
    'playlists.index'
);

watch(
    () => props.playlists,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Playlists">
        <Breadcrumb current-page-name="Playlists" />
        <PageHeader title="Playlists" :button-link="route('playlists.create')" button-text="Nova" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center g-3">
                <div class="col-md-12">
                    <label for="playlistName" class="form-label">Nome da Playlist</label>
                    <input
                        id="playlistName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Ex: Minha Playlist"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-playlists"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'name', label: 'Nome' },
                    {
                        key: 'user',
                        label: 'Responsável',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'is_public',
                        label: 'Pública',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_musics',
                        label: 'Músicas',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'created_at',
                        label: 'Data criação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="playlists?.data"
                :pagination="{
                    data: playlists?.meta,
                    reference: 'playlists',
                }"
                :rowLink="(item) => route('playlists.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <span>{{ item.name }}</span>
                </template>

                <template #cell-user="{ item }">
                    <div class="d-flex justify-content-center">
                        <Link v-if="item.user" :href="route('users.show', item.user.id)">
                            <LazyImage width="35px" :src="`${item.user.profile_image_url}`" />
                        </Link>
                        <span v-else>-</span>
                    </div>
                </template>

                <template #cell-total_musics="{ item }">
                    {{ item.total_musics }}
                </template>

                <template #cell-is_public="{ item }">
                    <Badge :name="item.is_public ? 'YES' : 'NOT'" />
                </template>

                <template #cell-created_at="{ item }">
                    {{ item.created_at }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
