<script setup>
import { ref } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

import Insignia from './Components/Insignia.vue';
import Timeline from './Components/Timeline.vue';

import FamilyOrganizationCad from '../Families/Components/FamilyOrganizationCard.vue';
import DepartmentCard from '../Departments/Components/DepartmentCard.vue';
import GroupConnectionCard from '../GroupConnections/Components/GroupConnectionCard.vue';

const props = defineProps({
    member: Object,
    timeline: Object,
    family: Object,
    departments: Object,
    groups: Object,
    creatorRegistration: Object,
});

const showCpf = ref(false);
const showRg = ref(false);

const toggleShowCpf = () => (showCpf.value = !showCpf.value);
const toggleShowRg = () => (showRg.value = !showRg.value);

const getValueOrDefault = (value, defaultValue = 'Não informado') => {
    return value ? value : defaultValue;
};
</script>

<template>
    <Dashboard :page-title="member.clean_name">
        <div id="show-member">
            <Breadcrumb
                :current-page-name="member.clean_name"
                :link-list="[
                    {
                        previous_page_url: 'members',
                        previous_page_name: 'Membros',
                    },
                ]"
            />

            <div class="cp-3 row">
                <div class="d-flex justify-content-end">
                    <Link
                        :href="`/dashboard/members/edit/${member.id}`"
                        class="btn btn-primary btn-add mt-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
                <div class="col-12 mt-3 mt-sm-0">
                    <img
                        class="identification_photo shadow-sm d-block mx-auto"
                        :src="member.identification_photo"
                        alt=""
                    />
                </div>

                <div class="col-12">
                    <div class="row">
                        <h4 class="my-4 text-center">
                            {{ member.name }}
                        </h4>
                    </div>
                    <Insignia :member="member" :departments="departments" :groups="groups" />
                </div>
            </div>

            <div class="p-3 row mt-2">
                <ul class="nav nav-tabs d-flex justify-content-center" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link active"
                            id="home-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#personal-data"
                            type="button"
                            role="tab"
                            aria-controls="personal-data"
                            aria-selected="true"
                        >
                            Dados pessoais
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="contact-tab"
                            :disabled="timeline.length == 0"
                            data-bs-toggle="tab"
                            data-bs-target="#contact-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="contact-tab-pane"
                            aria-selected="false"
                        >
                            Linha do tempo
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="family-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#family-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="family-tab-pane"
                            aria-selected="false"
                        >
                            Família
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="department-tab"
                            data-bs-toggle="tab"
                            data-bs-target="#department-tab-pane"
                            type="button"
                            :disabled="!departments.length"
                            role="tab"
                            aria-controls="department-tab-pane"
                            aria-selected="false"
                        >
                            Departamentos
                        </button>
                    </li>

                    <li class="nav-item" role="presentation">
                        <button
                            class="nav-link"
                            id="group-tab"
                            data-bs-toggle="tab"
                            :disabled="!groups.length"
                            data-bs-target="#group-tab-pane"
                            type="button"
                            role="tab"
                            aria-controls="group-tab-pane"
                            aria-selected="false"
                        >
                            GCs
                        </button>
                    </li>
                </ul>
                <div class="tab-content mt-3" id="myTabContent">
                    <div
                        class="tab-pane fade show active form-card p-4 mx-0"
                        id="personal-data"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                    >
                        <form action="">
                            <fieldset class="mb-3">
                                <div class="row g-3">
                                    <div class="col-sm-7">
                                        <label for="name" class="form-label">
                                            Nome do membro
                                        </label>

                                        <input
                                            disabled
                                            id="name"
                                            :value="member.name"
                                            type="text"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-5">
                                        <label for="email" class="form-label"> Email </label>

                                        <input
                                            disabled
                                            id="email"
                                            :value="getValueOrDefault(member.email)"
                                            type="text"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>

                                    <div class="col-sm-3">
                                        <label for="cpf" class="form-label"> CPF </label>

                                        <div class="input-group input-group-sm">
                                            <input
                                                :type="showCpf ? 'text' : 'password'"
                                                class="form-control"
                                                :value="getValueOrDefault(member.cpf)"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <button
                                                class="btn btn-outline-secondary"
                                                type="button"
                                                id="button-addon2"
                                                @click="toggleShowCpf()"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', showCpf ? 'eye-slash' : 'eye']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="rg" class="form-label"> RG </label>

                                        <div class="input-group input-group-sm">
                                            <input
                                                :type="showRg ? 'text' : 'password'"
                                                class="form-control"
                                                :value="getValueOrDefault(member.rg)"
                                                aria-label="Recipient's username"
                                                aria-describedby="basic-addon2"
                                            />
                                            <button
                                                class="btn btn-outline-secondary"
                                                type="button"
                                                id="button-addon2"
                                                @click="toggleShowRg()"
                                            >
                                                <font-awesome-icon
                                                    :icon="['fas', showRg ? 'eye-slash' : 'eye']"
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="ssp" class="form-label"> SSP </label>

                                        <input
                                            disabled
                                            id="ssp"
                                            :value="getValueOrDefault(member.ssp)"
                                            type="text"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-3">
                                        <label for="member_date" class="form-label">
                                            Membro deste
                                        </label>

                                        <input
                                            disabled
                                            id="member_date"
                                            :value="getValueOrDefault(member.member_date)"
                                            type="date"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="naturalness" class="form-label">
                                            Naturalidade
                                        </label>

                                        <input
                                            disabled
                                            id="naturalness"
                                            :value="getValueOrDefault(member.naturalness)"
                                            type="text"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="nationality" class="form-label">
                                            Nacionalidade
                                        </label>

                                        <input
                                            disabled
                                            id="nationality"
                                            :value="getValueOrDefault(member.nationality)"
                                            type="text"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="birth_date" class="form-label">
                                            Data de nascimento
                                        </label>

                                        <input
                                            disabled
                                            id="birth_date"
                                            :value="member.birth_date"
                                            type="date"
                                            autocomplete="off"
                                            class="form-control"
                                        />
                                    </div>

                                    <div class="col-sm-4">
                                        <label for="sex" class="form-label d-block"> Sexo </label>
                                        <Badge :name="member.sex" />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="sex" class="form-label d-block"> Crente </label>
                                        <Badge :name="member.believer ? 'YES' : 'NOT'" />
                                    </div>
                                    <div class="col-sm-4">
                                        <label for="sex" class="form-label d-block">
                                            Situação do membro
                                        </label>
                                        <Badge :name="member.type" />
                                    </div>

                                    <div class="col-lg-12 mt-0">
                                        <hr />

                                        <legend>Trabalho e escolaridade</legend>

                                        <div class="row">
                                            <div class="col-sm-12 mb-3">
                                                <label for="profession" class="form-label d-block">
                                                    Profissão
                                                </label>

                                                <input
                                                    id="profession"
                                                    :value="getValueOrDefault(member.profession)"
                                                    type="text"
                                                    autocomplete="off"
                                                    class="form-control"
                                                />
                                            </div>
                                            <div class="col-sm-4">
                                                <label
                                                    for="education_degree"
                                                    class="form-label d-block"
                                                >
                                                    Grau de instrução
                                                </label>
                                                <Badge
                                                    :name="
                                                        getValueOrDefault(member.education_degree)
                                                    "
                                                />
                                            </div>

                                            <div class="col-sm-4 mt-2 mt-sm-0">
                                                <label for="training" class="form-label d-block">
                                                    Formação
                                                </label>

                                                <input
                                                    id="training"
                                                    :value="getValueOrDefault(member.training)"
                                                    type="text"
                                                    autocomplete="off"
                                                    class="form-control"
                                                />
                                            </div>

                                            <div class="col-sm-4 mt-2 mt-sm-0">
                                                <label
                                                    for="businessperson"
                                                    class="form-label d-block"
                                                >
                                                    Empresário?
                                                </label>
                                                <Badge
                                                    :name="member.businessperson ? 'YES' : 'NOT'"
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-12 mt-0">
                                        <hr />

                                        <legend>Tempo como cristão e na igreja</legend>

                                        <div class="row">
                                            <div class="col-sm-6">
                                                <label
                                                    for="education_degree"
                                                    class="form-label d-block"
                                                >
                                                    Tempo na igreja
                                                </label>
                                                <Badge
                                                    :name="
                                                        getValueOrDefault(
                                                            member.time_in_church_formatted
                                                        )
                                                    "
                                                />
                                            </div>

                                            <div class="col-sm-6 mt-2 mt-sm-0">
                                                <label for="training" class="form-label d-block">
                                                    Tempo como cristão
                                                </label>

                                                <Badge
                                                    :name="
                                                        getValueOrDefault(
                                                            member.time_as_christian_formatted
                                                        )
                                                    "
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-0" v-if="member.social_networks.length">
                                        <hr />
                                        <div class="row">
                                            <legend>Redes sociais</legend>

                                            <div
                                                class="col-sm-12"
                                                v-for="(item, index) in member.social_networks"
                                                :key="index"
                                            >
                                                <div class="input-group mb-2">
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon1"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="[
                                                                'fab',
                                                                item.social_network.toLowerCase(),
                                                            ]"
                                                        />
                                                    </span>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        :value="item.nick"
                                                        class="form-control"
                                                    />
                                                    <a
                                                        :href="item.full_url"
                                                        target="_blank"
                                                        class="btn btn-primary btn-add"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="[
                                                                'fas',
                                                                'arrow-up-right-from-square',
                                                            ]"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="col-12 mt-0"
                                        v-if="member.cell_phone_numbers.length"
                                    >
                                        <hr />
                                        <div class="row">
                                            <legend>Telefones de contato</legend>

                                            <div
                                                class="col-sm-6"
                                                v-for="(item, index) in member.cell_phone_numbers"
                                                :key="index"
                                            >
                                                <div class="input-group mb-2">
                                                    <span
                                                        class="input-group-text"
                                                        id="basic-addon1"
                                                    >
                                                        <img
                                                            width="25"
                                                            :src="`/img/operators/${item.operador.toLowerCase()}.png`"
                                                            alt=""
                                                        />
                                                    </span>
                                                    <input
                                                        disabled
                                                        type="text"
                                                        :value="item.phone_number"
                                                        class="form-control"
                                                    />
                                                    <a
                                                        :href="`https://wa.me/55${item.phone_number}`"
                                                        target="_blank"
                                                        class="btn btn-success btn-add"
                                                        :class="{
                                                            disabled: !item.same_whatsapp_number,
                                                        }"
                                                    >
                                                        <font-awesome-icon
                                                            :icon="['fab', 'whatsapp']"
                                                        />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-12 mt-0" v-if="member.term_commitment">
                                        <hr />
                                        <div
                                            class="d-flex justify-content-between align-items-center"
                                        >
                                            <span class="d-inline fs-5 font-bold"
                                                >Termo de consentimento</span
                                            >
                                            <a
                                                class="btn btn-danger btn-add"
                                                :href="`/storage/${member.term_commitment}`"
                                                download="termo-de-compromisso.pdf"
                                            >
                                                <font-awesome-icon
                                                    class="me-2"
                                                    :icon="['fas', 'file-pdf']"
                                                />
                                                Baixar
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </fieldset>
                        </form>
                    </div>

                    <div
                        class="tab-pane fade"
                        id="contact-tab-pane"
                        role="tabpanel"
                        aria-labelledby="contact-tab"
                        tabindex="0"
                    >
                        <Timeline :data="timeline" />
                    </div>
                    <div
                        class="tab-pane fade"
                        id="family-tab-pane"
                        role="tabpanel"
                        aria-labelledby="family-tab"
                        tabindex="0"
                    >
                        <div v-if="family">
                            <FamilyOrganizationCad :family="family" :memberSection="member.id" />
                        </div>
                        <div v-else>
                            <div class="row mt-3 mb-3">
                                <div class="col d-flex justify-content-end">
                                    <div>
                                        <Link
                                            :href="`/dashboard/families/create`"
                                            class="btn btn-primary btn-add"
                                        >
                                            <font-awesome-icon
                                                class="me-2"
                                                :icon="['fas', 'plus']"
                                            />
                                            Adicionar família
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div class="p-3 bg-info bg-opacity-10 border border-info my-3">
                                Esse membro ainda não estar vinculado a nenhuma família.
                            </div>
                        </div>
                    </div>
                    <div
                        class="tab-pane fade form-card p-4 mx-0"
                        id="department-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                    >
                        <div class="row">
                            <DepartmentCard :data="departments" />
                        </div>
                    </div>
                    <div
                        class="tab-pane fade form-card p-4 mx-0"
                        id="group-tab-pane"
                        role="tabpanel"
                        aria-labelledby="home-tab"
                        tabindex="0"
                    >
                        <div class="row">
                            <GroupConnectionCard :data="groups" />
                        </div>
                    </div>
                </div>
                <CreatorRecord
                    v-if="creatorRegistration.name"
                    :data="creatorRegistration"
                    title="Membro cadastrado por: "
                />
            </div>
        </div>
    </Dashboard>
</template>
