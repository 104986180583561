<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    childs: Object,
});

const { searchParams, isLoading, updateLoadingState, executeSearch } = useSearch(
    {
        child_name: '',
    },
    'children-consecration.index'
);

watch(
    () => props.childs,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Consagrações">
        <Breadcrumb current-page-name="Consagrações" />

        <PageHeader
            title="Consagrações"
            :button-link="route('children-consecration.create')"
            button-text="Nova"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label for="childName" class="form-label">Nome da criança</label>
                    <input
                        id="childName"
                        v-model="searchParams.child_name"
                        type="text"
                        class="form-control"
                        placeholder="Camila Gomes"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <hr />

            <Table
                table-id="table-children-consecration"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'child_name', label: 'Nome da criança' },
                    {
                        key: 'status',
                        label: 'Status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'age',
                        label: 'Idade criança',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'scheduled_date',
                        label: 'Data agendada',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="childs?.data"
                :pagination="{
                    data: childs?.meta,
                    reference: 'childs',
                }"
                :rowLink="(item) => route('children-consecration.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-status="{ item }">
                    <Badge :name="item.status" />
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
