<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

import usePreviewImage from '@/Pages/Dashboard/Composables/usePreviewImage.vue';

const { previewImage, imageUrl } = usePreviewImage();

const props = defineProps({
    rooms: Object,
});

const form = useForm({
    name: null,
    room_id: props.rooms[0].id || null,
    description: null,
    banner: null,
});

const store = () => form.post('/dashboard/child-department-classes');
</script>

<template>
    <Dashboard page-title="Nova turma">
        <Breadcrumb
            current-page-name="Nova turma"
            :link-list="[
                {
                    previous_page_url: 'child-department-classes',
                    previous_page_name: 'Turmas',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Nova turma</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome do turma </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Super Igreja"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="room" class="form-label">Sala</label>
                    <select
                        id="room"
                        v-model="form.room_id"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in rooms" :key="index" :value="item.id">
                            {{ item.identifier }}
                        </option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para essa turma."
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="formFile" class="form-label"
                        >Banner da turma - Dimensões recomendadas: 772 x 194</label
                    >
                    <input
                        id="formFile"
                        class="form-control"
                        type="file"
                        @input="form.banner = $event.target.files[0]"
                        @change="previewImage"
                    />
                    <small class="mt-3 informative-text">
                        O tamanho máximo que a imagem deve ter é de 2mb
                    </small>
                </div>

                <div class="col-md-11 mx-auto">
                    <img
                        v-if="imageUrl"
                        width="540px"
                        height="136px"
                        class="d-block mx-auto border border-5 border-light shadow"
                        :src="imageUrl"
                    />
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
