<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    members: Object,
    schedules: Object,
    typesNewBirths: Object,
    counselors: Object,
});

const {
    searchParams,
    executeSearch,
    compositionSeacher,
    updateLoadingState,
    buildDownloadUrl,
    isLoading,
} = useSearch(
    {
        name: '',
        start_date: '',
        end_date: '',
        type: 0,
        counselor_id: 0,
        schedule_id: 0,
    },
    'members.new-births.index'
);

watch(
    () => props.members,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Novos nascimento">
        <Breadcrumb current-page-name="Novos nascimento" />

        <PageHeader
            title="Novo nascimento"
            :button-link="route('members.new-births.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar dados</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('new-births', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('new-births', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row g-3">
                <div class="col-md-6">
                    <label class="form-label" for="name">Nome do membro</label>
                    <input
                        id="name"
                        type="text"
                        class="form-control"
                        placeholder="Aline Andrade"
                        @input="(event) => compositionSeacher('name', event, executeSearch)"
                    />
                </div>

                <div class="col-6 col-sm-3">
                    <label for="startDate" class="form-label">Data de início</label>
                    <input
                        id="startDate"
                        v-model="searchParams.start_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>

                <div class="col-6 col-sm-3">
                    <label for="endDate" class="form-label">Data de fim</label>
                    <input
                        id="endDate"
                        v-model="searchParams.end_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>
                <div class="col-md-5 col-sm-6">
                    <label for="schedule_id" class="form-label">Selecionar programação</label>
                    <select
                        id="schedule_id"
                        v-model="searchParams.schedule_id"
                        class="form-select"
                        required
                        @change="executeSearch"
                    >
                        <option value="0">Todas as programações</option>
                        <option
                            v-for="schedule in props.schedules"
                            :key="schedule.id"
                            :value="schedule.id"
                        >
                            {{ schedule.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-3 col-sm-6">
                    <label for="type" class="form-label">Tipo</label>
                    <select
                        id="type"
                        v-model="searchParams.type"
                        class="form-select"
                        @change="executeSearch"
                    >
                        <option value="0">Todos os tipos</option>
                        <option
                            v-for="type in props.typesNewBirths"
                            :key="type.value"
                            :value="type.value"
                        >
                            {{ type.name }}
                        </option>
                    </select>
                </div>
                <div class="col-md-4 col-sm-6">
                    <label for="counselor_id" class="form-label">Conselheiro</label>
                    <select
                        id="counselor_id"
                        v-model="searchParams.counselor_id"
                        class="form-select"
                        @change="executeSearch"
                    >
                        <option value="0">Todos os conselheiros</option>
                        <option
                            v-for="counselor in props.counselors"
                            :key="counselor.id"
                            :value="counselor.id"
                        >
                            {{ counselor.name }}
                        </option>
                    </select>
                </div>
            </div>

            <Table
                table-id="table-new-births"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    { key: 'name', label: 'Nome', thClass: 'text-start', tdClass: 'text-start' },
                    {
                        key: 'schedule',
                        label: 'Programação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'type', label: 'Tipo', thClass: 'text-center', tdClass: 'text-center' },
                    {
                        key: 'counselors',
                        label: 'Conselheiros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'new_birth_date',
                        label: 'Data',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="members?.data"
                :pagination="{ data: members?.meta, reference: 'members' }"
                :rowLink="(item) => route('members.new-births.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-schedule="{ item }">
                    <span v-if="item.schedule_name">{{ item.schedule_name }}</span>
                    <span v-else>
                        <font-awesome-icon
                            :icon="['fas', 'minus']"
                            data-bs-toggle="tooltip"
                            title="Dado do SGI antigo"
                        />
                    </span>
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="item.member.identification_photo" />
                        <span class="ms-3">{{ item.member.clean_name }}</span>
                    </div>
                </template>

                <template #cell-type="{ item }">
                    <Badge :name="item.type" />
                </template>

                <template #cell-counselors="{ item }">
                    <template v-if="item.counselors.length">
                        <Link
                            v-for="(counselor, index) in item.counselors"
                            :key="index"
                            :href="route('members.counselors.show', counselor.id)"
                        >
                            <img
                                height="30"
                                width="30"
                                class="rounded-circle border border-1 border-light"
                                :src="`/storage/${counselor.identification_photo}`"
                                :alt="counselor.name"
                                data-bs-toggle="tooltip"
                                title="{{ counselor.name }}"
                                style="margin-left: -8px"
                            />
                        </Link>
                    </template>
                    <template v-else>
                        <font-awesome-icon
                            :icon="['fas', 'minus']"
                            data-bs-toggle="tooltip"
                            title="Nenhum conselheiro definido"
                        />
                    </template>
                </template>

                <template #cell-new_birth_date="{ item }">
                    {{ item.new_birth_date }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
