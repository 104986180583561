<script setup>
import { ref, reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

const props = defineProps({
    members: Object,
});

const form = useForm({
    name: null,
    image_identification: '/img/avatars/male.png',
    description: null,
    identification_color: null,
    member_name: null,
    member_id: null,
});

const inputRef = ref(null);

const store = () => form.post('/dashboard/departments');

const handleImageResized = (blob) => {
    const reader = new FileReader();
    reader.onload = (e) => {
        form.image_identification = e.target.result;
    };
    reader.readAsDataURL(blob);
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const seacher = reactive({
    member_name: null,
});

const search = () => {
    router.visit(`/dashboard/departments/create`, {
        method: 'get',
        data: { seacher },
        only: ['members'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.member_id = props.members ? form.member_id : null;
        },
    });
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
    } else {
        form[field] = id;
    }
};

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(route('departments.create'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page-title="Novo departamento">
        <Breadcrumb
            current-page-name="Novo departamento"
            :link-list="[
                {
                    previous_page_url: 'departments',
                    previous_page_name: 'Departamentos',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Novo departamento</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing || !form.member_id"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="form.image_identification"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon class="text-white" :icon="['fas', 'pen']" />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="col-md-6">
                    <label for="name" class="form-label"> Nome do departamento </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Comunicação"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="exampleColorInput" class="form-label">Cor de identificação</label>
                    <input
                        type="color"
                        v-model="form.identification_color"
                        class="form-control form-control-color"
                        id="exampleColorInput"
                        value="#563d7c"
                        title="Choose your color"
                    />
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para o departamento"
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="member_id" class="form-label">
                        Selecione o líder do departamento
                    </label>

                    <input
                        id="member_id"
                        v-model="form.member_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Werlla Ribeiro"
                        @input="compositionSeacher('member_name', $event)"
                    />
                </div>

                <hr class="mb-0" />

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in members"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.member_id === item.id }"
                    >
                        <input
                            :id="'checkBoxSpouse' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.member_id === item.id"
                            @change="toggleSelection('member_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxSpouse' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/members/${item.id}`"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>

                <input type="file" ref="inputRef" class="file-input d-none" />
            </Form>
            <PhotoEditModal
                v-if="form.image_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
