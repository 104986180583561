<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    discipleship: Object,
});

const form = useForm({
    start_time: null,
    end_time: null,
});

const store = () => form.post(`/dashboard/discipleships/${props.discipleship.id}/modules`);
</script>

<template>
    <Dashboard page-title="Criar programação">
        <Breadcrumb
            current-page-name="Criar programação"
            :link-list="[
                {
                    previous_page_url: `discipleships/${discipleship.id}/modules`,
                    previous_page_name: `${discipleship.name}`,
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Criar programação</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-6">
                    <label for="start_time" class="form-label"> Horário de início </label>

                    <input
                        id="start_time"
                        v-model="form.start_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.start_time" class="text-danger mt-3">
                        {{ form.errors.start_time }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="end_time" class="form-label"> Horário de fim </label>

                    <input
                        id="end_time"
                        v-model="form.end_time"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder=""
                        required
                    />
                    <small v-if="form.errors.end_time" class="text-danger mt-3">
                        {{ form.errors.end_time }}
                    </small>
                </div>
            </Form>
        </div>
    </Dashboard>
</template>
