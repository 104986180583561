<script setup>
import { watch, computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    discipleships: Object,
});

const { compositionSeacher, updateLoadingState, isLoading } = useSearch(
    {
        name: '',
    },
    'discipleships.index'
);

watch(
    () => props.discipleships,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);

const totalParticipants = computed(() => {
    return props.discipleships && props.discipleships.data
        ? props.discipleships.data.reduce((total, item) => total + item.total_registrations, 0)
        : 0;
});
</script>

<template>
    <Dashboard page-title="Turmas">
        <Breadcrumb current-page-name="Turmas" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Turmas</h4>
                <Link href="/dashboard/discipleships/create" class="btn btn-primary btn-add">
                    <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                    Nova
                </Link>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome da turma</label>
                        <input
                            id="floatingInput"
                            type="text"
                            class="form-control"
                            placeholder="Turma Maio 2025"
                            @input="compositionSeacher('name', $event)"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <Table
                    table-id="table-discipleship"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'identifier', label: 'Nome da Turma' },
                        {
                            key: 'total_members',
                            label: 'Inscritos',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'open',
                            label: 'Situação',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="discipleships?.data"
                    :pagination="{ data: discipleships?.meta, reference: 'discipleships' }"
                    :rowLink="(item) => route('discipleships.show', item.id)"
                    :is-loading="isLoading"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>
                    <template #cell-identifier="{ item }">
                        {{ item.name }}
                    </template>
                    <template #cell-total_members="{ item }">
                        {{ item.total_registrations }} pessoa(s)
                    </template>
                    <template #cell-open="{ item }">
                        <Badge :name="item.open ? 'OPEN' : 'CLOSED'" />
                    </template>
                </Table>
            </div>
            <p class="text-center font-bold fs-12">Soma geral: {{ totalParticipants }} inscritos</p>
        </div>
    </Dashboard>
</template>
