<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    musics: Object,
    artists: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
        artist_id: 0,
        start_date: null,
        end_date: null,
    },
    'musics.index'
);

watch(
    () => props.musics,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Músicas">
        <Breadcrumb current-page-name="Músicas" />

        <PageHeader title="Músicas" :button-link="route('musics.create')" button-text="Nova" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center g-3">
                <div class="col-md-12">
                    <label for="musicName" class="form-label">Nome da música</label>
                    <input
                        id="musicName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Seu Amor"
                        @input="executeSearch()"
                    />
                </div>
                <div class="col-md-4">
                    <label class="form-label" for="startDate">Tocada em</label>
                    <input
                        id="startDate"
                        type="date"
                        v-model="searchParams.start_date"
                        class="form-control"
                        @change="executeSearch()"
                    />
                </div>
                <div class="col-md-4">
                    <label class="form-label" for="endDate">Até</label>
                    <input
                        id="endDate"
                        type="date"
                        v-model="searchParams.end_date"
                        class="form-control"
                        @change="executeSearch()"
                    />
                </div>
                <div class="col-md-4">
                    <label for="artist_id" class="form-label">Artista</label>
                    <select
                        id="artist_id"
                        v-model="searchParams.artist_id"
                        class="form-select"
                        @change="executeSearch()"
                    >
                        <option value="0">Todos</option>
                        <option v-for="(item, index) in artists" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <Table
                table-id="table-musics"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'name', label: 'Nome' },
                    {
                        key: 'artist',
                        label: 'Artista',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'youtube',
                        label: 'Youtube',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'duration',
                        label: 'Duração',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'reproductions',
                        label: 'Reproduções',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="musics?.data"
                :pagination="{
                    data: musics?.meta,
                    reference: 'musics',
                }"
                :rowLink="(item) => route('musics.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage
                            width="35px"
                            :src="`/storage/${item.cover_photo}`"
                            class="rounded-pill me-3"
                        />
                        <span>{{ item.name }}</span>
                    </div>
                </template>

                <template #cell-artist="{ item }">
                    <div class="d-flex justify-content-center">
                        <Link v-if="item.artist" :href="route('artists.show', item.artist_id)">
                            <LazyImage
                                width="35px"
                                :src="`/storage/${item.artist.profile_photo}`"
                                class="rounded-circle border"
                            />
                        </Link>
                        <span v-else>-</span>
                    </div>
                </template>

                <template #cell-youtube="{ item }">
                    <a
                        v-if="item.youtube_link"
                        :href="item.youtube_link"
                        target="_blank"
                        class="btn btn-link text-danger p-0 fs-3"
                    >
                        <font-awesome-icon :icon="['fab', 'youtube']" />
                    </a>
                    <span v-else>-</span>
                </template>

                <template #cell-duration="{ item }">
                    {{ item.duration }}
                </template>

                <template #cell-reproductions="{ item }">
                    {{ item.reproductions ? `${item.reproductions} x` : '-' }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
