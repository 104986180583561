<script setup>
import { defineProps, onMounted, ref } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    allEvents: Object,
    counselors: Object,
    baptismHolySpirit: Object,
    currentEvent: Object,
});

const form = useForm({
    counselors: [],
    current_church: props.baptismHolySpirit.current_church,
    event_id: props.currentEvent.id,
    observations: props.baptismHolySpirit.observations,
});

const update = () =>
    form.put(route('members.baptism-holy-spirits.update', props.baptismHolySpirit.id));

const toggleCounselorSelection = (counselorId) => {
    const index = form.counselors.indexOf(counselorId);
    return index === -1 ? form.counselors.push(counselorId) : form.counselors.splice(index, 1);
};

onMounted(() => {
    form.counselors = props.baptismHolySpirit.counselors.map((counselor) => counselor.id);
});

const baptismHolySpiritEditModalCloseButton = ref(null);

const destroy = () => {
    baptismHolySpiritEditModalCloseButton.value.click();
    router.delete(route('members.baptism-holy-spirits.destroy', props.baptismHolySpirit.id));
};
</script>

<template>
    <Dashboard :page-title="baptismHolySpirit.member.name">
        <Breadcrumb
            :current-page-name="baptismHolySpirit.member.name"
            :link-list="[
                {
                    previous_page_url: 'baptism-holy-spirits',
                    previous_page_name: 'Batismos no Espírito Santo',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/baptism-holy-spirits/${baptismHolySpirit.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar regsitro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#baptismHolySpiritEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-6">
                <label for="current_church" class="form-label">Nome da igreja atual</label>
                <input
                    id="current_church"
                    v-model="form.current_church"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.current_church" class="text-danger mt-3">
                    {{ form.errors.current_church }}
                </small>
            </div>

            <div class="col-md-6">
                <label for="type" class="form-label">Evento relacionado</label>
                <select id="type" v-model="form.event_id" class="form-select" required>
                    <option v-for="(item, index) in allEvents" :key="index" :value="item.id">
                        {{ item.schedule.name }} - {{ item.name }}
                    </option>
                </select>
            </div>

            <div class="col-md-12">
                <label for="observations" class="form-label"> Observações </label>

                <textarea
                    id="observations"
                    v-model="form.observations"
                    type="text"
                    class="form-control"
                    name="observations"
                    placeholder="Caso necessário, forneca alguma observação para esse registro"
                    rows="5"
                />

                <small v-if="form.errors.observations" class="text-danger mt-3">
                    {{ form.errors.observations }}
                </small>
            </div>

            <hr class="mb-0" />

            <legend class="mb-4">Selecione os conselheiros</legend>

            <div class="row">
                <div
                    v-for="(item, index) in counselors"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-lg-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="`/storage/${item.identification_photo}`"
                            :class="{
                                selected: form.counselors.includes(item.id),
                            }"
                            @click="toggleCounselorSelection(item.id)"
                        />
                        <span
                            v-show="form.counselors.includes(item.id)"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.name }}</span>
                        <input type="checkbox" name="image[]" value="" />
                    </label>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="baptismHolySpiritEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="baptismHolySpiritEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
