<script setup>
import { reactive, onMounted } from 'vue';
import { useForm, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    group: Object,
    members: Object,
});

const form = useForm({
    meeting_date: null,
    place_held: null,
    host: null,
});

const toggleHostSelection = (hostId) => {
    form.host = form.host === hostId ? null : hostId;
};

const store = () => form.post(`/dashboard/group-connections/${props.group.id}/meetings`);
</script>

<template>
    <Dashboard page-title="Novo encontro">
        <Breadcrumb
            current-page-name="Novo encontro"
            :link-list="[
                {
                    previous_page_url: `group-connections/${group.id}/meetings`,
                    previous_page_name: 'Encontros',
                },
            ]"
        />
        <div id="create-minister">
            <h4 class="my-3">Novo encontro</h4>

            <Form
                button-name="Adicionar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-12">
                    <label for="meeting_date" class="form-label mx-0"> Horário do encontro </label>

                    <input
                        id="meeting_date"
                        v-model="form.meeting_date"
                        type="datetime-local"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Turma Maio 2025"
                        required
                    />
                    <small v-if="form.errors.meeting_date" class="text-danger mt-3">
                        {{ form.errors.meeting_date }}
                    </small>
                </div>
                <div class="col-md-12">
                    <label for="place_held" class="form-label"> Endereço do encontro </label>

                    <input
                        id="place_held"
                        v-model="form.place_held"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Rua São Jorge n 99 - Próximo a Saúde Cia"
                    />
                    <small v-if="form.errors.place_held" class="text-danger mt-3">
                        {{ form.errors.place_held }}
                    </small>
                </div>

                <template v-if="members.length">
                    <hr class="mb-0" />

                    <legend class="mb-4">Selecione o anfitrião desse encontro</legend>

                    <div class="row">
                        <div
                            v-for="(item, index) in members"
                            :key="index"
                            class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                        >
                            <label class="image-checkbox position-relative">
                                <img
                                    class="img-responsive"
                                    :src="`${item.member.identification_photo}`"
                                    :class="{
                                        selected: form.host == item.id,
                                    }"
                                    @click="toggleHostSelection(item.id)"
                                />
                                <span
                                    v-show="form.host == item.id"
                                    class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                                >
                                    <font-awesome-icon :icon="['fas', 'circle-check']" />
                                </span>
                                <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                                <input type="checkbox" name="image[]" value="" />
                            </label>
                        </div>
                    </div>
                </template>
            </Form>
        </div>
    </Dashboard>
</template>
