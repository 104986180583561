<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    members: Object,
    schedules: Object,
    typesIndications: Object,
});

const {
    searchParams,
    isLoading,
    compositionSeacher,
    updateLoadingState,
    executeSearch,
    buildDownloadUrl,
} = useSearch(
    {
        name: '',
        met_church: '',
        schedule_id: 0,
        start_date: '',
        end_date: '',
        believer: '',
    },
    'members.visitors.index'
);

watch(
    () => props.members,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Visitantes">
        <Breadcrumb current-page-name="Visitantes" />

        <PageHeader
            title="Visitantes"
            :button-link="route('members.visitors.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar visitantes</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('visitors', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('visitors', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row g-3">
                <div class="col-md-6">
                    <label class="form-label" for="floatingInput">Nome do visitante</label>
                    <input
                        id="floatingInput"
                        type="email"
                        class="form-control"
                        placeholder="Aline Andrade"
                        @input="(event) => compositionSeacher('name', event, executeSearch)"
                    />
                </div>

                <div class="col-6 col-sm-3">
                    <label for="startDate" class="form-label">Data de início</label>
                    <input
                        id="startDate"
                        v-model="searchParams.start_date"
                        type="date"
                        class="form-control"
                        name=""
                        @change="executeSearch()"
                    />
                </div>

                <div class="col-6 col-sm-3">
                    <label for="endDate" class="form-label">Data de fim</label>
                    <input
                        id="endDate"
                        v-model="searchParams.end_date"
                        type="date"
                        class="form-control"
                        name=""
                        @change="executeSearch()"
                    />
                </div>

                <div class="col-md-4">
                    <label for="floatingSelect" class="bg-transparent form-label"
                        >Selecionar programação</label
                    >
                    <select
                        id="schedule_id"
                        v-model="searchParams.schedule_id"
                        class="form-select"
                        required
                        @change="executeSearch()"
                    >
                        <option value="0">Todas as programações</option>
                        <option v-for="(item, index) in schedules" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="type" class="form-label">Como conheceu a igreja</label>
                    <select
                        id="type"
                        v-model="searchParams.met_church"
                        class="form-select form-select"
                        aria-label="Small select example"
                        @change="executeSearch()"
                    >
                        <option value="">Todas opções</option>
                        <option
                            v-for="(item, index) in typesIndications"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-4">
                    <label for="floatingSelect" class="bg-transparent form-label"
                        >Situação do visitante</label
                    >
                    <select
                        id="believer"
                        v-model="searchParams.believer"
                        class="form-select"
                        required
                        @change="executeSearch()"
                    >
                        <option value="">Todas</option>
                        <option value="1">Somente crentes</option>
                        <option value="0">Somente não convertidos</option>
                    </select>
                </div>
            </div>

            <Table
                table-id="table-visitors"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center fw-bold',
                    },
                    {
                        key: 'name',
                        label: 'Nome do visitante',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'schedule',
                        label: 'Programação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'believer',
                        label: 'Crente',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'visit_date',
                        label: 'Data de visita',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="members?.data"
                :pagination="{
                    data: members?.meta,
                    reference: 'members',
                }"
                :rowLink="(item) => route('members.visitors.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.member.clean_name }}
                </template>

                <template #cell-schedule="{ item }">
                    <span v-if="item.schedule_name">
                        {{ item.schedule_name }}
                    </span>
                    <span
                        v-else
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        data-bs-title="Dado do SGI antigo"
                    >
                        <font-awesome-icon :icon="['fas', 'minus']" />
                    </span>
                </template>

                <template #cell-believer="{ item }">
                    <Badge :name="item.believer ? 'YES' : 'NOT'" />
                </template>

                <template #cell-visit_date="{ item }">
                    {{ item.created_at }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
