<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    data: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

const { simplifyDate } = useDateFormat();

const eventDetails = {
    visit: {
        description: 'Primeira visita a igreja',
        icon: 'person-shelter',
        colorClass: 'text-white',
        backgroundClass: 'bg-success',
        route: 'visitors',
    },
    new_birth: {
        description: 'Membro aceitou jesus',
        icon: 'person-praying',
        colorClass: 'text-white',
        backgroundClass: 'bg-danger',
        route: 'new-births',
    },
    baptism_holy_spirit: {
        description: 'Batismo no espírito santo',
        icon: 'person-rays',
        colorClass: 'text-warning',
        backgroundClass: 'bg-dark',
        route: 'baptism-holy-spirits',
    },
    water_baptism: {
        description: 'Membro adicionado a turma de batismo',
        icon: 'person-drowning',
        colorClass: 'text-white',
        backgroundClass: 'bg-primary',
        route: 'water-baptisms',
    },
    default: {
        description: 'Tipo desconhecido',
        icon: 'Tipo desconhecido',
        colorClass: 'text-secondary',
        backgroundClass: 'bg-dark',
    },
};

const getEventDetails = (type) => eventDetails[type] || eventDetails.default;
</script>

<template>
    <div class="timeline col-11 mx-auto mt-3">
        <section>
            <ul class="timeline-with-icons">
                <li class="timeline-item mb-5" v-for="(item, index) in data" :key="index">
                    <Link
                        :href="`/dashboard/${getEventDetails(item.type).route}/${item.properties.data.id}`"
                    >
                        <span
                            class="timeline-icon"
                            :class="getEventDetails(item.type).backgroundClass"
                        >
                            <font-awesome-icon
                                :icon="['fas', getEventDetails(item.type).icon]"
                                :class="getEventDetails(item.type).colorClass"
                            ></font-awesome-icon>
                        </span>
                    </Link>

                    <h5 class="fw-bold">{{ getEventDetails(item.type).description }}</h5>
                    <p class="text-muted mb-2 fw-bold">
                        {{ item.data ? simplifyDate(item.data, false) : '' }}
                        <span v-if="item.type == 'water_baptism'"> - Data da realização</span>
                    </p>

                    <p class="text-muted" v-if="item.type == 'visit'">
                        No
                        <Link
                            :href="`/dashboard/events/${item.properties?.event?.id}`"
                            :class="{ disabled: !item.properties?.event?.id }"
                            :style="{
                                'pointer-events': !item.properties?.event?.id ? 'none' : 'auto',
                            }"
                        >
                            {{ item.properties?.event?.name ?? 'culto' }} </Link
                        >, o membro fez sua primeira visita à nossa igreja. Ele conheceu nossa
                        igreja a partir de um(a)
                        <Badge :name="item.properties.data.met_church" />
                    </p>
                    <p class="text-muted" v-else-if="item.type == 'baptism_holy_spirit'">
                        No
                        <Link
                            :href="`/dashboard/events/${item.properties?.event?.id}`"
                            :class="{ disabled: !item.properties?.event?.id }"
                            :style="{
                                'pointer-events': !item.properties?.event?.id ? 'none' : 'auto',
                            }"
                        >
                            {{ item.properties.event?.name ?? 'culto' }} </Link
                        >, o membro recebeu o batismo no espírito santo.
                    </p>
                    <p class="text-muted" v-else-if="item.type == 'new_birth'">
                        No
                        <Link
                            :href="`/dashboard/events/${item.properties?.event?.id}`"
                            :class="{ disabled: !item.properties?.event?.id }"
                            :style="{
                                'pointer-events': !item.properties?.event?.id ? 'none' : 'auto',
                            }"
                        >
                            {{ item.properties.event?.name ?? 'culto' }} </Link
                        >, o membro
                        <Badge
                            :name="
                                item.properties.data.type == 'NEW_BIRTH'
                                    ? 'Aceitou Jesus'
                                    : 'Reconciliou'
                            "
                        />
                        <span v-if="item.properties.data.type == 'NEW_BIRTH'">
                            como o seu salvador em um evento abençoado.
                        </span>
                        <span v-else> com Jesus.</span>
                    </p>
                    <p class="text-muted" v-else-if="item.type == 'water_baptism'">
                        Na turma de
                        <Link
                            :href="`/dashboard/water-baptisms/${item.properties.data.id}`"
                            :class="{ disabled: !item.properties?.data?.id }"
                            :style="{
                                'pointer-events': !item.properties?.data?.id ? 'none' : 'auto',
                            }"
                        >
                            {{ item.properties.data.identifier }} </Link
                        >, o membro se inscreveu para participar do batismo.
                    </p>
                </li>
            </ul>
        </section>
    </div>
</template>
