<script setup>
import { onMounted } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useDominantColor } from '@/Pages/Dashboard/Composables/useDominantColor.js';

const props = defineProps({
    theme: Object,
    musics: Array,
});

const { dominantColor, extractDominantColor } = useDominantColor();

onMounted(() => {
    if (props.theme?.photo_identification) {
        const imageSrc = `/storage/${props.theme.photo_identification}`;
        extractDominantColor(imageSrc);
    }
});
</script>

<template>
    <Dashboard :page-title="theme.name">
        <Breadcrumb
            :current-page-name="theme.name"
            :link-list="[
                {
                    previous_page_url: 'musical-themes',
                    previous_page_name: 'Temas',
                },
            ]"
        />

        <div
            class="rounded-3 text-white mb-3 p-4"
            :style="{
                background: `linear-gradient(135deg, ${dominantColor}, #000)`,
            }"
        >
            <div class="row d-flex align-items-center">
                <div class="col-lg-4">
                    <div class="position-relative d-flex justify-content-center">
                        <img
                            class="rounded-3 shadow"
                            width="200px"
                            :src="`/storage/${theme.photo_identification}`"
                            alt="Capa do Tema"
                        />
                        <div class="position-absolute top-0 end-0 m-5 mt-2">
                            <Link
                                :href="route('musical-themes.edit', theme.id)"
                                class="btn btn-light"
                            >
                                <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                            </Link>
                        </div>
                    </div>
                </div>
                <div class="col-lg-8 mt-3 mt-sm-0">
                    <div class="row">
                        <span class="font-semibold text-white">Tema</span>
                        <h2 class="font-bold">{{ theme.name }}</h2>
                    </div>
                </div>
            </div>
        </div>

        <div class="card-simple card p-4" v-if="musics.length">
            <h4 class="font-bold mb-0">Músicas</h4>
            <span class="text-secondary font-semibold fs-12 mb-3"> Relacionadas a esse tema </span>

            <div class="search-form-card shadow-none border-0">
                <Table
                    table-id="table-musics"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        { key: 'name', label: 'Nome' },
                        {
                            key: 'youtube',
                            label: 'Youtube',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'duration',
                            label: 'Duração',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="musics"
                    :pagination="{ reference: 'musics' }"
                    :rowLink="(music) => route('musics.show', music.id)"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-name="{ item }">
                        <div class="d-flex align-items-center">
                            <LazyImage :src="`/storage/${item.cover_photo}`" width="35px" />
                            <span class="ms-3">{{ item.name }}</span>
                        </div>
                    </template>

                    <template #cell-youtube="{ item }">
                        <a
                            target="_blank"
                            class="btn btn-link text-danger p-0 fs-3"
                            :class="{ disabled: !item.youtube_link }"
                            :href="item.youtube_link"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            data-bs-title="Assistir no Youtube"
                        >
                            <font-awesome-icon :icon="['fab', 'youtube']" />
                        </a>
                    </template>

                    <template #cell-duration="{ item }">
                        <div class="text-center">
                            {{ item.duration }}
                        </div>
                    </template>
                </Table>
            </div>
        </div>
    </Dashboard>
</template>
