<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    leaders: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
    },
    'leaders.index'
);

watch(
    () => props.leaders,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Dirigentes">
        <Breadcrumb current-page-name="Dirigentes" />

        <PageHeader title="Dirigentes" :button-link="route('leaders.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <label for="artistName" class="form-label">Nome do dirigente</label>
                    <input
                        id="artistName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Renato de Sá"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-leaders"
                :headers="[
                    {
                        key: 'index',
                        label: 'ID',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'name', label: 'Nome do ministro' },
                    {
                        key: 'created_at',
                        label: 'Data de cadastro',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="leaders?.data"
                :pagination="{
                    data: leaders?.meta,
                    reference: 'leaders',
                }"
                :rowLink="(item) => route('leaders.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="`/storage/${item.identification_photo}`" />
                        <span class="ms-3">{{ item.name }}</span>
                    </div>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
