<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    waterBaptism: Object,
    shepherds: Array,
});

const form = useForm({
    identifier: props.waterBaptism.identifier,
    open: !!props.waterBaptism.open,
    completion_date: props.waterBaptism.completion_date_gross,
    responsible_id: props.waterBaptism.responsible.id,
    helper_id: props.waterBaptism.helper?.id || null,
    address: props.waterBaptism.address,
    comments: props.waterBaptism.comments,
    enrolled_members: props.waterBaptism.members.map((member) => member.id),
});

const update = () =>
    form
        .transform((data) => ({
            ...data,
        }))
        .put(`/dashboard/water-baptisms/${props.waterBaptism.id}`);

const waterBaptismEditModalCloseButton = ref(null);

const destroy = () => {
    waterBaptismEditModalCloseButton.value.click();
    router.delete(`/dashboard/water-baptisms/${props.waterBaptism.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar dados da turma">
        <Breadcrumb
            current-page-name="Editar dados da turma"
            :link-list="[
                {
                    previous_page_url: 'water-baptisms',
                    previous_page_name: 'Turmas de batismo',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/water-baptisms/${waterBaptism.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#waterBaptismEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <!-- Dados da turma -->
            <div class="col-md-5">
                <label for="identifier" class="form-label">Nome de identificação da turma</label>
                <input
                    id="identifier"
                    v-model="form.identifier"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Turma Março 2024"
                    required
                />
                <small v-if="form.errors.identifier" class="text-danger mt-3">
                    {{ form.errors.identifier }}
                </small>
            </div>

            <div class="col-md-5">
                <label for="completion_date" class="form-label">Data de realização</label>
                <small class="optional ms-2">(opcional)</small>
                <input
                    id="completion_date"
                    v-model="form.completion_date"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.completion_date" class="text-danger mt-3">
                    {{ form.errors.completion_date }}
                </small>
            </div>

            <div class="col-md-2 d-flex align-items-end justify-content-end">
                <div class="form-check d-flex align-items-center form-switch mb-2">
                    <input
                        id="flexCheckDefault"
                        v-model="form.open"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">Aberta</label>
                </div>
            </div>

            <div class="col-md-12">
                <label for="address" class="form-label">Local de batismo</label>
                <small class="optional ms-2">(opcional)</small>
                <input
                    id="address"
                    v-model="form.address"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua da Alegria - nº 100 - Centro"
                />
                <small v-if="form.errors.address" class="text-danger mt-3">
                    {{ form.errors.address }}
                </small>
            </div>

            <div class="col-md-12">
                <label for="comments" class="form-label">Descrição</label>
                <small class="optional ms-2">(opcional)</small>
                <textarea
                    id="comments"
                    v-model="form.comments"
                    class="form-control"
                    placeholder="Forneça alguma descrição para essa turma"
                    rows="5"
                />
                <small v-if="form.errors.comments" class="text-danger mt-3">
                    {{ form.errors.comments }}
                </small>
            </div>

            <!-- Seleção de responsável e auxiliar (já existente) -->
            <hr class="mb-0" />
            <legend class="mb-4">Selecione o responsável pelo batismo</legend>
            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="item.identification_photo"
                            :class="{ selected: form.responsible_id == item.id }"
                            @click="form.responsible_id = item.id"
                        />
                        <span
                            v-show="form.responsible_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.clean_name }}</span>
                    </label>
                </div>
            </div>

            <hr class="mb-0" />
            <legend class="mb-4">Selecione o auxiliar do responsável pelo batismo</legend>
            <div class="row">
                <div
                    v-for="(item, index) in shepherds"
                    :key="index"
                    class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                >
                    <label class="image-checkbox position-relative">
                        <img
                            class="img-responsive"
                            :src="item.identification_photo"
                            :class="{ selected: form.helper_id == item.id }"
                            @click="form.helper_id = item.id"
                        />
                        <span
                            v-show="form.helper_id == item.id"
                            class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                        >
                            <font-awesome-icon :icon="['fas', 'circle-check']" />
                        </span>
                        <span class="mt-3 d-block">{{ item.clean_name }}</span>
                    </label>
                </div>
            </div>

            <!-- Seção de Membros Inscritos -->
            <hr class="mb-0" />

            <legend>Membros inscritos na turma</legend>

            <div class="search-form-card">
                <div v-if="waterBaptism.members.length" class="col-12">
                    <Table
                        table-id="table-enrolled-members"
                        :headers="[
                            {
                                key: 'checkbox',
                                label: 'Incritos',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'member',
                                label: 'Membro',
                                thClass: 'text-start',
                                tdClass: 'text-start',
                            },
                            {
                                key: 'phone',
                                label: 'Telefone',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                        ]"
                        :data="waterBaptism.members"
                        :pagination="{ reference: 'waterBaptisms' }"
                    >
                        <template #cell-checkbox="{ item }">
                            <input
                                type="checkbox"
                                :value="item.id"
                                class="form-check-input"
                                v-model="form.enrolled_members"
                            />
                        </template>
                        <template #cell-member="{ item }">
                            <div class="d-flex align-items-center">
                                <LazyImage width="30px" :src="item.identification_photo" />
                                <span class="ms-3">{{ item.clean_name }}</span>
                            </div>
                        </template>
                        <template #cell-phone="{ item }">
                            {{ item.first_phone ?? 'N/A' }}
                        </template>
                    </Table>
                </div>
                <div v-else class="col-12">
                    <p class="text-center">Nenhum membro inscrito.</p>
                </div>
            </div>
        </Form>

        <ConfirmationModal
            modal_id="waterBaptismEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="waterBaptismEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
