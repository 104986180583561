<script setup>
import { computed, defineProps } from 'vue';

const props = defineProps({ name: String });

const statusStyle = computed(() => {
    const style = {
        NEW_BIRTH: {
            color: 'white',
            background: 'primary',
            name: 'Novo nascimento',
        },
        RECONCCILIATION: {
            color: 'dark',
            background: 'warning',
            name: 'Reconciliação',
        },
        MARRIED: {
            color: 'white',
            background: 'danger',
            name: 'Casado (a)',
        },
        SINGLE: {
            color: 'white',
            background: 'secondary',
            name: 'Solteiro (a)',
        },
        DIVORCED: {
            color: 'white',
            background: 'dark',
            name: 'Divorciado (a)',
        },
        OTHER: {
            color: 'dark',
            background: 'warning',
            name: 'Outra situação',
        },
        FRIEND: {
            color: 'white',
            background: 'primary',
            name: 'Amigo',
        },
        BROTHER: {
            color: 'dark',
            background: 'warning',
            name: 'Irmão',
        },
        SOCIAL_MEDIA: {
            color: 'dark',
            background: 'warning',
            name: 'Social media',
        },
        OTHERS: {
            color: 'dark',
            background: 'warning',
            name: 'Outros',
        },
        MALE: {
            color: 'white',
            background: 'primary',
            name: 'Masculino',
        },
        FEMALE: {
            color: 'white',
            background: 'info',
            name: 'Feminino',
        },
        CULT: {
            color: 'white',
            background: 'primary',
            name: 'Culto',
        },
        VISITOR: {
            color: 'dark',
            background: 'warning',
            name: 'Visitante',
        },
        MEMBER: {
            color: 'white',
            background: 'success',
            name: 'Membro',
        },
        INACTIVE: {
            color: 'white',
            background: 'danger',
            name: 'Inativo',
        },
        YES: {
            color: 'white',
            background: 'success',
            name: 'Sim',
        },
        NOT: {
            color: 'white',
            background: 'danger',
            name: 'Não',
        },
        INCOMPLETE_PRIMARY: {
            color: 'white',
            background: 'danger',
            name: 'Primário Incompleto',
        },
        COMPLETE_PRIMARY: {
            color: 'white',
            background: 'success',
            name: 'Primário Completo',
        },
        INCOMPLETE_HIGH_SCHOOL: {
            color: 'white',
            background: 'danger',
            name: 'Ensino Médio Incompleto',
        },
        COMPLETE_HIGH_SCHOOL: {
            color: 'white',
            background: 'danger',
            name: 'Ensino Médio Completo',
        },
        INCOMPLETE_COLLEGE: {
            color: 'white',
            background: 'danger',
            name: 'Faculdade Incompleta',
        },
        COMPLETE_COLLEGE: {
            color: 'white',
            background: 'success',
            name: 'Faculdade Completa',
        },
        INCOMPLETE_POSTGRADUATE_DEGREE: {
            color: 'white',
            background: 'danger',
            name: 'Pós-Graduação Incompleta',
        },
        COMPLETE_POSTGRADUATE_DEGREE: {
            color: 'white',
            background: 'success',
            name: 'Pós-Graduação Completa',
        },
        INCOMPLETE_MBA: {
            color: 'white',
            background: 'danger',
            name: 'MBA Incompleto',
        },
        COMPLETE_MBA: {
            color: 'white',
            background: 'success',
            name: 'MBA Completo',
        },
        INCOMPLETE_PHD: {
            color: 'white',
            background: 'danger',
            name: 'PHD Incompleto',
        },
        COMPLETE_PHD: {
            color: 'white',
            background: 'success',
            name: 'PHD Completo',
        },
        INCOMPLETE_POST_PHD: {
            color: 'white',
            background: 'danger',
            name: 'Pós-Doutoramento Incompleto',
        },
        COMPLETE_POST_PHD: {
            color: 'white',
            background: 'success',
            name: 'Pós-Doutoramento Completo',
        },
        ACTIVATE: {
            color: 'white',
            background: 'success',
            name: 'Ativo',
        },
        OPEN: {
            color: 'white',
            background: 'success',
            name: 'Aberta',
        },
        DEACTIVATE: {
            color: 'white',
            background: 'danger',
            name: 'Desativado',
        },
        CLOSED: {
            color: 'white',
            background: 'danger',
            name: 'Fechada',
        },
        EVANGELIZATION: {
            color: 'white',
            background: 'danger',
            name: 'Evangelização',
        },
        NEW_CONVERT: {
            color: 'white',
            background: 'primary',
            name: 'Novo Convertido',
        },
        PAID_EVENT: {
            color: 'white',
            background: 'primary',
            name: 'Evento Pago',
        },
        CASH: {
            color: 'white',
            background: 'success',
            name: 'Dinheiro',
        },
        CARD: {
            color: 'white',
            background: 'primary',
            name: 'Cartão',
        },
        PIX: {
            color: 'dark',
            background: 'warning',
            name: 'PIX',
        },
        PENDING: {
            color: 'white',
            background: 'primary',
            name: 'Pendente',
        },
        CONGREGATION: {
            color: 'dark',
            background: 'warning',
            name: 'Congregação',
        },
        MAIN: {
            color: 'white',
            background: 'success',
            name: 'Principal',
        },
        LEADER: {
            color: 'white',
            background: 'danger',
            name: 'Líder',
        },
        VICE_LEADER: {
            color: 'white',
            background: 'danger',
            name: 'Vice Líder',
        },
        SUPERVISOR: {
            color: 'white',
            background: 'danger',
            name: 'Supervisor',
        },
        OFF: {
            color: 'white',
            background: 'danger',
            name: 'Desligada',
        },
        COMPLETED: {
            color: 'white',
            background: 'success',
            name: 'Realizado',
        },
        HOST: {
            color: 'dark',
            background: 'warning',
            name: 'Anfitrião',
        },
        PRESENT: {
            color: 'white',
            background: 'success',
            name: 'Presente',
        },
        ABSENT: {
            color: 'white',
            background: 'danger',
            name: 'Ausente',
        },
        CHILD: {
            color: 'white',
            background: 'success',
            name: 'Filho (a)',
        },
        FIXED: {
            color: 'white',
            background: 'success',
            name: 'Fixo',
        },
        CHILD: {
            color: 'white',
            background: 'success',
            name: 'Filho (a)',
        },
    };

    return (
        style[props.name] || {
            color: 'dark',
            background: 'warning',
            name: props.name,
        }
    );
});
</script>

<template>
    <span :class="`badge bg-${statusStyle.background} text-${statusStyle.color} p-2`">
        {{ statusStyle.name }}
    </span>
</template>
