<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    albums: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
    },
    'albums.index'
);

watch(
    () => props.albums,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Álbuns">
        <Breadcrumb current-page-name="Álbuns" />
        <PageHeader title="Álbuns" :button-link="route('albums.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center g-3">
                <div class="col-md-12">
                    <label for="albumName" class="form-label">Nome do álbum</label>
                    <input
                        id="albumName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Ex: Meu Álbum"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-musics"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'title', label: 'Título' },
                    {
                        key: 'artist',
                        label: 'Artista',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_musics',
                        label: 'Músicas',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'release_date',
                        label: 'Lançamento',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="albums?.data"
                :pagination="{
                    data: albums?.meta,
                    reference: 'albums',
                }"
                :rowLink="(item) => route('albums.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-title="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage
                            width="35px"
                            :src="`/storage/${item.cover_image}`"
                            class="rounded-pill"
                        />
                        <span class="ms-3">{{ item.title }}</span>
                    </div>
                </template>

                <template #cell-total_musics="{ item }">
                    {{ item.total_musics }}
                </template>

                <template #cell-artist="{ item }">
                    <div class="d-flex justify-content-center">
                        <Link v-if="item.artist" :href="route('artists.show', item.artist_id)">
                            <LazyImage
                                width="35px"
                                :src="`/storage/${item.artist.profile_photo}`"
                                class="rounded-circle border"
                            />
                        </Link>
                        <span v-else>-</span>
                    </div>
                </template>

                <template #cell-release_date="{ item }">
                    {{ item.release_date }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
