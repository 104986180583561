<script setup>
import { defineProps } from 'vue';
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    events: Object,
    classe: Object,
    teachers: Object,
});

const form = useForm({
    event_id: props.events[0].id || null,
    teachers: [],
});

const toggleTeacherSelection = (teacherID) => {
    const index = form.teachers.indexOf(teacherID);
    if (index === -1) {
        form.teachers.push(teacherID);
    } else {
        form.teachers.splice(index, 1);
    }
};

const store = () => form.post(route('child-department-classe.lessons.store', props.classe.id));
</script>

<template>
    <Dashboard page-title="Nova aula">
        <Breadcrumb
            current-page-name="Nova aula"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${classe.id}/lessons`,
                    previous_page_name: 'Aulas',
                },
            ]"
        />
        <h4 class="my-3">Nova aula</h4>

        <Form
            button-name="Nova aula"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.event_id"
        >
            <div class="row g-2">
                <div class="col-md-12">
                    <label for="event_id" class="form-label"> Evento </label>

                    <select id="event_id" v-model="form.event_id" class="form-select" required>
                        <option v-for="event in events" :key="event.id" :value="event.id">
                            {{ event.name }} - {{ event.start_time }}
                        </option>
                    </select>

                    <small v-if="form.errors.event_id" class="text-danger mt-3">
                        {{ form.errors.event_id }}
                    </small>
                </div>

                <hr />

                <legend class="mb-4">Selecione os professores relacionados</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in teachers"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`${item.member.identification_photo}`"
                                :class="{
                                    selected: form.teachers.includes(item.id),
                                }"
                                @click="toggleTeacherSelection(item.id)"
                            />
                            <span
                                v-show="form.teachers.includes(item.id)"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                            <input type="checkbox" name="teachers[]" value="" />
                        </label>
                    </div>
                </div>
            </div>
        </Form>
    </Dashboard>
</template>
