<script setup>
import { onMounted, ref } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useDominantColor } from '@/Pages/Dashboard/Composables/useDominantColor.js';

const props = defineProps({
    album: Object,
    musicAlbum: Object,
    musics: Object,
});

const { dominantColor, extractDominantColor } = useDominantColor();

onMounted(() => {
    const imageSrc = `/storage/${props.album.cover_image}`;
    extractDominantColor(imageSrc);
});
</script>

<template>
    <Dashboard :page-title="album.title">
        <div id="show-music">
            <Breadcrumb
                :current-page-name="album.title"
                :link-list="[
                    {
                        previous_page_url: 'albums',
                        previous_page_name: 'Álbums',
                    },
                ]"
            />

            <div
                class="card-simple card p-4"
                :style="{ background: `linear-gradient(135deg, ${dominantColor}, #000)` }"
            >
                <div class="row d-flex align-items-center">
                    <div class="col-lg-3">
                        <div class="position-relative d-flex justify-content-center">
                            <img
                                class="rounded-3 shadow"
                                width="200px"
                                :src="`/storage/${album.cover_image}`"
                                alt=""
                            />
                            <div class="position-absolute top-0 end-0 m-3 me-5 me-sm-3">
                                <Link
                                    :href="route('albums.edit', album.id)"
                                    class="btn btn-light border"
                                >
                                    <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-9 mt-3 mt-sm-0">
                        <div class="row">
                            <div class="col">
                                <span class="font-semibold text-white">Álbum</span>
                                <h2 class="font-bold text-white">{{ album.title }}</h2>
                                <div class="d-flex align-items-center flex-wrap">
                                    <span class="d-flex align-items-center">
                                        <Link :href="`/dashboard/artists/${album.artist_id}`">
                                            <LazyImage
                                                width="35px"
                                                :src="`/storage/${album.artist.profile_photo}`"
                                                class="rounded-pill"
                                            />
                                        </Link>
                                        <span class="text-white font-semibold ms-2 mt-2">
                                            {{ album.artist.name }}
                                        </span>
                                    </span>
                                    <Link
                                        v-if="album.release_year"
                                        href="#"
                                        class="btn btn-secondary btn-sm ms-2 font-semibold disabled mt-2"
                                    >
                                        {{ album.release_year }}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card-simple card p-4 mt-4" v-if="musics.length">
                <span class="text-secondary font-semibold fs-12">
                    Músicas disponíveis desse álbum
                </span>
                <div class="search-form-card border-0 shadow-none">
                    <Table
                        table-id="table-musics"
                        :headers="[
                            {
                                key: 'index',
                                label: '#',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            { key: 'name', label: 'Nome' },
                            {
                                key: 'youtube',
                                label: 'Youtube',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                            {
                                key: 'duration',
                                label: 'Duração',
                                thClass: 'text-center',
                                tdClass: 'text-center',
                            },
                        ]"
                        :data="musics"
                        :rowLink="(item) => route('musics.show', item.id)"
                        :pagination="{ reference: 'musics' }"
                    >
                        <template #cell-index="{ index }">
                            {{ index + 1 }}
                        </template>
                        <template #cell-name="{ item }">
                            <div class="d-flex align-items-center">
                                <LazyImage
                                    width="35px"
                                    :src="`/storage/${item.cover_photo}`"
                                    class="rounded-pill"
                                />
                                <span class="ms-3">{{ item.name }}</span>
                            </div>
                        </template>
                        <template #cell-youtube="{ item }">
                            <a
                                target="_blank"
                                class="btn btn-link text-danger p-0 fs-3"
                                :class="{ disabled: !item.youtube_link }"
                                :href="item.youtube_link"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Assistir no Youtube"
                            >
                                <font-awesome-icon :icon="['fab', 'youtube']" />
                            </a>
                        </template>
                        <template #cell-duration="{ item }">
                            {{ item.duration }}
                        </template>
                    </Table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
