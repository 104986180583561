<script setup>
import { watch } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    families: Object,
});

const { searchParams, isLoading, updateLoadingState, executeSearch } = useSearch(
    {
        name: null,
    },
    'families.index'
);

watch(
    () => props.families,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Famílias">
        <Breadcrumb current-page-name="Famílias" />

        <PageHeader title="Famílias" :button-link="route('families.create')" button-text="Nova" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <label for="artistName" class="form-label">Nome da família</label>
                    <input
                        id="artistName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Família Souza"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-families"
                :headers="[
                    { key: 'index', label: '#', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'family_name', label: 'Nome da família' },
                    {
                        key: 'members',
                        label: 'Membros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="families?.data"
                :pagination="{
                    data: families?.meta,
                    reference: 'families',
                }"
                :is-loading="isLoading"
                :rowLink="(item) => route('families.show', item.id)"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-family_name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-members="{ item }">
                    <div class="d-flex justify-content-center" v-if="item.members.length">
                        <Link
                            v-for="(member, index) in item.members"
                            :key="index"
                            :href="`/dashboard/members/${member.id}`"
                        >
                            <LazyImage width="30px" :src="member.identification_photo" />
                        </Link>
                    </div>
                </template>

                <template #cell-moi="{ item }">
                    <div class="text-center">
                        {{ item.church_family_members }}
                        <font-awesome-icon
                            v-if="item.church_family_members === item.members.length"
                            class="ms-2 text-success"
                            :icon="['fas', 'circle-check']"
                        />
                        <font-awesome-icon
                            v-else
                            class="ms-2 text-light"
                            :icon="['fas', 'circle-check']"
                        />
                    </div>
                </template>

                <template #cell-mfc="{ item }">
                    <div class="text-center">
                        {{ item.believing_family_members }}
                        <font-awesome-icon
                            v-if="item.believing_family_members === item.members.length"
                            class="ms-2 text-success"
                            :icon="['fas', 'circle-check']"
                        />
                        <font-awesome-icon
                            v-else
                            class="ms-2 text-light"
                            :icon="['fas', 'circle-check']"
                        />
                    </div>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
