<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';

const { simplifyDate } = useDateFormat();

const props = defineProps({
    children: Object,
});

const form = useForm({
    child_id: null,
    lesson_id: null,
    visitor: 0,
});

const seacher = reactive({
    child_name: null,
});

const search = () => {
    router.visit(route('child-lessons.create'), {
        method: 'get',
        data: { seacher },
        only: ['children'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.child_id = props.children ? form.child_id : null;
            child.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
        child.value = false;
        form.lesson_id = false;
    } else {
        form[field] = id;
        const selectedChild = props.children.find((item) => item.id === id);

        child.value = selectedChild || false;
        form.lesson_id =
            selectedChild.child_class_assignment[0].child_department_class.child_department_lessons[0].id;
    }
};

const store = () => form.post(route('child-lessons.store'));

const child = ref(false);

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(route('child-lessons.create'), {}, { replace: true, preserveState: true });
    }
});
</script>

<template>
    <Dashboard page-title="Recepção Infantil">
        <Breadcrumb
            current-page-name="Recepção Infantil"
            :link-list="[
                {
                    previous_page_url: `child-lessons`,
                    previous_page_name: 'Crianças',
                },
            ]"
        />
        <h4 class="my-3">Recepção Infantil</h4>

        <Form
            button-name="Cadastrar"
            :method="store"
            :processing="form.processing"
            :button-disabled="
                form.processing ||
                !form.child_id ||
                (form.child_id &&
                    !child.child_class_assignment[0].child_department_class.child_department_lessons
                        .length)
            "
        >
            <div class="row g-2">
                <div class="col-md-12">
                    <label for="name" class="form-label">Buscar criança</label>
                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('child_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
            </div>

            <template v-if="children?.length">
                <hr />

                <legend class="m-0 p-0">Criança selecionada</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in children"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.child_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.child_id === item.id"
                            @change="toggleSelection('child_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/children/${item.id}`"
                                    target="_blank"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>

                <div class="p-0" v-if="form.child_id">
                    <div class="card classe-card m-0 border-primary">
                        <div class="card-header p-0">
                            <img
                                :src="`/storage/${child.child_class_assignment[0].child_department_class.banner}`"
                                class="card-img-top"
                                alt=""
                                style="width: 100%; height: 100px; object-fit: cover"
                            />
                        </div>

                        <div class="card-body">
                            <div class="row d-flex justify-content-between align-items-center">
                                <h5 class="font-bold col-sm-6">
                                    Turma
                                    {{
                                        child.child_class_assignment[0].child_department_class.name
                                    }}
                                    <span class="text-secondary">
                                        -
                                        {{
                                            child.child_class_assignment[0].child_department_class
                                                .room.identifier
                                        }}</span
                                    >
                                </h5>
                                <div class="col-sm-6 mt-2 mt-sm-0 text-end">
                                    <a
                                        target="_blank"
                                        :href="`/dashboard/child-department-classes/${child.child_class_assignment[0].child_department_class.id}/lessons/${
                                            child.child_class_assignment[0].child_department_class
                                                .child_department_lessons[0]?.id
                                        }`"
                                        :class="{
                                            disabled:
                                                !child.child_class_assignment[0]
                                                    .child_department_class.child_department_lessons
                                                    .length,
                                        }"
                                        class="btn btn-success btn-add"
                                        >Acessar aula</a
                                    >
                                </div>
                            </div>

                            <template
                                v-if="
                                    child.child_class_assignment[0].child_department_class
                                        .child_department_lessons[0]
                                "
                            >
                                <div>
                                    <template
                                        v-for="(teacher, index) in child.child_class_assignment[0]
                                            .child_department_class.child_department_lessons"
                                        :key="index"
                                    >
                                        <hr />
                                        <div class="row d-flex justify-content-center">
                                            <div
                                                class="col-md-2 col-6 counselors mt-3"
                                                v-for="(
                                                    memberTeacher, key
                                                ) in teacher.child_lesson_member"
                                                :key="key"
                                            >
                                                <Link
                                                    class="d-block text-center"
                                                    :href="`/dashboard/counselors/${memberTeacher.member_child_department.member.id}`"
                                                >
                                                    <img
                                                        class="image-counselor border border-info border-3"
                                                        :src="`${memberTeacher.member_child_department.member.identification_photo}`"
                                                        alt=""
                                                    />
                                                </Link>
                                                <span class="name d-block text-center mt-2">
                                                    {{
                                                        memberTeacher.member_child_department.member
                                                            .clean_name
                                                    }}
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                </div>

                                <hr />

                                <div
                                    class="d-flex position-relative"
                                    v-if="
                                        child.child_class_assignment[0].child_department_class
                                            .child_department_lessons[0]
                                    "
                                >
                                    <img
                                        :src="`/storage/${
                                            child.child_class_assignment[0].child_department_class
                                                .child_department_lessons[0].event.banner
                                        }`"
                                        class="flex-shrink-0 me-3 rounded-3 shadow-sm"
                                        style="height: 125px; width: 108px"
                                    />
                                    <div>
                                        <h5 class="mt-0 font-bold">
                                            {{
                                                child.child_class_assignment[0]
                                                    .child_department_class
                                                    .child_department_lessons[0].event.name
                                            }}:
                                            {{
                                                simplifyDate(
                                                    child.child_class_assignment[0]
                                                        .child_department_class
                                                        .child_department_lessons[0].event
                                                        .start_time
                                                )
                                            }}
                                        </h5>
                                        <p>Este é o evento que está acontecendo nesse momento</p>
                                        <Link
                                            :href="`/dashboard/events/${
                                                child.child_class_assignment[0]
                                                    .child_department_class
                                                    .child_department_lessons[0].event.id
                                            }`"
                                            class="btn btn-success btn-add"
                                            >Acessar evento</Link
                                        >
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 m-0 p-0 mt-4">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.visitor"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                            checked
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            Primeira visita da criança?
                        </label>
                    </div>
                </div>
            </template>
        </Form>
    </Dashboard>
</template>
