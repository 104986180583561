<script setup>
import { Link } from '@inertiajs/vue3';
import Layout from '../Layout.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

const props = defineProps({
    group: Object,
    meetings: Object,
});

import useDateFormat from '@/Pages/Dashboard/Composables/useDateFormat.vue';
const { simplifyDate } = useDateFormat();

// Função que gera a rota para cada linha da tabela
const rowLink = (item) => `/dashboard/group-connections/${props.group.id}/meetings/${item.id}`;

// Define a classe CSS com base na porcentagem de presença
const getAttendanceClass = (attendancePercentage) => {
    if (attendancePercentage > 75) {
        return 'text-success';
    } else if (attendancePercentage > 50) {
        return 'text-primary';
    } else if (attendancePercentage > 40) {
        return 'text-danger';
    } else if (attendancePercentage > 0) {
        return 'text-secondary';
    }
    return '';
};
</script>

<template>
    <Layout :group="group">
        <div
            class="row d-flex justify-content-center px-4 py-3 search-form-card border-0 shadow-none"
        >
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">Encontros desse grupo</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/group-connections/${group.id}/meetings/create`"
                >
                    Novo
                </Link>
            </div>
            <hr />

            <Table
                table-id="table-meetings"
                :headers="[
                    { key: 'index', label: '#' },
                    { key: 'date', label: 'Data do encontro' },
                    {
                        key: 'present',
                        label: 'Presentes',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'visitors',
                        label: 'Visitantes',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'attendance',
                        label: 'Taxa de presença',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="meetings?.data"
                :rowLink="rowLink"
                :pagination="{ reference: 'meetings' }"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-date="{ item }">
                    {{ simplifyDate(item.meeting_date) }}
                </template>

                <template #cell-present="{ item }">
                    <div class="text-center">
                        {{ item.total_participants }}
                    </div>
                </template>

                <template #cell-visitors="{ item }">
                    <div class="text-center">
                        {{ item.total_visitors }}
                    </div>
                </template>

                <template #cell-attendance="{ item }">
                    <div class="text-center">
                        <span
                            :class="getAttendanceClass(item.attendance.attendance_percentage)"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :data-bs-title="`${item.attendance.present_members}/${item.attendance.total_members} participantes presentes`"
                        >
                            {{ item.attendance.attendance_percentage }} %
                        </span>
                    </div>
                </template>
            </Table>
        </div>
    </Layout>
</template>
