<script setup>
import { Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

const props = defineProps({
    group: Object,
    member: Object,
});

const goToMeetingPage = (meetingID) =>
    router.get(`/dashboard/group-connections/${props.group.id}/meetings/${meetingID}`);
</script>

<template>
    <Dashboard :page-title="member.member.clean_name">
        <Breadcrumb
            :current-page-name="member.member.clean_name"
            :link-list="[
                {
                    previous_page_url: `group-connections/${group.id}/members`,
                    previous_page_name: 'Membros',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <Badge :name="member.function" />
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/group-connections/${group.id}/members/edit/${member.id}`"
                    class="btn btn-add btn-primary"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar dados
                </Link>
            </div>
        </div>

        <div class="mb-3 mt-3 mt-sm-0">
            <div id="discipline-details" class="row mb-3 px-3">
                <div class="d-flex justify-content-center">
                    <Link :href="`/dashboard/members/${member.member.id}`">
                        <img
                            class="identification_photo shadow-sm"
                            :src="`${member.member.identification_photo}`"
                            alt=""
                        />
                    </Link>
                </div>
                <h4 class="text-center mt-4">
                    {{ member.member.clean_name }}
                </h4>
            </div>
        </div>

        <p
            class="p-3 bg-success fs-14 bg-opacity-10 rounded-end font-semibold border-success border-start border-5"
        >
            Esse membro tem uma taxa de presença de {{ member.presence_percentage }}% em nossos!
            encontros
        </p>

        <div class="row search-form-card border-0 shadow-none" v-if="member.frequencies.length">
            <hr />

            <div class="table-responsive">
                <table class="table align-middle table-hover" id="table-members">
                    <thead class="table-light">
                        <tr>
                            <th>#</th>
                            <th>Encontro de GC</th>
                            <th class="text-center">Situação</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr
                            v-for="(item, index) in member.frequencies"
                            :key="index"
                            @click="goToMeetingPage(item.meeting_id)"
                        >
                            <td>
                                {{ index + 1 }}
                            </td>
                            <td>
                                {{ item.meeting_date }}
                            </td>
                            <td class="text-center">
                                <Badge :name="item.status" />
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </Dashboard>
</template>
