<script setup>
import { useForm } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Pages/Dashboard/Composables/useImageResizer.js';

const props = defineProps({
    artists: Object,
});

const form = useForm({
    artist_id: null,
    title: null,
    release_date: null,
    cover_image: '/img/avatars/male.png',
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.photo_identification);

const store = () => {
    form.cover_image = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('albums.store'));
};

const toggleArtistSelection = (artistId) => {
    form.artist_id = form.artist_id === artistId ? null : artistId;
};
</script>

<template>
    <Dashboard page-title="Novo álbum">
        <Breadcrumb
            current-page-name="Novo álbum"
            :link-list="[
                {
                    previous_page_url: 'albums',
                    previous_page_name: 'Álbuns',
                },
            ]"
        />
        <div id="create-album">
            <h4 class="my-3">Novo álbum</h4>

            <Form
                button-name="Cadastrar"
                :method="store"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <!-- Preview e edição da Capa do Álbum -->
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Capa do álbum"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#coverUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div class="alert alert-danger mx-2" role="alert" v-if="form.errors.changed_photo">
                    {{ form.errors.changed_photo }}
                </div>

                <!-- Campo para o Título -->
                <div class="col-md-6 mb-3">
                    <label for="title" class="form-label">Título do álbum</label>
                    <input
                        id="title"
                        v-model="form.title"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Nome do álbum"
                        required
                    />
                    <small v-if="form.errors.title" class="text-danger mt-3">
                        {{ form.errors.title }}
                    </small>
                </div>

                <!-- Campo para a Data de Lançamento -->
                <div class="col-md-6 mb-3">
                    <label for="release_date" class="form-label">Data de lançamento</label>
                    <input
                        id="release_date"
                        v-model="form.release_date"
                        type="date"
                        class="form-control"
                        required
                    />
                    <small v-if="form.errors.release_date" class="text-danger mt-3">
                        {{ form.errors.release_date }}
                    </small>
                </div>

                <hr class="mb-0" />

                <legend class="mb-4">Selecione o artista dono do álbum</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in artists"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`/storage/${item.profile_photo}`"
                                :class="{
                                    selected: form.artist_id == item.id,
                                }"
                                @click="toggleArtistSelection(item.id)"
                            />
                            <span
                                v-show="form.artist_id == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>
            </Form>

            <PhotoEditModal
                v-if="form.cover_image"
                title="Adicionar capa"
                id="coverUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
