<script setup>
import { watch, computed } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    waterBaptisms: Object,
});

const {
    searchParams,
    executeSearch,
    compositionSeacher,
    updateLoadingState,
    isLoading,
    buildDownloadUrl,
} = useSearch(
    {
        responsible_id: null,
        identifier: '',
        start_date: '',
        end_date: '',
    },
    'water-baptisms.index'
);

watch(
    () => props.waterBaptisms,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);

const totalParticipants = computed(() => {
    return props.waterBaptisms && props.waterBaptisms.data
        ? props.waterBaptisms.data.reduce((total, item) => total + item.total_members, 0)
        : 0;
});
</script>

<template>
    <Dashboard page-title="Turmas de batismo">
        <Breadcrumb current-page-name="Turmas de batismo" />

        <PageHeader
            title="Turmas de batismo"
            :button-link="route('water-baptisms.create')"
            button-text="Nova"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar dados</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('water-baptisms', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('water-baptisms', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row g-3">
                <div class="col-md-6">
                    <label class="form-label" for="floatingInput">Nome de identificação</label>
                    <input
                        id="floatingInput"
                        type="email"
                        class="form-control"
                        placeholder="Culto de celebração"
                        @input="(event) => compositionSeacher('identifier', event, executeSearch)"
                    />
                </div>
                <div class="col-6 col-sm-3">
                    <label for="startDate" class="form-label">Data de início</label>
                    <input
                        id="startDate"
                        v-model="searchParams.start_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>
                <div class="col-6 col-sm-3">
                    <label for="endDate" class="form-label">Data de fim</label>
                    <input
                        id="endDate"
                        v-model="searchParams.end_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch"
                    />
                </div>
            </div>

            <Table
                table-id="table-members"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'identifier', label: 'Nome' },
                    {
                        key: 'open',
                        label: 'Aberta',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_members',
                        label: 'Inscritos',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'completion_date',
                        label: 'Realização',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="waterBaptisms?.data"
                :pagination="{ data: waterBaptisms?.meta, reference: 'waterBaptisms' }"
                :rowLink="(item) => route('water-baptisms.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-identifier="{ item }">
                    {{ item.identifier }}
                </template>

                <template #cell-open="{ item }">
                    <Badge :name="item.open ? 'YES' : 'NOT'" />
                </template>
            </Table>

            <p class="text-center font-bold fs-12">Soma geral: {{ totalParticipants }} inscritos</p>
        </div>
    </Dashboard>
</template>
