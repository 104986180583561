<script setup>
import { reactive, onMounted } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import PhotoEditModal from '@/Pages/Dashboard/Shared/PhotoEditModal.vue';

import { useImageResizer } from '@/Pages/Dashboard/Composables/useImageResizer.js';

const props = defineProps({
    child: Object,
    sexs: Object,
    families: Object,
    types: Object,
});

const form = useForm({
    _method: 'put',
    name: props.child.name,
    photo_identification: props.child.photo_identification,
    sex: props.child.sex,
    birth_date: props.child.birth_date,
    family: props.child.family_id,
    observations: props.child.observations,
    type: props.child.type,
    intolerant_foods: props.child.intolerant_foods,
    special_needs: props.child.special_needs,
    changed_photo: false,
});

const { photo, changedPhoto, handleImageResized } = useImageResizer(form.photo_identification);

const update = () => {
    form.photo_identification = photo.value;
    form.changed_photo = changedPhoto.value;
    form.post(route('children.update', props.child.id));
};

const search = () => {
    router.visit(route('children.edit', props.child.id), {
        method: 'get',
        data: { seacher },
        only: ['families'],
        preserveState: true,
        preserveScroll: true,
    });
};

const seacher = reactive({
    family_name: null,
});

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelectionFamily = (id) => {
    if (form.family === id) {
        form.family = null;
    } else {
        form.family = id;
    }
};

onMounted(() => {
    const url = new URL(window.location.href);
    if (url.search) {
        router.get(
            route('children.edit', props.child.id),
            {},
            { replace: true, preserveState: true }
        );
    }

    seacher.family_name = props.child.family.name;
    search();
});
</script>

<template>
    <Dashboard page-title="Editar criança">
        <Breadcrumb
            current-page-name="Editar criança"
            :link-list="[
                {
                    previous_page_url: 'children',
                    previous_page_name: 'Crianças',
                },
            ]"
        />

        <div id="create-minister">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="route('children.show', child.id)"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="d-flex justify-content-center">
                    <div class="position-relative">
                        <img
                            :src="photo"
                            alt="Identification Photo"
                            class="upload-box img-fluid shadow-sm"
                        />
                        <div class="position-absolute bottom-0 end-0 m-3">
                            <a
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target="#photoUpdateModal"
                                class="btn btn-edit shadow"
                            >
                                <font-awesome-icon
                                    class="text-white"
                                    :icon="['fas', 'pen-to-square']"
                                />
                            </a>
                        </div>
                    </div>
                </div>

                <div
                    class="alert alert-danger mx-2"
                    role="alert"
                    v-if="form.errors.photo_identification"
                >
                    {{ form.errors.photo_identification }}
                </div>

                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome do Criança </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Laura Vitória"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="birth_date" class="form-label">Data nascimento</label>
                    <input
                        type="date"
                        v-model="form.birth_date"
                        class="form-control"
                        name="birth_date"
                        id="birth_date"
                        aria-describedby="birth_dateHelp"
                    />
                    <small v-if="form.errors.birth_date" class="text-danger mt-3">
                        {{ form.errors.birth_date }}
                    </small>
                </div>

                <div class="col-md-6">
                    <label for="sex" class="form-label">Sexo</label>
                    <select
                        id="sex"
                        v-model="form.sex"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-6">
                    <label for="type" class="form-label">Tipo de criança</label>
                    <select
                        id="type"
                        v-model="form.type"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in types" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="observations" class="form-label"> Observações Gerais</label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="observations"
                        v-model="form.observations"
                        type="text"
                        class="form-control"
                        name="observations"
                        placeholder="Forneça alguma observação"
                        rows="5"
                    />

                    <small v-if="form.errors.observations" class="text-danger mt-3">
                        {{ form.errors.observations }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="intolerant_foods" class="form-label">
                        Alimentos de intolerância
                    </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="intolerant_foods"
                        v-model="form.intolerant_foods"
                        type="text"
                        class="form-control"
                        name="intolerant_foods"
                    />

                    <small v-if="form.errors.intolerant_foods" class="text-danger mt-3">
                        {{ form.errors.intolerant_foods }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="special_needs" class="form-label"> Necessidades especiais </label>
                    <small class="optional ms-2">(opcional)</small>

                    <input
                        id="special_needs"
                        v-model="form.special_needs"
                        type="text"
                        class="form-control"
                        name="special_needs"
                    />

                    <small v-if="form.errors.special_needs" class="text-danger mt-3">
                        {{ form.errors.special_needs }}
                    </small>
                </div>

                <input type="file" ref="inputRef" class="file-input d-none" />

                <hr class="mb-0" />

                <div class="col-md-12 mb-3">
                    <label for="name" class="form-label">Nome da família</label>
                    <input
                        id="name"
                        v-model="seacher.family_name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        @input="compositionSeacher('family_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <ul class="list-group member-found mt-2 border-0" v-if="families.length">
                    <li
                        v-for="(item, index) in families"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.family === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.family === item.id"
                            @change="toggleSelectionFamily(item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="route('families.show', item.id)"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    target="_blank"
                                    >Acessar família</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </Form>
            <PhotoEditModal
                v-if="form.photo_identification"
                title="Adicionar foto"
                id="photoUpdateModal"
                @image-resized="handleImageResized"
            />
        </div>
    </Dashboard>
</template>
