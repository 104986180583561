<script setup>
import { defineProps, watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    hospitalVerbMembers: Object,
});

const { searchParams, executeSearch, isLoading, updateLoadingState } = useSearch(
    { name: '' },
    'members.hospitals.index'
);

watch(
    () => props.hospitalVerbMembers,
    (newVal) => updateLoadingState(newVal),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Membros">
        <Breadcrumb current-page-name="Membros" />

        <PageHeader
            title="Membros"
            :button-link="route('members.hospitals.create')"
            button-text="Novo"
        />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center">
                <div class="row align-items-center">
                    <div class="col-md-12">
                        <label for="artistName" class="form-label">Nome</label>
                        <input
                            id="artistName"
                            v-model="searchParams.name"
                            type="text"
                            class="form-control"
                            placeholder="Márcio Reis"
                            @input="executeSearch()"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-hospital-verb-members"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'name', label: 'Nome do membro' },
                    {
                        key: 'created_at',
                        label: 'Data de cadastro',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="hospitalVerbMembers?.data"
                :pagination="{
                    data: hospitalVerbMembers?.meta,
                    reference: 'hospitalVerbMembers',
                }"
                :rowLink="(item) => `/dashboard/hospital-verb-members/${item.id}`"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>
                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="`/storage/${item.identification_photo}`" />
                        <span class="ms-3">{{ item.name }}</span>
                    </div>
                </template>
                <template #cell-created_at="{ item }">
                    {{ item.created_at }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
