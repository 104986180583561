<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    children: Object,
    sexs: Object,
    types: Object,
});

const {
    searchParams,
    executeSearch,
    compositionSeacher,
    updateLoadingState,
    buildDownloadUrl,
    isLoading,
} = useSearch(
    {
        name: '',
        type: 0,
        sex: 0,
    },
    'children.index'
);

watch(
    () => props.children,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Crianças">
        <Breadcrumb current-page-name="Crianças" />

        <PageHeader title="Crianças" :button-link="route('children.create')" button-text="Nova" />

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar crianças</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('children', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('children', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr class="mx-3" />

            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-6">
                        <label class="form-label" for="name">Nome da criança</label>
                        <input
                            id="name"
                            type="text"
                            class="form-control"
                            placeholder="Laura Vitória"
                            @input="(event) => compositionSeacher('name', event, executeSearch)"
                        />
                    </div>

                    <div class="col-6 col-md-3">
                        <label for="sex" class="form-label">Sexo</label>
                        <select
                            id="sex"
                            v-model="searchParams.sex"
                            class="form-select"
                            @change="executeSearch"
                        >
                            <option value="0">Todos os sexos</option>
                            <option
                                v-for="item in props.sexs"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-6 col-md-3">
                        <label for="type" class="form-label">Tipo de criança</label>
                        <select
                            id="type"
                            v-model="searchParams.type"
                            class="form-select"
                            @change="executeSearch"
                        >
                            <option value="0">Todos os tipos</option>
                            <option
                                v-for="item in props.types"
                                :key="item.value"
                                :value="item.value"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row px-4 mt-3">
                <Table
                    table-id="table-children"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center fw-bold',
                        },
                        {
                            key: 'name',
                            label: 'Nome da criança',
                            thClass: 'text-start',
                            tdClass: 'text-start',
                        },
                        {
                            key: 'type',
                            label: 'Tipo',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'age',
                            label: 'Idade',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'parents',
                            label: 'Parentes',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="props.children?.data"
                    :pagination="{ data: props.children?.meta, reference: 'children' }"
                    :rowLink="(item) => route('children.show', item.id)"
                    :is-loading="isLoading"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-name="{ item }">
                        <div class="d-flex align-items-center">
                            <LazyImage :src="item.photo_identification" width="30px" />
                            <span class="ms-3">{{ item.name }}</span>
                        </div>
                    </template>

                    <template #cell-type="{ item }">
                        <Badge :name="item.type" />
                    </template>

                    <template #cell-age="{ item }">
                        {{ item.age }}
                    </template>

                    <template #cell-parents="{ item }">
                        <template v-if="item.family?.parents && item.family.parents.length">
                            <div class="d-flex justify-content-center align-items-center">
                                <div
                                    class="ms-2"
                                    v-for="(parent, idx) in item.family.parents"
                                    :key="idx"
                                >
                                    <LazyImage :src="parent.profile_photo_url" width="30px" />
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <font-awesome-icon
                                :icon="['fas', 'minus']"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                data-bs-title="Não vinculado a nenhuma família"
                            />
                        </template>
                    </template>
                </Table>
            </div>
        </div>
    </Dashboard>
</template>
