<script setup>
import { watch, computed } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    census: Object,
    schedules: Object,
});

const { searchParams, isLoading, updateLoadingState, executeSearch, buildDownloadUrl } = useSearch(
    {
        schedule_id: 0,
        start_date: '',
        end_date: '',
    },
    'census.index'
);

const averagePeoplePerEvent = computed(() => {
    if (!props.census?.data.length) {
        return 0;
    }
    const totalPeople = props.census?.data.reduce((sum, censu) => sum + censu.total_people, 0);

    return Math.round(totalPeople / props.census?.data.length);
});

watch(
    () => props.census,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Censos">
        <Breadcrumb current-page-name="Censos" />

        <PageHeader title="Censos" :button-link="route('census.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar censos</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="buildDownloadUrl('census', 'pdf')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="buildDownloadUrl('census', 'excel')"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row g-3">
                <div class="col-sm-4">
                    <label for="schedule_id" class="form-label">Programação</label>
                    <select
                        id="schedule_id"
                        v-model="searchParams.schedule_id"
                        class="form-select"
                        required
                        @change="executeSearch()"
                    >
                        <option value="0">Todas as programações</option>
                        <option v-for="(item, index) in schedules" :key="index" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-6 col-sm-4">
                    <label for="startDate" class="form-label">Data de início</label>
                    <input
                        id="startDate"
                        v-model="searchParams.start_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch()"
                    />
                </div>
                <div class="col-6 col-sm-4">
                    <label for="endDate" class="form-label">Data de fim</label>
                    <input
                        id="endDate"
                        v-model="searchParams.end_date"
                        type="date"
                        class="form-control"
                        @change="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-census"
                :headers="[
                    { key: 'index', label: 'ID', thClass: 'text-center', tdClass: 'text-center' },
                    {
                        key: 'schedule',
                        label: 'Programação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'event',
                        label: 'Evento',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'total_people',
                        label: 'Presentes',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'number_visitors',
                        label: 'Visitantes',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'created_at',
                        label: 'Data',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="census?.data"
                :pagination="{
                    data: census?.meta,
                    reference: 'census',
                }"
                :rowLink="(item) => route('census.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-schedule="{ item }">
                    {{ item.identification }}
                </template>

                <template #cell-event="{ item }">
                    <div class="d-flex justify-content-center">
                        <Link v-if="item.event_id" :href="route('events.show', item.event_id)">
                            <LazyImage width="35px" :src="`/storage/${item.event_banner}`" />
                        </Link>
                        <span
                            v-else
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            data-bs-title="Não vinculado a evento"
                        >
                            <font-awesome-icon :icon="['fas', 'minus']" />
                        </span>
                    </div>
                </template>
            </Table>

            <p class="text-center font-bold fs-12">
                Média de {{ averagePeoplePerEvent }} pessoas por culto
            </p>
        </div>
    </Dashboard>
</template>
