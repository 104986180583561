<template>
    <WhenVisible :data="pagination.reference">
        <template #fallback>
            <Loader />
        </template>

        <div class="row p-3">
            <div class="table-responsive-sm p-0">
                <table
                    v-if="!isLoading"
                    class="table m-0 align-middle table-hover table1"
                    :id="tableId"
                >
                    <thead class="table-light">
                        <tr>
                            <th
                                v-for="(header, index) in headers"
                                :key="index"
                                :class="header.thClass"
                                scope="col"
                            >
                                {{ header.label }}
                            </th>
                        </tr>
                    </thead>

                    <tbody v-if="data?.length">
                        <tr
                            v-for="(item, index) in data"
                            :key="index"
                            :class="
                                typeof rowClass === 'function' ? rowClass(item, index) : rowClass
                            "
                            @click="handleRowClick(item)"
                            style="cursor: pointer"
                        >
                            <td
                                v-for="(header, idx) in headers"
                                :key="idx"
                                :class="header.tdClass || ''"
                            >
                                <slot :name="`cell-${header.key}`" :item="item" :index="index">
                                    {{ header.key === 'index' ? index + 1 : item[header.key] }}
                                </slot>
                            </td>
                        </tr>
                    </tbody>

                    <tfoot v-if="pagination?.data" class="bg-white">
                        <tr>
                            <td :colspan="headers.length">
                                <Pagination
                                    :data="pagination.data"
                                    :referenceDate="pagination.reference"
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>

                <div v-if="isLoading" class="d-flex justify-content-center p-4">
                    <Loader />
                </div>
            </div>
        </div>
    </WhenVisible>
</template>

<script setup>
import { defineProps } from 'vue';
import { router, WhenVisible } from '@inertiajs/vue3';
import Pagination from './Pagination.vue';
import Loader from '@/Pages/Dashboard/Components/ui/Loader.vue';

const props = defineProps({
    tableId: { type: String, default: 'custom-table' },
    headers: { type: Array, required: true },
    data: { type: [Array, Object], required: false },
    pagination: { type: Object, default: null },
    rowClass: { type: [String, Function], default: '' },
    rowLink: { type: [String, Function], default: null },
    isLoading: { type: Boolean, default: false }, // Recebe o estado de carregamento
});

const handleRowClick = (item) => {
    if (props.rowLink) {
        const link = typeof props.rowLink === 'function' ? props.rowLink(item) : props.rowLink;
        router.get(link);
    }
};
</script>
