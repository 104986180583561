<script setup>
import { ref, onMounted, computed } from 'vue';
import { useForm, Link, router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    group: Object,
    meeting: Object,
    members: Object,
    hosts: Object,
});

const frequencyMembers = ref(props.members);

const form = useForm({
    meeting_date: props.meeting.meeting_date_gross,
    place_held: props.meeting.place_held,
    host: props.meeting.host_id,
    total_visitors: props.meeting.total_visitors,
    total_salvation: props.meeting.total_salvation,
    total_participants: props.meeting.total_participants,
    active: props.meeting.active ? true : false,
});

const toggleHostSelection = (hostId) => {
    form.host = form.host === hostId ? null : hostId;
};

const transformMembers = (members) => {
    return members.map((item) => ({
        id: item.id,
        status: item.status,
    }));
};

const update = () =>
    form
        .transform((data) => ({
            ...data,
            frequencies: transformMembers(frequencyMembers.value),
        }))
        .put(`/dashboard/group-connections/${props.group.id}/meetings/${props.meeting.id}`);

const meetingEditModalCloseButton = ref(null);

const destroy = () => {
    meetingEditModalCloseButton.value.click();
    router.delete(`/dashboard/group-connections/${props.group.id}/meetings/${props.meeting.id}`);
};
</script>

<template>
    <Dashboard :page-title="group.name">
        <Breadcrumb
            :current-page-name="group.name"
            :link-list="[
                {
                    previous_page_url: `group-connections/${group.id}/meetings`,
                    previous_page_name: 'Encontros',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-md-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                <Link
                    :href="`/dashboard/group-connections/${group.id}/meetings/${meeting.id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#meetingEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Editar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="col-md-12">
                <label for="meeting_date" class="form-label"> Horário do encontro </label>

                <input
                    id="meeting_date"
                    v-model="form.meeting_date"
                    type="datetime-local"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Turma Maio 2025"
                    required
                />
                <small v-if="form.errors.meeting_date" class="text-danger mt-3">
                    {{ form.errors.meeting_date }}
                </small>
            </div>

            <div class="col-md-12 mt-2">
                <label for="place_held" class="form-label"> Endereço do encontro </label>

                <input
                    id="place_held"
                    v-model="form.place_held"
                    type="text"
                    autocomplete="off"
                    class="form-control"
                    placeholder="Rua São Jorge n 99 - Próximo a Saúde Cia"
                />
                <small v-if="form.errors.place_held" class="text-danger mt-3">
                    {{ form.errors.place_held }}
                </small>
            </div>

            <div class="col-md-4 mt-2">
                <label for="total_participants" class="form-label"> Total presentes </label>

                <input
                    id="total_participants"
                    v-model="form.total_participants"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.total_participants" class="text-danger mt-3">
                    {{ form.errors.total_participants }}
                </small>
            </div>

            <div class="col-md-4 mt-2">
                <label for="total_visitors" class="form-label"> Total visitantes </label>

                <input
                    id="total_visitors"
                    v-model="form.total_visitors"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.total_visitors" class="text-danger mt-3">
                    {{ form.errors.total_visitors }}
                </small>
            </div>

            <div class="col-md-4 mt-2">
                <label for="total_salvation" class="form-label"> Total salvações </label>

                <input
                    id="total_salvation"
                    v-model="form.total_salvation"
                    type="number"
                    autocomplete="off"
                    class="form-control"
                />
                <small v-if="form.errors.total_salvation" class="text-danger mt-3">
                    {{ form.errors.total_salvation }}
                </small>
            </div>

            <div class="col-md-12">
                <div class="form-check d-flex align-items-center form-switch">
                    <input
                        id="flexCheckDefault"
                        v-model="form.active"
                        class="form-check-input"
                        type="checkbox"
                        role="switch"
                    />
                    <label class="form-check-label ms-3" for="flexCheckDefault">
                        Encontro ativo?
                    </label>
                </div>
            </div>

            <template v-if="hosts.length">
                <hr class="mb-0" />

                <legend class="mb-4">Selecione o anfitrião desse encontro</legend>

                <div class="row">
                    <div
                        v-for="(item, index) in hosts"
                        :key="index"
                        class="col-md-2 col-6 mb-3 mb-sm-0 text-center"
                    >
                        <label class="image-checkbox position-relative">
                            <img
                                class="img-responsive"
                                :src="`${item.member.identification_photo}`"
                                :class="{
                                    selected: form.host == item.id,
                                }"
                                @click="toggleHostSelection(item.id)"
                            />
                            <span
                                v-show="form.host == item.id"
                                class="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-primary p-2"
                            >
                                <font-awesome-icon :icon="['fas', 'circle-check']" />
                            </span>
                            <span class="mt-3 d-block">{{ item.member.clean_name }}</span>
                            <input type="checkbox" name="image[]" value="" />
                        </label>
                    </div>
                </div>
            </template>

            <hr class="mb-0" />

            <div class="col-12" v-if="frequencyMembers.length">
                <legend class="mx-0 px-0">Frequência dos membros</legend>

                <div class="list-group frequency-presence-members">
                    <div
                        v-for="(item, index) in frequencyMembers"
                        :key="index"
                        class="list-group-item row bg-light font-semibold fs-12 p-3 mb-2 border rounded-3 d-flex justify-content-between align-items-center"
                    >
                        <div class="col-12 col-sm-8">
                            <img
                                class="me-2 rounded-circle"
                                width="30px"
                                :src="`${item.member.identification_photo}`"
                                alt=""
                            />
                            {{ item.member.clean_name }}
                        </div>
                        <div class="col-12 col-sm-4 mt-3 mt-sm-0 text-end">
                            <input
                                type="radio"
                                class="btn-check"
                                :name="`options-base-${item.id}`"
                                :id="`options-base-${item.id}-present`"
                                autocomplete="off"
                                v-model="item.status"
                                value="PRESENT"
                            />

                            <label
                                class="btn fs-10 btn-outline-success me-2"
                                :for="`options-base-${item.id}-present`"
                            >
                                Presente
                            </label>

                            <input
                                type="radio"
                                class="btn-check"
                                :name="`options-base-${item.id}`"
                                :id="`options-base-${item.id}-online`"
                                autocomplete="off"
                                v-model="item.status"
                                value="ABSENT"
                            />
                            <label
                                class="btn fs-10 btn-outline-danger me-2"
                                :for="`options-base-${item.id}-online`"
                            >
                                Ausente
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <ConfirmationModal
                modal_id="meetingEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="meetingEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </Form>
    </Dashboard>
</template>
