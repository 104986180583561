<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    evangelizations: Object,
});

const { searchParams, executeSearch, isLoading, updateLoadingState } = useSearch(
    { name: '' },
    'evangelizations.index'
);

watch(
    () => props.evangelizations,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Evangelizações">
        <Breadcrumb current-page-name="Evangelizações" />

        <PageHeader
            title="Evangelizações"
            :button-link="route('evangelizations.create')"
            button-text="Nova"
        />

        <div class="p-3 search-form-card">
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-12">
                        <label class="form-label" for="floatingInput">Nome do evento</label>
                        <input
                            id="floatingInput"
                            type="text"
                            class="form-control"
                            placeholder="Aviva Ilha"
                            v-model="searchParams.name"
                            @input="executeSearch"
                        />
                    </div>
                </div>
            </div>

            <Table
                table-id="table-evangelizations"
                :headers="[
                    {
                        key: 'id',
                        label: 'ID',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'name',
                        label: 'Nome do evento',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'status',
                        label: 'Status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'reach',
                        label: 'Alcance',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'date',
                        label: 'Data',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="evangelizations?.data"
                :pagination="{ data: evangelizations?.meta, reference: 'evangelizations' }"
                :rowLink="(item) => route('evangelizations.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-id="{ item }">
                    {{ item.id }}
                </template>

                <template #cell-name="{ item }">
                    {{ item.name }}
                </template>

                <template #cell-status="{ item }">
                    <Badge :name="item.status ? 'ACTIVATE' : 'CLOSED'" />
                </template>

                <template #cell-reach="{ item }">
                    {{ item.evangelized_people?.length || 0 }} pessoas
                </template>

                <template #cell-date="{ item }">
                    {{ item.date_completion }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
