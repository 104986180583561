<script setup>
import { Link } from '@inertiajs/vue3';
import Layout from '../Layout.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

const props = defineProps({
    group: Object,
    members: Object,
});

const rowLink = (item) => `/dashboard/group-connections/${props.group.id}/members/${item.id}`;

const statusFrequency = (status) => {
    const style = {
        PENDING: 'Não informado',
        PRESENT: 'Presente',
        ABSENT: 'Ausente',
    };
    return style[status];
};

const formatStatusIcon = (status) => {
    const style = {
        PENDING: { color: 'text-body-tertiary' },
        PRESENT: { color: 'text-success' },
        ABSENT: { color: 'text-danger text-opacity-50' },
    };
    return style[status]?.color || 'text-success';
};
</script>

<template>
    <Layout :group="group">
        <div
            class="row d-flex justify-content-center px-4 py-3 search-form-card border-0 shadow-none"
        >
            <div class="d-flex justify-content-between mb-2 align-items-center">
                <h5 class="font-bold mb-0">{{ members?.data.length }} membros nesse grupo</h5>
                <Link
                    class="btn btn-primary btn-redirection"
                    :href="`/dashboard/group-connections/${group.id}/members/create`"
                >
                    Novo
                </Link>
            </div>
            <hr />

            <Table
                table-id="table-members"
                :headers="[
                    { key: 'index', label: '#' },
                    { key: 'name', label: 'Nome do membro' },
                    {
                        key: 'function',
                        label: 'Função',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'frequencies',
                        label: 'Últimos encontros',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'presence',
                        label: 'Presença',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="members?.data"
                :rowLink="rowLink"
                :pagination="{
                    reference: 'members',
                }"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="item.member.identification_photo" />
                        <span class="ms-3">{{ item.member.clean_name }}</span>
                    </div>
                </template>

                <template #cell-function="{ item }">
                    <Badge :name="item.function" />
                </template>

                <template #cell-frequencies="{ item }">
                    <div v-if="item.frequencies.length">
                        <font-awesome-icon
                            v-for="(frequency, i) in item.frequencies.slice(0, 3)"
                            :key="i"
                            :class="`fs-4 me-2 ${formatStatusIcon(item.status)}`"
                            :icon="['fas', 'circle-check']"
                            data-bs-toggle="tooltip"
                            data-bs-placement="top"
                            :data-bs-title="statusFrequency(item.status)"
                        />
                    </div>
                    <div v-else>Pendente</div>
                </template>

                <template #cell-presence="{ item }"> {{ item.presence_percentage }} % </template>
            </Table>
        </div>
    </Layout>
</template>
