<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Pagination from '@/Pages/Dashboard/Shared/Pagination.vue';

const props = defineProps({
    scales: Object,
    departments: Object,
});

const searchForm = reactive({
    department_id: 0,
    start_date: '',
    end_date: '',
});

const seacher = () =>
    router.get(`/dashboard/departments/scales`, searchForm, { preserveState: true });

const goToScale = (departmentID, scaleID) =>
    router.get(`/dashboard/departments/${departmentID}/scales/${scaleID}`);
</script>

<template>
    <Dashboard page-title="Escalas Gerais">
        <Breadcrumb current-page-name="Escalas Gerais" />
        <div class="row mt-3 mb-4">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Escalas Gerais</h4>
            </div>
        </div>

        <div class="p-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2 px-3">
                <div class="font-bold col-12 col-sm-6">Baixar escalas</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/departments/scales/export?department_id=${searchForm.department_id}&start_date=${searchForm.start_date}&end_date=${searchForm.end_date}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                </div>
            </div>
            <hr />
            <div class="px-3">
                <div class="row g-3">
                    <div class="col-md-4">
                        <label for="floatingSelect" class="bg-transparent form-label"
                            >Selecionar departmento</label
                        >
                        <select
                            id="department_id"
                            v-model="searchForm.department_id"
                            class="form-select"
                            required
                            @change="seacher()"
                        >
                            <option value="0">Todos</option>
                            <option
                                v-for="(item, index) in departments"
                                :key="index"
                                :value="item.id"
                            >
                                {{ item.name }}
                            </option>
                        </select>
                    </div>

                    <div class="col-6 col-sm-4">
                        <label for="startDate" class="form-label">Data de início</label>
                        <input
                            id="startDate"
                            v-model="searchForm.start_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                    <div class="col-6 col-sm-4">
                        <label for="endDate" class="form-label">Data de fim</label>
                        <input
                            id="endDate"
                            v-model="searchForm.end_date"
                            type="date"
                            class="form-control"
                            name=""
                            @change="seacher()"
                        />
                    </div>
                </div>
            </div>

            <div class="row mt-3 px-3">
                <div class="table-responsive">
                    <table class="table align-middle table-hover" id="table-scale-general">
                        <thead class="table-light">
                            <tr>
                                <th>#</th>
                                <th>Departamento</th>
                                <th class="text-center">Referente</th>
                                <th class="text-center">Data de início</th>
                                <th class="text-center">Data de fim</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr
                                v-for="(item, index) in scales.data"
                                :key="index"
                                @click="goToScale(item.department_id, item.id)"
                            >
                                <td>
                                    {{ index + 1 }}
                                </td>
                                <td>
                                    {{ item.department.name }}
                                </td>
                                <td class="text-center">
                                    {{ item.month_year }}
                                </td>
                                <td class="text-center">
                                    {{ item.start_date }}
                                </td>
                                <td class="text-center">
                                    {{ item.end_date }}
                                </td>
                            </tr>
                        </tbody>

                        <tfoot class="bg-white">
                            <tr>
                                <td colspan="6">
                                    <Pagination
                                        :data="scales.meta"
                                        reference-date="scales"
                                        identifications="Escalas"
                                    />
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    </Dashboard>
</template>
