<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    roles: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        display_name: null,
    },
    'roles.index'
);

watch(
    () => props.roles,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Funções">
        <Breadcrumb current-page-name="Funções" />

        <PageHeader title="Funções" :button-link="route('roles.create')" button-text="Nova" />

        <div class="py-3 px-4 search-form-card">
            <div class="row g-3">
                <div class="col-md-12">
                    <label class="form-label" for="floatingInput">Nome da função</label>
                    <input
                        id="floatingInput"
                        type="text"
                        class="form-control"
                        placeholder="Administrador"
                        v-model="searchParams.display_name"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-roles"
                :headers="[
                    {
                        key: 'id',
                        label: 'ID',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'name',
                        label: 'Prefixo',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'display_name',
                        label: 'Nome da função',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="roles?.data"
                :pagination="{
                    data: roles?.meta,
                    reference: 'roles',
                }"
                :rowLink="(item) => route('roles.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-id="{ index }">
                    {{ index + 1 }}
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
