<script setup>
import { defineProps } from 'vue';
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

const props = defineProps({
    event: Object,
    member: Object,
    creatorRegistration: Object,
});

import usePriceFormatter from '@/Pages/Dashboard/Composables/usePriceFormatter.vue';

const { convertToCurrencyFormat } = usePriceFormatter();
</script>

<template>
    <Dashboard page-title="Inscrição">
        <Breadcrumb
            current-page-name="Inscrição"
            :link-list="[
                {
                    previous_page_url: `events/${event.id}`,
                    previous_page_name: `${event.name}`,
                },
            ]"
        />

        <div class="row mt-3 mb-3">
            <div class="col d-flex justify-content-between align-items-center">
                <h4 class="mb-0">Inscrição</h4>
                <Link
                    :href="`/dashboard/events/${event.id}/members/edit/${member.id}`"
                    class="btn btn-primary btn-add"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                    Editar
                </Link>
            </div>
        </div>
        <div class="card-simple p-4">
            <div class="row g-0">
                <div class="col-md-3 d-flex align-items-center justify-content-center">
                    <img
                        width="100"
                        :src="member.identification_photo"
                        class="img-fluid rounded-circle"
                        alt=""
                    />
                </div>
                <div class="col-md-9 d-flex align-items-center justify-content-center">
                    <div class="card-body">
                        <h5 v-if="member.member_id" class="card-title mt-3 mt-sm-0">
                            <Link :href="`/dashboard/members/${member.member_id}`">{{
                                member.name
                            }}</Link>
                        </h5>
                        <h5 class="card-title mt-3 mt-sm-0" v-else>
                            {{ member.name }}
                        </h5>
                        <p class="card-text mt-3">
                            <Badge :name="`${convertToCurrencyFormat(member.amount_paid)}`" /> -
                            Inscrição feita em {{ member.created_at }} utilizando o método
                            <Badge :name="member.payment_method" />
                        </p>
                    </div>
                </div>
            </div>

            <template v-if="member.married && member.married_id">
                <hr />
                <p class="mt-3 p-3 bg-primary bg-opacity-10 border border-primary my-3">
                    Essa inscrição foi feita como uma casadinha. Ela estar conectada com a seguinte
                    <Link
                        :href="`/dashboard/events/${member.event_id}/members/${member.married_id}`"
                        >inscrição</Link
                    >.
                </p>
            </template>

            <template v-if="member.responsible">
                <hr />
                <p class="mt-3 p-3 bg-success bg-opacity-10 border border-success my-3">
                    Esse inscrito do evento é menor de idade, dessa forma ele tem como responsável
                    <span v-if="member.responsible_id">
                        <Link :href="`/dashboard/members/${member.responsible_id}`"
                            >{{ member.responsible_name }}.</Link
                        >
                    </span>
                    <span v-else> {{ member.responsible_name }}. </span>
                </p>
            </template>

            <hr />

            <CreatorRecord
                v-if="creatorRegistration.name"
                :data="creatorRegistration"
                title="Inscrição realizada por: "
            />
        </div>
    </Dashboard>
</template>
