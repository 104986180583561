<script setup>
import { onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';
import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

import usePreviewImage from '@/Pages/Dashboard/Composables/usePreviewImage.vue';

const { previewImage, imageUrl } = usePreviewImage();

const props = defineProps({
    classe: Object,
    rooms: Object,
});

const form = useForm({
    _method: 'PUT',
    name: props.classe.name,
    room_id: props.classe.room_id,
    description: props.classe.description,
    banner: props.classe.banner,
});

const update = () => form.post(`/dashboard/child-department-classes/${props.classe.id}`);

onMounted(() => {
    imageUrl.value = `/storage/${props.classe.banner}`;
});

const classEditModalCloseButton = ref(null);

const destroy = () => {
    classEditModalCloseButton.value.click();
    router.delete(`/dashboard/child-department-classes/${props.classe.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar turma">
        <Breadcrumb
            current-page-name="Editar turma"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${classe.id}`,
                    previous_page_name: `${classe.name}`,
                },
            ]"
        />
        <div id="create-minister">
            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">Editar dados</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/child-department-classes/${classe.id}`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                        Visualizar registro
                    </Link>
                    <a
                        data-bs-toggle="modal"
                        data-bs-target="#classEditModal"
                        href="#"
                        class="btn btn-danger btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                        Deletar
                    </a>
                </div>
            </div>

            <Form
                button-name="Editar dados"
                :method="update"
                :processing="form.processing"
                :button-disabled="form.processing"
            >
                <div class="col-md-8">
                    <label for="name" class="form-label"> Nome do turma </label>

                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="Super Igreja"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>

                <div class="col-md-4">
                    <label for="room" class="form-label">Sala</label>
                    <select
                        id="room"
                        v-model="form.room_id"
                        class="form-select form-select"
                        aria-label="Small select example"
                    >
                        <option v-for="(item, index) in rooms" :key="index" :value="item.id">
                            {{ item.identifier }}
                        </option>
                    </select>
                </div>

                <div class="col-md-12">
                    <label for="description" class="form-label"> Descrição </label>
                    <small class="optional ms-2">(opcional)</small>

                    <textarea
                        id="description"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        name="description"
                        placeholder="Forneça alguma descrição para essa turma."
                        rows="5"
                    />

                    <small v-if="form.errors.description" class="text-danger mt-3">
                        {{ form.errors.description }}
                    </small>
                </div>

                <div class="col-md-12">
                    <label for="formFile" class="form-label"
                        >Banner da turma - Dimensões recomendadas: 772 x 194</label
                    >
                    <input
                        id="formFile"
                        class="form-control"
                        type="file"
                        @input="form.banner = $event.target.files[0]"
                        @change="previewImage"
                    />
                    <small class="mt-3 informative-text">
                        O tamanho máximo que a imagem deve ter é de 2mb
                    </small>
                </div>

                <div class="col-md-11 mx-auto">
                    <img
                        v-if="imageUrl"
                        width="540px"
                        height="136px"
                        class="d-block mx-auto border border-5 border-light shadow"
                        :src="imageUrl"
                    />
                </div>
            </Form>
            <ConfirmationModal
                modal_id="classEditModal"
                modal_title="Confirmação de remoção do registro"
            >
                <template v-slot:modal-text>
                    Você tem certeza que deseja excluir o registro?
                </template>
                <template v-slot:modal-footer>
                    <button
                        type="button"
                        ref="classEditModalCloseButton"
                        class="btn btn-secondary text-white"
                        data-bs-dismiss="modal"
                    >
                        Voltar
                    </button>
                    <button @click="destroy" type="button" class="btn btn-danger text-white">
                        Deletar registro
                    </button>
                </template>
            </ConfirmationModal>
        </div>
    </Dashboard>
</template>
