<template>
    <div>
        <div class="ratio ratio-16x9 ratio-xxl-21x9 bg-secondary rounded-3 animated-background">
            <iframe
                v-if="localYoutubeLink"
                class="rounded-3 shadow"
                frameborder="0"
                :src="localYoutubeLink"
            />
        </div>
        <a
            class="btn btn-dark fs-12 mt-4 w-100 font-semibold p-2"
            target="_blank"
            :class="{ disabled: !music.link_lyrics }"
            :href="music.link_lyrics"
            role="button"
        >
            <font-awesome-icon class="me-2" :icon="['far', 'circle-play']" />
            Letra detalhada da música
        </a>
        <div
            id="recommendedCarousel"
            class="carousel slide mt-3 carousel-dark"
            data-bs-ride="carousel"
            v-if="chunkedRecommendedSongs.length"
        >
            <div class="carousel-inner">
                <div
                    v-for="(group, groupIndex) in chunkedRecommendedSongs"
                    :key="groupIndex"
                    :class="['carousel-item', { active: groupIndex === 0 }]"
                >
                    <div class="row">
                        <div v-for="song in group" :key="song.id" class="col-6 col-md-4 mb-3 p-0">
                            <div class="card border-0" style="width: 150px; height: 150px">
                                <img
                                    :src="`/storage/${song.cover_photo}`"
                                    class="card-img-top d-block mx-auto rounded-3"
                                    style="width: 80px; height: 80px"
                                    :alt="song.name"
                                />
                                <div class="card-body text-center">
                                    <span class="song-title fs-10 font-bold mb-2">
                                        {{ song.name }}
                                    </span>
                                    <button
                                        class="btn btn-dark btn-sm mt-auto align-self-start fs-10 font-bold stretched-link"
                                        @click="playRecommendedSong(song)"
                                        :disabled="!song.youtube_link"
                                    >
                                        Ouvir agora
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <button
                class="carousel-control-prev"
                type="button"
                data-bs-target="#recommendedCarousel"
                data-bs-slide="prev"
            >
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Anterior</span>
            </button>
            <button
                class="carousel-control-next"
                type="button"
                data-bs-target="#recommendedCarousel"
                data-bs-slide="next"
            >
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Próximo</span>
            </button>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';

const props = defineProps({
    music: {
        type: Object,
        default: () => ({}),
    },
    recommendedSongs: {
        type: Array,
        default: () => [],
    },
});

const emits = defineEmits(['updateYoutubeLink']);

const localYoutubeLink = ref(
    props.music.youtube_link ? convertYouTubeLink(props.music.youtube_link) : ''
);

function convertYouTubeLink(link) {
    if (link && link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];
    }
    return link;
}

// Estado reativo para a largura da janela
const windowWidth = ref(window.innerWidth);

function handleResize() {
    windowWidth.value = window.innerWidth;
}

onMounted(() => {
    window.addEventListener('resize', handleResize);
});

onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
});

// Computed que determina se a tela é xs (<576px)
const isXs = computed(() => windowWidth.value < 576);

// Particiona as músicas: 2 itens por slide em xs, 3 para outros tamanhos
const chunkedRecommendedSongs = computed(() => {
    const chunkSize = isXs.value ? 2 : 3;
    const chunks = [];
    if (props.recommendedSongs && props.recommendedSongs.length) {
        for (let i = 0; i < props.recommendedSongs.length; i += chunkSize) {
            chunks.push(props.recommendedSongs.slice(i, i + chunkSize));
        }
    }
    return chunks;
});

function playRecommendedSong(song) {
    let link = song.youtube_link;
    if (link && link.includes('/watch?v=')) {
        link = link.replace('/watch?v=', '/embed/');
        link = link.split('&')[0];
    }
    if (link.includes('?')) {
        link += '&autoplay=1';
    } else {
        link += '?autoplay=1';
    }
    localYoutubeLink.value = link;
    emits('updateYoutubeLink', link);
}
</script>

<style scoped>
.song-title {
    display: block;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>
