<script setup>
import { Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';

import CreatorRecord from '@/Pages/Dashboard/Components/Shared/CreatorRecord.vue';

const props = defineProps({
    visit: Object,
    people: Object,
    creatorRegistration: Object,
});

const getValueOrDefault = (value, defaultValue = 'Não informado') => {
    return value ? value : defaultValue;
};
</script>

<template>
    <Dashboard :page-title="visit.identification">
        <div id="show-member">
            <Breadcrumb
                current-page-name="Visita"
                :link-list="[
                    {
                        previous_page_url: 'hospital-verb-visits',
                        previous_page_name: 'Verbo nos hospitais',
                    },
                ]"
            />

            <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
                <div class="col-md-6">
                    <h4 class="font-bold m-0">{{ visit.identification }}</h4>
                </div>
                <div class="col-md-6 mt-3 mt-md-0 text-end pe-0">
                    <Link
                        :href="`/dashboard/hospital-verb-people/${visit.id}/create`"
                        class="btn btn-success btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'plus']" />
                        Novo
                    </Link>
                    <Link
                        :href="`/dashboard/hospital-verb-visits/edit/${visit.id}`"
                        class="btn btn-primary btn-add me-2"
                    >
                        <font-awesome-icon class="me-2" :icon="['fas', 'pen-to-square']" />
                        Editar dados
                    </Link>
                </div>
            </div>

            <p class="p-3 bg-danger bg-opacity-10 border border-success my-3">
                <font-awesome-icon class="me-2" :icon="['fas', 'person-praying']" />
                {{ people.length }} pessoa(s) foram alcançadas nessa visita
            </p>

            <div class="px-5 py-4 mb-3 simple-data-card-member" v-if="visit.team.length">
                <h6>Equipe que participou da visita ao hospital</h6>
                <div class="row">
                    <div
                        v-for="(counselor, index) in visit.team"
                        :key="index"
                        class="col-md-2 col-6 counselors mt-3"
                    >
                        <Link
                            class="d-block text-center"
                            :href="`/dashboard/hospital-verb-members/${counselor.id}`"
                        >
                            <img
                                class="image-counselor border border-info border-3"
                                :src="`/storage/${counselor.identification_photo}`"
                                alt=""
                            />
                        </Link>
                        <span class="name d-block text-center mt-2">
                            {{ counselor.name }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="row d-flex justify-content-between align-items-center my-3">
                <div class="font-bold col-12 col-sm-6">Baixar relatório</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/hospital-verb-people/pdf/export?visit_id=${visit.id}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/hospital-verb-people/excel/export?visit_id=${visit.id}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <div class="card mb-3 p-4" v-for="(item, index) in people" :key="index">
                <div class="d-flex align-items-center justify-content-between">
                    <h5 class="mb-0 fw-bold">{{ item.name }}</h5>
                    <Link
                        :href="`/dashboard/hospital-verb-people/edit/${item.id}`"
                        class="btn btn-primary btn-add me-2 float-end"
                    >
                        <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                    </Link>
                </div>
                <hr />
                <fieldset class="row">
                    <div class="col-sm-3">
                        <label for="exampleInputEmail1" class="form-label">Contato</label>
                        <div class="input-group mb-2">
                            <input
                                type="name"
                                disabled
                                :value="
                                    item.phone_number == '' ? 'Não informado' : item.phone_number
                                "
                                class="form-control"
                            />
                            <a
                                :href="`https://wa.me/55${item.phone_number}`"
                                target="_blank"
                                class="btn btn-success btn-add"
                            >
                                <font-awesome-icon :icon="['fab', 'whatsapp']" />
                            </a>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <label for="exampleInputEmail1" class="form-label">Endereço</label>
                        <input
                            type="name"
                            disabled
                            class="form-control p-2"
                            id="exampleInputEmail1"
                            :value="getValueOrDefault(item.address)"
                            aria-describedby="emailHelp"
                        />
                    </div>
                    <div class="col-sm-3">
                        <label for="exampleInputEmail1" class="form-label">Cidade</label>
                        <input
                            type="name"
                            disabled
                            class="form-control p-2"
                            id="exampleInputEmail1"
                            :value="getValueOrDefault(item.city)"
                            aria-describedby="emailHelp"
                        />
                    </div>
                    <div class="col-sm-4 mt-3">
                        <label for="sex" class="form-label d-block"> Tipo de ação</label>
                        <Badge :name="item.new_birth_type" />
                    </div>
                    <div class="col-sm-4 mt-3">
                        <label for="sex" class="form-label d-block"> Povoado</label>
                        <Badge :name="item.town ? 'YES' : 'NOT'" />
                    </div>
                </fieldset>
            </div>
            <CreatorRecord
                v-if="creatorRegistration.name"
                :data="creatorRegistration"
                title="Visita cadastrada por: "
            />
        </div>
    </Dashboard>
</template>
