<script setup>
import { reactive, defineProps, onMounted, ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';

const props = defineProps({
    children: Object,
    classe: Object,
});

const form = useForm({
    child_id: null,
});

const seacher = reactive({
    child_name: null,
});

const search = () => {
    router.visit(`/dashboard/child-department-classes/${props.classe.id}/children/create`, {
        method: 'get',
        data: { seacher },
        only: ['children'],
        preserveState: true,
        preserveScroll: true,
        onSuccess: () => {
            form.child_id = props.children ? form.child_id : null;
            child.value = false;
        },
    });
};

let timeoutId = null;

const compositionSeacher = (field, event) => {
    seacher[field] = event.target.value;
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
        search();
    }, 1000);
};

const toggleSelection = (field, id) => {
    if (form[field] === id) {
        form[field] = null;
        child.value = false;
    } else {
        form[field] = id;
    }
};

const store = () => form.post(`/dashboard/child-department-classes/${props.classe.id}/children`);

const child = ref(false);
</script>

<template>
    <Dashboard page-title="Novo aluno">
        <Breadcrumb
            current-page-name="Novo aluno"
            :link-list="[
                {
                    previous_page_url: `child-department-classes/${props.classe.id}/children`,
                    previous_page_name: 'Crianças',
                },
            ]"
        />
        <h4 class="my-3">Novo aluno</h4>

        <Form
            button-name="Novo aluno"
            :method="store"
            :processing="form.processing"
            :button-disabled="form.processing || !form.child_id"
        >
            <div class="row g-2">
                <p
                    class="p-3 bg-info bg-opacity-10 rounded-end border-primary border-start border-5 font-semibold fs-12"
                >
                    Criança sendo adicionada na {{ classe.name }}
                </p>
                <hr />

                <div class="col-md-12">
                    <label for="name" class="form-label">Buscar criança</label>
                    <input
                        id="name"
                        v-model="form.name"
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="João Pedro de Souza"
                        required
                        @input="compositionSeacher('child_name', $event)"
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
            </div>

            <template v-if="children?.length">
                <hr />

                <legend class="m-0">Criança selecionada</legend>

                <ul class="list-group member-found">
                    <li
                        v-for="(item, index) in children"
                        :key="index"
                        class="list-group-item p-2 d-flex align-items-center mb-3"
                        :class="{ checked: form.child_id === item.id }"
                    >
                        <input
                            :id="'checkBoxVisitor' + item.id"
                            class="form-check-input me-1 mb-0"
                            type="checkbox"
                            name="listGroupRadio"
                            :value="item.id"
                            :checked="form.child_id === item.id"
                            @change="toggleSelection('child_id', item.id)"
                        />
                        <label
                            class="form-check-label stretched-link ms-3 fs-13 mb-0 py-2 w-100"
                            :for="'checkBoxVisitor' + item.id"
                            style="cursor: pointer"
                        >
                            <div class="d-flex justify-content-between align-items-center">
                                <span>{{ item.name }}</span>
                                <a
                                    :href="`/dashboard/children/${item.id}`"
                                    target="_blank"
                                    class="btn btn-primary btn-add z-2 me-3"
                                    >Acessar perfil</a
                                >
                            </div>
                        </label>
                    </li>
                </ul>
            </template>
        </Form>
    </Dashboard>
</template>
