<script setup>
import { reactive, defineProps, onMounted } from 'vue';
import { router } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';

import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

const props = defineProps({
    members: Object,
    types: Object,
});

const searchForm = reactive({
    name: null,
    type: '',
});

const seacher = () =>
    router.get('/dashboard/members', searchForm, {
        preserveState: true,
    });

const compositionSeacher = (field, event) => {
    searchForm[field] = event.target.value;
    seacher();
};

const initializeSearchForm = () => {
    const urlParams = new URLSearchParams(window.location.search);

    const fields = ['name', 'type'];
    fields.forEach((field) => {
        if (urlParams.has(field)) {
            searchForm[field] = urlParams.get(field);
        }
    });

    if (!urlParams.has('type')) {
        searchForm.type = 'MEMBER';
    }
};

onMounted(() => {
    initializeSearchForm();
});
</script>

<template>
    <Dashboard page-title="Membros">
        <Breadcrumb current-page-name="Membros" />

        <PageHeader title="Membros" :button-link="route('members.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row d-flex justify-content-between align-items-center mt-2">
                <div class="font-bold col-12 col-sm-6">Baixar membros</div>
                <div class="col-12 col-sm-6 text-sm-end mt-3 mt-sm-0 text-end text-sm-start">
                    <a
                        class="btn btn-danger fw-bold fs-12"
                        :href="`/dashboard/members/pdf/export?name=${searchForm.name}&type=${searchForm.type}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-pdf']" />PDF
                    </a>
                    <a
                        class="btn btn-success ms-2 fw-bold fs-12"
                        :href="`/dashboard/members/excel/export?name=${searchForm.name}&type=${searchForm.type}`"
                    >
                        <font-awesome-icon class="me-2" :icon="['fa', 'file-excel']" /> Excel
                    </a>
                </div>
            </div>

            <hr />

            <div class="row g-3">
                <div class="col-md-8">
                    <label class="form-label" for="floatingInput">Nome do visitante</label>
                    <input
                        id="floatingInput"
                        type="email"
                        v-model="searchForm.name"
                        class="form-control"
                        placeholder="Aline Andrade"
                        @input="compositionSeacher('name', $event)"
                    />
                </div>
                <div class="col-md-4">
                    <label for="type" class="form-label">Status</label>
                    <select
                        id="type"
                        v-model="searchForm.type"
                        class="form-select form-select"
                        aria-label="Small select example"
                        @change="seacher()"
                    >
                        <option value="0">Todas opções</option>
                        <option v-for="(item, index) in types" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <Table
                table-id="table-members"
                :headers="[
                    {
                        key: 'index',
                        label: '#',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    { key: 'member_name', label: 'Nome do membro' },
                    {
                        key: 'status',
                        label: 'Status',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'phone',
                        label: 'Telefone',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="members?.data"
                :pagination="{
                    data: members?.meta,
                    reference: 'members',
                }"
                :rowLink="(item) => route('members.show', item.id)"
            >
                <template #cell-index="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-member_name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="item.identification_photo" />
                        <span class="ms-3">{{ item.clean_name }}</span>
                    </div>
                </template>

                <template #cell-status="{ item }">
                    <Badge :name="item.type" />
                </template>

                <template #cell-phone="{ item }">
                    <div class="text-center">
                        <template v-if="item.phone_number?.phone_number.length">
                            {{ item.phone_number?.phone_number }}
                        </template>
                        <template v-else>
                            <font-awesome-icon
                                :icon="['fas', 'minus']"
                                data-bs-toggle="tooltip"
                                data-bs-placement="bottom"
                                data-bs-title="Nenhum telefone adicionado"
                            />
                        </template>
                    </div>
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
