<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    evangelized: Object,
    typesNewBirths: Object,
    sexs: Object,
});

const form = useForm({
    name: props.evangelized.name,
    phone_number: props.evangelized.phone_number,
    address: props.evangelized.address,
    observations: props.evangelized.observations,
    age: props.evangelized.age,
    new_birth_type: props.evangelized.new_birth_type,
    sex: props.evangelized.sex,
});

const update = () => router.put(`/dashboard/evangelized-people/${props.evangelized.id}`, form);

const evangelizedEditModalCloseButton = ref(null);

const destroy = () => {
    evangelizedEditModalCloseButton.value.click();
    router.delete(`/dashboard/evangelized-people/${props.evangelized.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar dados">
        <Breadcrumb
            current-page-name="Editar dados"
            :link-list="[
                {
                    previous_page_url: `evangelizations/${evangelized.evangelization_id}`,
                    previous_page_name: 'Evangelização',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                <Link
                    :href="`/dashboard/evangelizations/${evangelized.evangelization_id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#evangelizedEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-sm-8">
                    <label for="name" class="form-label">Nome do Evangelizado(a)</label>
                    <input
                        placeholder="João Pedro de Souza"
                        type="text"
                        v-model="form.name"
                        class="form-control"
                        name="name"
                        id="name"
                        autocomplete="on"
                        aria-describedby="nameHelp"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
                <div class="col-sm-sm-4">
                    <label for="new_birth_type" class="form-label">Tipo da ação</label>
                    <select
                        id="new_birth_type"
                        v-model="form.new_birth_type"
                        class="form-select"
                        required
                    >
                        <option
                            v-for="(item, index) in typesNewBirths"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-sm-4">
                    <label for="phone_number" class="form-label"> Contato </label>

                    <input
                        id="phone_number"
                        v-model="form.phone_number"
                        v-maska
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="(00) 00000-0000"
                        data-maska="(##) #####-####"
                    />
                    <small v-if="form.errors.phone_number" class="text-danger mt-3">
                        {{ form.errors.phone_number }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <label for="sex" class="form-label">Sexo</label>
                    <select id="sex" v-model="form.sex" class="form-select" required>
                        <option v-for="(item, index) in sexs" :key="index" :value="item.value">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-4">
                    <label for="age" class="form-label">Idade</label>
                    <input
                        placeholder="30"
                        type="number"
                        v-model="form.age"
                        class="form-control"
                        name="age"
                        id="age"
                        autocomplete="on"
                        aria-describedby="ageHelp"
                        required
                    />
                    <small v-if="form.errors.age" class="text-danger mt-3">
                        {{ form.errors.age }}
                    </small>
                </div>
                <div class="col-sm-12">
                    <label for="address" class="form-label">Endereço</label>
                    <input
                        placeholder=" Rua Juscelino Kubitschek"
                        type="text"
                        v-model="form.address"
                        class="form-control"
                        name="address"
                        id="address"
                        autocomplete="on"
                        aria-describedby="addressHelp"
                    />
                    <small v-if="form.errors.address" class="text-danger mt-3">
                        {{ form.errors.address }}
                    </small>
                </div>

                <div class="col-sm-12">
                    <label for="observations" class="form-label"> Observações </label>

                    <textarea
                        id="observations"
                        v-model="form.observations"
                        type="text"
                        class="form-control"
                        name="observations"
                        placeholder="Caso necessário, forneca alguma observação."
                        rows="3"
                    />

                    <small v-if="form.errors.observations" class="text-danger mt-3">
                        {{ form.errors.observations }}
                    </small>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="evangelizedEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="evangelizedEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
