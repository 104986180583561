<script setup>
import { watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    lessons: Object,
    classes: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    { classe_id: 0 },
    'child-lessons.index'
);

watch(
    () => props.lessons,
    (newVal) => updateLoadingState(newVal),
    { immediate: true, deep: true }
);
</script>

<template>
    <Dashboard page-title="Recepção Infantil">
        <Breadcrumb current-page-name="Recepção Infantil" />
        <PageHeader
            title="Recepção Infantil"
            :button-link="route('child-lessons.create')"
            button-text="Nova"
        />

        <div class="p-3 search-form-card">
            <div class="row px-3 g-3">
                <div class="col-md-12">
                    <label for="class_id" class="form-label">Turma</label>
                    <select
                        id="class_id"
                        v-model="searchParams.classe_id"
                        class="form-select"
                        aria-label="Selecione a turma"
                        @change="executeSearch"
                    >
                        <option value="0">Todas</option>
                        <option v-for="item in classes" :key="item.id" :value="item.id">
                            {{ item.name }}
                        </option>
                    </select>
                </div>
            </div>

            <div class="mt-3">
                <Table
                    table-id="table-child-lesson"
                    :headers="[
                        {
                            key: 'index',
                            label: '#',
                            thClass: 'text-center',
                            tdClass: 'text-center fw-bold',
                        },
                        {
                            key: 'child',
                            label: 'Criança',
                            thClass: 'text-start',
                            tdClass: 'text-start',
                        },
                        {
                            key: 'classe',
                            label: 'Turma selecionada',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                        {
                            key: 'event',
                            label: 'Evento relacionado',
                            thClass: 'text-center',
                            tdClass: 'text-center',
                        },
                    ]"
                    :data="lessons?.data"
                    :pagination="{ data: lessons?.meta, reference: 'lessons' }"
                    :rowLink="
                        (item) =>
                            `/dashboard/child-department-classes/${item.classe.id}/lessons/${item.child_department_lesson_id}`
                    "
                    :is-loading="isLoading"
                >
                    <template #cell-index="{ index }">
                        {{ index + 1 }}
                    </template>

                    <template #cell-child="{ item }">
                        <div class="d-flex align-items-center">
                            <LazyImage :src="item.child.photo_identification" width="30px" />
                            <span class="ms-3">{{ item.child.clean_name }}</span>
                        </div>
                    </template>

                    <template #cell-classe="{ item }">
                        {{ item.classe.name }}
                    </template>

                    <template #cell-event="{ item }">
                        <div class="d-flex align-items-center justify-content-center">
                            <LazyImage :src="`/storage/${item.event_banner}`" width="30px" />
                            <span class="ms-3">{{ item.event_name }}</span>
                        </div>
                    </template>
                </Table>
            </div>
        </div>
    </Dashboard>
</template>
