<script setup>
import { ref } from 'vue';
import { useForm, router, Link } from '@inertiajs/vue3';

import { vMaska } from 'maska/vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Form from '@/Pages/Dashboard/Components/ui/Form.vue';
import ConfirmationModal from '@/Pages/Dashboard/Components/ui/ConfirmationModal.vue';

const props = defineProps({
    people: Object,
    typesNewBirths: Object,
});

const form = useForm({
    name: props.people.name,
    phone_number: props.people.phone_number,
    address: props.people.address,
    city: props.people.city,
    new_birth_type: props.people.new_birth_type,
    town: props.people.town ? true : false,
});

const update = () => router.put(`/dashboard/hospital-verb-people/${props.people.id}`, form);

const peopleEditModalCloseButton = ref(null);

const destroy = () => {
    peopleEditModalCloseButton.value.click();
    router.delete(`/dashboard/hospital-verb-people/${props.people.id}`);
};
</script>

<template>
    <Dashboard page-title="Editar dados">
        <Breadcrumb
            current-page-name="Editar dados"
            :link-list="[
                {
                    previous_page_url: `hospital-verb-visits/${people.verb_hospital_visit_id}`,
                    previous_page_name: 'Visita',
                },
            ]"
        />

        <div class="row mt-3 mb-3 d-flex align-items-center justify-content-between">
            <div class="col-sm-6">
                <h4 class="font-bold m-0">Editar dados</h4>
            </div>
            <div class="col-sm-6 mt-3 mt-sm-0 text-end pe-0">
                <Link
                    :href="`/dashboard/hospital-verb-visits/${people.verb_hospital_visit_id}`"
                    class="btn btn-success btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'eye']" />
                    Visualizar registro
                </Link>
                <a
                    data-bs-toggle="modal"
                    data-bs-target="#peopleEditModal"
                    href="#"
                    class="btn btn-danger btn-add me-2"
                >
                    <font-awesome-icon class="me-2" :icon="['fas', 'circle-xmark']" />
                    Deletar
                </a>
            </div>
        </div>

        <Form
            button-name="Atualizar dados"
            :method="update"
            :processing="form.processing"
            :button-disabled="form.processing"
        >
            <div class="row g-3">
                <div class="col-sm-6">
                    <label for="name" class="form-label">Nome</label>
                    <input
                        placeholder="João Pedro de Souza"
                        type="text"
                        v-model="form.name"
                        class="form-control"
                        name="name"
                        id="name"
                        autocomplete="on"
                        aria-describedby="nameHelp"
                        required
                    />
                    <small v-if="form.errors.name" class="text-danger mt-3">
                        {{ form.errors.name }}
                    </small>
                </div>
                <div class="col-sm-3">
                    <label for="new_birth_type" class="form-label">Tipo da ação</label>
                    <select
                        id="new_birth_type"
                        v-model="form.new_birth_type"
                        class="form-select"
                        required
                    >
                        <option
                            v-for="(item, index) in typesNewBirths"
                            :key="index"
                            :value="item.value"
                        >
                            {{ item.name }}
                        </option>
                    </select>
                </div>
                <div class="col-sm-3">
                    <label for="phone_number" class="form-label"> Contato </label>

                    <input
                        id="phone_number"
                        v-model="form.phone_number"
                        v-maska
                        type="text"
                        autocomplete="off"
                        class="form-control"
                        placeholder="(00) 00000-0000"
                        data-maska="(##) #####-####"
                    />
                    <small v-if="form.errors.phone_number" class="text-danger mt-3">
                        {{ form.errors.phone_number }}
                    </small>
                </div>
                <div class="col-sm-8">
                    <label for="address" class="form-label">Endereço</label>
                    <input
                        placeholder=" Rua Juscelino Kubitschek"
                        type="text"
                        v-model="form.address"
                        class="form-control"
                        name="address"
                        id="address"
                        autocomplete="on"
                        aria-describedby="addressHelp"
                    />
                    <small v-if="form.errors.address" class="text-danger mt-3">
                        {{ form.errors.address }}
                    </small>
                </div>
                <div class="col-sm-4">
                    <label for="city" class="form-label">Cidade</label>
                    <input
                        placeholder=" Rua Juscelino Kubitschek"
                        type="text"
                        v-model="form.city"
                        class="form-control"
                        name="city"
                        id="city"
                        autocomplete="on"
                        aria-describedby="cityHelp"
                    />
                    <small v-if="form.errors.city" class="text-danger mt-3">
                        {{ form.errors.city }}
                    </small>
                </div>
                <div class="col-md-12">
                    <div class="form-check d-flex align-items-center form-switch">
                        <input
                            id="flexCheckDefault"
                            v-model="form.town"
                            class="form-check-input"
                            type="checkbox"
                            role="switch"
                        />
                        <label class="form-check-label ms-3" for="flexCheckDefault">
                            É povoado?
                        </label>
                    </div>
                </div>
            </div>
        </Form>
        <ConfirmationModal
            modal_id="peopleEditModal"
            modal_title="Confirmação de remoção do registro"
        >
            <template v-slot:modal-text> Você tem certeza que deseja excluir o registro? </template>
            <template v-slot:modal-footer>
                <button
                    type="button"
                    ref="peopleEditModalCloseButton"
                    class="btn btn-secondary text-white"
                    data-bs-dismiss="modal"
                >
                    Voltar
                </button>
                <button @click="destroy" type="button" class="btn btn-danger text-white">
                    Deletar registro
                </button>
            </template>
        </ConfirmationModal>
    </Dashboard>
</template>
