<script setup>
import { router } from '@inertiajs/vue3';

import Layout from './Layout.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

const props = defineProps({
    department: Object,
    requests: Object,
    isLeader: Boolean,
});

import useStatus from '@/Pages/Dashboard/Composables/useStatus.vue';

const { formatStatusIcon, statusResponsePastor, statusResponseLeader } = useStatus();

const goDepartmentRequest = (id) => router.get(`/dashboard/departments/entry-requests/${id}`);
</script>

<template>
    <Layout :department="department">
        <div class="table-responsive search-form-card" v-if="requests.length">
            <table class="table m-0 align-middle table-hover" id="table-families">
                <thead class="table-light">
                    <tr>
                        <th class="text-center" scope="col">#</th>
                        <th scope="col">Membro solicitante</th>
                        <th class="text-center" scope="col">Status da solicitação</th>
                        <th class="text-center" scope="col">Data da solicitação</th>
                    </tr>
                </thead>

                <tbody>
                    <tr
                        v-for="(request, index) in requests"
                        :key="index"
                        @click="goDepartmentRequest(request.id)"
                        :class="{
                            'table-info':
                                request.status_response_pastor == 'PENDING' &&
                                request.status_response_leader == 'PENDING',
                        }"
                    >
                        <td class="text-center">
                            {{ index + 1 }}
                        </td>
                        <td>
                            <div class="d-flex align-items-center">
                                <LazyImage width="30px" :src="request.member_photo" />
                                <span class="ms-3">{{ request.member_name }}</span>
                            </div>
                        </td>
                        <td class="text-center">
                            <font-awesome-icon
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :data-bs-title="
                                    statusResponsePastor(request.status_response_pastor)
                                "
                                :class="`fs-4 ${formatStatusIcon(request.status_response_pastor)}`"
                                :icon="['fas', 'circle-check']"
                            />
                            <font-awesome-icon
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                :data-bs-title="
                                    statusResponseLeader(request.status_response_leader)
                                "
                                :class="`fs-4 ${formatStatusIcon(request.status_response_leader)} ms-3`"
                                :icon="['fas', 'circle-check']"
                            />
                        </td>
                        <td class="text-center">
                            {{ request.created_at }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
            <p
                class="p-3 bg-info bg-opacity-10 rounded-end informative-text"
                :style="{
                    borderLeft: `5px solid ${department.identification_color}`,
                }"
            >
                Nenhuma solicitação encontrada
            </p>
        </div>
    </Layout>
</template>
