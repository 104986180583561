<script setup>
import { defineProps, watch } from 'vue';

import Dashboard from '@/Pages/Dashboard/Layouts/Dashboard.vue';
import Breadcrumb from '@/Pages/Dashboard/Components/ui/Breadcrumb.vue';
import Table from '@/Pages/Dashboard/Components/Shared/Table.vue';
import Badge from '@/Pages/Dashboard/Components/Shared/Badge.vue';
import PageHeader from '@/Pages/Dashboard/Components/Shared/PageHeader.vue';
import LazyImage from '@/Pages/Dashboard/Components/Shared/LazyImage.vue';

import { useSearch } from '@/Pages/Dashboard/Composables/useSearch.js';

const props = defineProps({
    users: Object,
});

const { searchParams, executeSearch, updateLoadingState, isLoading } = useSearch(
    {
        name: null,
        order_by: 'asc',
    },
    'users.index'
);

watch(
    () => props.users,
    (newValue) => updateLoadingState(newValue),
    { deep: true, immediate: true }
);
</script>

<template>
    <Dashboard page-title="Usuários">
        <Breadcrumb current-page-name="Usuários" />

        <PageHeader title="Usuários" :button-link="route('users.create')" button-text="Novo" />

        <div class="px-4 py-3 search-form-card">
            <div class="row align-items-center">
                <div class="col-md-12">
                    <label for="artistName" class="form-label">Nome do usuário</label>
                    <input
                        id="artistName"
                        v-model="searchParams.name"
                        type="text"
                        class="form-control"
                        placeholder="Matheus Souza"
                        @input="executeSearch()"
                    />
                </div>
            </div>

            <Table
                table-id="table-users"
                :headers="[
                    { key: 'id', label: 'ID', thClass: 'text-center', tdClass: 'text-center' },
                    { key: 'name', label: 'Nome do usuário' },
                    {
                        key: 'email',
                        label: 'E-mail',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                    {
                        key: 'status',
                        label: 'Situação',
                        thClass: 'text-center',
                        tdClass: 'text-center',
                    },
                ]"
                :data="users?.data"
                :pagination="{
                    data: users?.meta,
                    reference: 'users',
                }"
                :rowLink="(item) => route('users.show', item.id)"
                :is-loading="isLoading"
            >
                <template #cell-id="{ index }">
                    {{ index + 1 }}
                </template>

                <template #cell-name="{ item }">
                    <div class="d-flex align-items-center">
                        <LazyImage width="30px" :src="`${item.profile_image_url}`" />
                        <span class="ms-3">{{ item.name }}</span>
                    </div>
                </template>

                <template #cell-status="{ item }">
                    <Badge :name="item.status" />
                </template>
            </Table>
        </div>
    </Dashboard>
</template>
